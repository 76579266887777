/* eslint-disable complexity */
import React, { useContext, useEffect, useRef, useState } from "react";

import { Text } from "../Text/Text";
import { AdminButton as Button } from "../Button/AdminButton";
import { Input } from "../Form";
import { Tag } from "../Tags/Tags";
import type { IconType } from "react-icons/lib";
import { RiArrowRightSLine, RiCheckLine, RiCloseLine, RiMoreFill } from "react-icons/ri";
import { useIsMobileWidth } from "../../hooks";
import { useTheme } from "../../contexts";
import { ProgressCircle } from "../ProgressCircle/ProgressCircle";
import { ColumnViewContext } from "./ColumnViewContext";
import * as Dropdown from "../Dropdown";

const DEFAULT_MAX_ICONS = 3;

interface Action {
  icon: IconType;
  title: string;
  onClick: () => void;
  color?: string;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
}

interface Props {
  className?: string;
  value: string;
  valueTags?: string[];
  icon?: React.ReactNode;
  count?: number;
  actions?: Action[];
  maxIcons?: number;
  isEditing?: boolean;
  isNew?: boolean;
  selected?: boolean;
  placeholder?: string;
  onSelect?: () => void;
  onChange?: (value: string) => void;
  onReset?: () => void;
  loading?: boolean;
  showActions?: boolean;
}

export const ColumnViewItem: React.FC<Props> = ({
  className,
  value,
  valueTags,
  selected,
  isEditing,
  isNew,
  icon,
  actions,
  maxIcons = DEFAULT_MAX_ICONS,
  count,
  onSelect,
  onChange,
  onReset,
  placeholder = "",
  loading = false,
  showActions = true,
}) => {
  const { colors } = useTheme();
  const isMobile = useIsMobileWidth();
  const [isHovered, setIsHovered] = useState(false);
  const [editingValue, setEditingValue] = useState(value);
  const { scrollNextColumn } = useContext(ColumnViewContext);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selected === true && ref.current != null) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [selected]);

  const handleSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isNew === true) {
      return;
    }

    onSelect?.();

    // disable select on actions elements
    if (e.target !== e.currentTarget && (e.target as HTMLElement).tagName !== "P") {
      return;
    }

    scrollNextColumn();
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onChange?.(editingValue);
    }
  };

  const handleSave = () => {
    onChange?.(editingValue);
  };

  const isInputMode = isEditing === true || isNew === true;

  return (
    <div
      className={`item ${className ?? ""} ${selected === true || isEditing === true ? "item--selected" : ""} ${
        isNew === true ? "item--new" : ""
      } ${icon != null && !isInputMode ? "item--icon" : ""}`.trim()}
      onClick={handleSelect}
      onMouseEnter={() => setIsHovered(showActions)}
      onMouseLeave={() => setIsHovered(false)}
      ref={ref}
    >
      {!isInputMode && (
        <>
          {icon != null && icon}
          <Text variant="block" size="small" weight="medium" className="m-0 label">
            <div className="d-flex flex-column flex-wrap">
              <span>{value}</span>
              <div>
                {valueTags?.map((vt, index) => (
                  <Tag className="mt-2 mb-2" key={index} style={{ alignSelf: "flex-start" }} variant="default">
                    {vt}
                  </Tag>
                ))}
              </div>
            </div>
          </Text>
          {(isMobile || isHovered) && actions != null ? (
            <div className="item-actions">
              {!isMobile &&
                actions.slice(0, maxIcons).map((action, index) => (
                  <Button
                    variant="ghost"
                    onClick={action.onClick}
                    className={action.className}
                    key={index}
                    aria-label={action.title}
                    disabled={action.disabled === true || action.loading === true}
                    shape="round"
                  >
                    {action.loading === true ? (
                      <ProgressCircle size="xs" />
                    ) : (
                      <action.icon size={16} color={action.color} />
                    )}
                  </Button>
                ))}
              {(isMobile || actions.length > maxIcons) && (
                <Dropdown.Icon icon={RiMoreFill} size="md" placement="bottom-end" supportsMobile className="dropdown">
                  {actions.slice(isMobile ? 0 : maxIcons).map((action, index) => (
                    <Dropdown.Item
                      icon={() =>
                        action.loading === true ? <ProgressCircle size="xs" /> : <action.icon color={action.color} />
                      }
                      onClick={() => {
                        action.onClick();
                        setIsHovered(false);
                      }}
                      className={action.className}
                      key={index}
                      disabled={action.disabled === true || action.loading === true}
                    >
                      <span>{action.title}</span>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Icon>
              )}
            </div>
          ) : (
            <div className="d-flex align-items-center">
              {count != null && <Tag type="counter" value={count} size="small" />}
              {onSelect != null && <RiArrowRightSLine size={16} className="my-3 justify-self-end" />}
            </div>
          )}
        </>
      )}
      {isInputMode && (
        <div className="edit-form">
          <Input
            type="text"
            variant="sm"
            value={editingValue}
            placeholder={placeholder}
            onChange={(e) => setEditingValue(e.target.value)}
            onKeyDown={handleEnter}
            autoFocus
          />
          <Button
            variant="primary"
            onClick={handleSave}
            className="ml-3 submit"
            shape="round"
            disabled={editingValue.trim().length === 0 || loading}
          >
            {loading ? <ProgressCircle size="xs" /> : <RiCheckLine size={16} />}
          </Button>
          <Button variant="outline" className="cancel" shape="round" onClick={onReset} disabled={loading}>
            <RiCloseLine size={16} />
          </Button>
        </div>
      )}
      <style jsx>
        {`
          .item {
            display: grid;
            grid-template-columns: 1fr auto;
            align-items: center;
            gap: 0.5rem;
            padding: 0.5rem;
            cursor: pointer;
            border-radius: 0.25rem;
            height: 3rem;
            min-height: 3rem;
          }

          .item--icon {
            grid-template-columns: 1.25rem 1fr auto;
          }

          .item--icon :global(*:first-child) {
            justify-self: center;
          }

          .item:hover {
            background-color: ${colors.grayscale[5]};
          }

          .item.item--selected {
            background-color: ${colors.blue.transparent[10]};
          }

          .item.item--new {
            background-color: ${colors.grayscale[5]};
          }

          .item :global(.label) {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .item .item-actions {
            display: flex;
            align-items: center;
          }

          .item .item-actions :global(.item-edit) {
            width: 2rem;
          }

          .item .edit-form {
            display: grid;
            grid-template-columns: auto 2.5rem 2rem;
            grid-gap: 0.25rem;
            align-items: center;
          }

          .tag :global(.tag-label) {
            display: inline-flex;
            align-items: center;
            white-space: nowrap;
            vertical-align: middle;
          }
        `}
      </style>
    </div>
  );
};
