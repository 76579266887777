import type { FC, RefObject } from "react";
import React, { useContext, useEffect, useRef } from "react";
import type { FieldProps } from "formik";

import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Text, useHtmlId, useIsMobileWidth, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiFileUploadLine } from "@equiem/react-admin-ui/icons";
import type { IFileV2 } from "@equiem/uploader";
import { useInstantFileUploaderState } from "@equiem/uploader";

import { useSignTemporaryUploadUrlMutation } from "../generated/requests-client";
import { RequestDetailsContext } from "../pages/request-details/contexts/RequestDetailsContext";
import { isAllowedFiles, validateFileSize } from "../utils/validators/file";

import { FileItem } from "./FileItem";

export type BaseFieldProps = FieldProps<IFileV2[]>["field"];

interface Props extends Omit<BaseFieldProps, "onBlur"> {
  onUploading?: (uploading: boolean) => void;
  showImages?: boolean;
  accept: string;
  onRemove: (removedItem: IFileV2, allItems: IFileV2[]) => void;
  showRemove?: boolean;
  showUploadButton?: boolean;
}

export const FileUploader: FC<Props> = ({
  value,
  onChange,
  name,
  onRemove,
  accept,
  showImages,
  showRemove,
  showUploadButton,
}) => {
  const { loading, setIsFileUploading } = useContext(RequestDetailsContext);
  const toast = useToast();
  const { colors, spacers } = useTheme();
  const { t } = useTranslation();
  const isMobile = useIsMobileWidth();
  const draggedItemClassName = useHtmlId();
  const fileInputRef = useRef() as RefObject<HTMLInputElement>;
  const [createPresignedUrl] = useSignTemporaryUploadUrlMutation();
  const { localAttachedFile, attachedFiles, onFileChange, progress, removeImage, uploading, onAbortLoading } =
    useInstantFileUploaderState({
      value,
      onChange: (files) => {
        return onChange({
          target: {
            name: name,
            value: files,
          },
        });
      },
      onRemove,
      createPresignedUrl: async () => createPresignedUrl().then((r) => r.data?.signTemporaryUploadUrl),
      multipleUpload: true,
      allowImages: showImages,
    });

  const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const isValidFiles = isAllowedFiles(e.target.files, accept);
    const isValidSize = validateFileSize(e.target.files);

    if (!isValidFiles) {
      toast.negative(t("requests.attachments.incorrectUploadedFile"));
      e.target.value = "";
      return;
    }

    if (!isValidSize) {
      toast.negative(t("requests.attachments.incorrectFileSize"));
      e.target.value = "";
      return;
    }

    if (e.target.files != null) {
      await onFileChange(e.target.files);
      e.target.value = "";
    }
  };

  useEffect(() => {
    setIsFileUploading(uploading);
  }, [uploading]);

  return (
    <>
      <div className="attached-files">
        {attachedFiles.map((file: IFileV2, index) => (
          <FileItem
            showImg={showImages}
            showRemove={showRemove === true && !loading}
            index={index}
            key={file.key ?? file.temporaryUuid}
            onRemove={() => {
              if (file.key == null || file.key === "") {
                return;
              }

              removeImage(file.key);
            }}
            file={file}
          />
        ))}
        {localAttachedFile?.file != null && (
          <FileItem
            onCancelLoading={onAbortLoading}
            index={0}
            key={localAttachedFile.file.name}
            progress={progress}
            showImg={showImages}
            file={localAttachedFile.file}
          />
        )}
      </div>

      {showUploadButton === true && (
        <>
          <input
            type="file"
            accept={accept}
            ref={fileInputRef}
            className="input"
            style={{ display: "none" }}
            id="contained-button-file"
            onChange={(e) => {
              onSelectFile(e).catch(console.error);
            }}
          />

          <Button
            variant="outline"
            disabled={loading}
            className="upload-file-button"
            onClick={() => fileInputRef.current?.click()}
          >
            <RiFileUploadLine size={16} /> {t("common.uploadFile")}
          </Button>

          <Text variant="text" color={colors.grayscale[60]} size="extra-small">
            {t("requests.attachments.validationRule")}
          </Text>
        </>
      )}
      <style jsx>
        {`
          :global(.upload-file-button) {
            margin-top: ${spacers.s4};
          }
          .attached-files {
            width: ${isMobile ? `calc(100% + ${spacers.s6})` : "100%"};
            padding-right: ${isMobile ? "24px" : 0};
            gap: 8px;
            display: flex;
            overflow: ${isMobile ? "auto" : "hidden"};
            flex-wrap: ${isMobile ? "nowrap" : "wrap"};
          }
          .center {
            display: flex;
            justify-content: space-between;
            font-size: 10pt;
            font-weight: 300;
          }
          .imgs-container {
            display: flex;
            flex-wrap: wrap;
            gap: ${spacers.s3};
            user-select: none;
          }
          .img-container {
            background: ${colors.white};
            margin-bottom: ${spacers.s3};
            margin-top: ${spacers.s3};
          }
          :global(.${draggedItemClassName}) {
            z-index: 999;
          }
        `}
      </style>
    </>
  );
};
