import React, { forwardRef, useState } from "react";
import type { ComponentProps, ReactNode } from "react";
import { useTheme } from "../../contexts";
import { AiOutlinePercentage } from "react-icons/ai";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import type { IconType } from "react-icons";

// eslint-disable-next-line @typescript-eslint/no-type-alias
export type AccordionListProps = ComponentProps<"ul"> & {
  showCollapseButton?: boolean;
  showCollapsed?: boolean;
  HeaderIcon?: IconType;
  headerText: ReactNode;
};

export const AccordionList = forwardRef<HTMLUListElement, AccordionListProps>(
  (
    { showCollapseButton = true, showCollapsed = true, HeaderIcon = AiOutlinePercentage, headerText, children },
    ref,
  ) => {
    const { colors, sizes } = useTheme();
    const [collapseList, setCollapseList] = useState(showCollapsed);

    const onListCollapseClick = () => {
      setCollapseList(!collapseList);
    };

    const CollapseIcon = collapseList ? BsChevronUp : BsChevronDown;

    return (
      <ul ref={ref}>
        <div className="accordion-container d-flex justify-content-between">
          <div className="d-flex">
            <div className="icon d-flex justify-content-center align-items-center">
              <HeaderIcon color={colors.status.positive.primary} size={sizes.xs} />
            </div>
            <div className="header-text">{headerText}</div>
          </div>
          {showCollapseButton && (
            <div
              className="list-collapse-icon  d-flex justify-content-center align-items-center"
              onClick={onListCollapseClick}
            >
              <CollapseIcon size={sizes.xs} strokeWidth={0.5} />
            </div>
          )}
        </div>
        {collapseList && <div className="accordion-items">{children}</div>}
        <style jsx>{`
          ul {
            list-style-type: none;
            background-color: ${colors.status.positive.accent};
            padding: ${sizes.xs}px ${sizes.sm}px;
            border-radius: 8px;
          }
          .accordion-container {
            min-height: ${sizes.md}px;
            line-height: ${sizes.sm}px;
          }
          .accordion-items {
            margin-top: 10px;
          }
          .icon {
            flex-shrink: 0;
            height: ${sizes.md}px;
            width: ${sizes.md}px;
            border-radius: 50%;
            background-color: rgba(from ${colors.status.positive.primary} r g b / 10%);
          }
          .header-text {
            font-size: 14px;
            margin: auto;
            margin-left: 10px;
          }
          .list-collapse-icon {
            height: ${sizes.md}px;
            width: ${sizes.md}px;
            border-radius: 50%;
            cursor: pointer;
          }
          .list-collapse-icon:hover {
            background-color: rgba(from ${colors.status.positive.primary} r g b / 10%);
          }
        `}</style>
      </ul>
    );
  },
);

AccordionList.displayName = "AccordionList";
