import React, { useContext, useState } from "react";
import { DateTime } from "luxon";

import { useSiteContext } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Form, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiCalendarEventLine } from "@equiem/react-admin-ui/icons";

import type { RequestQuery } from "../../../generated/requests-client";
import { RequestDetailsContext } from "../contexts/RequestDetailsContext";

interface P {
  request: RequestQuery["reqMgt"]["request"];
}

const RequestDateCompletedEdit: React.FC<P & { date: DateTime | null }> = ({ request, date }) => {
  const { timezone } = useSiteContext();
  const theme = useTheme();
  const [time, setTime] = useState(date);
  const toast = useToast();
  const { t } = useTranslation();
  const { loading, update } = useContext(RequestDetailsContext);

  return (
    <>
      <div>
        <Form.Input
          className="date-completed"
          disabled={loading}
          showChrome="onInteraction"
          type="datetime-local"
          calendarIconPosition="left"
          value={time?.toFormat("yyyy-LL-dd'T'HH:mm")}
          onChange={(e) => {
            const conversion = DateTime.fromISO(e.target.value, {
              zone: timezone,
            });
            if (conversion.isValid) {
              setTime(conversion);
            }
          }}
          onBlur={() => {
            const completed = time?.toMillis();
            if (request.completed === completed) {
              return;
            }
            if (completed != null && isNaN(completed)) {
              toast.negative(t("common.invalidDateFormat"));
              return;
            }
            update(request, { completed }).catch(console.log);
          }}
        />
      </div>
      <style jsx>{`
        div {
          max-width: 210px;
          margin-left: -${theme.spacers.s3};
        }
      `}</style>
    </>
  );
};

export const RequestDateCompleted: React.FC<P> = ({ request }) => {
  const { timezone } = useSiteContext();
  const { i18n } = useTranslation();

  const date = request.completed != null ? DateTime.fromMillis(request.completed, { zone: timezone }) : null;

  return request.viewerRelations.canManage || request.viewerRelations.assignee ? (
    <RequestDateCompletedEdit date={date} request={request} />
  ) : (
    <div className="d-flex align-items-center py-3 date-readonly">
      <RiCalendarEventLine size={16} className="mr-4" />
      {date != null ? `${formatters.dateshort(date, i18n.language)} ${formatters.timeshort(date, i18n.language)}` : "-"}
    </div>
  );
};
