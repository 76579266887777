import { useErrorTranslation, useTranslation } from "@equiem/localisation-eq1";
import { Formik } from "formik";
import React, { useContext } from "react";
import { convertBookingAddOnsToInputAddOns } from "../libs/convertBookingAddOnsToInputAddOns";
import { bookingFormValidation } from "../libs/bookingFormValidation";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import { DateTime } from "luxon";
import { getAvailabilityDateRange } from "../../../lib/getAvailabilityDateRange";
import { dateFormat, timeFormat, toDefaultTime } from "../libs/formatSelectedTime";
import type { BookingFormValue } from "../models/BookingFormValue";
import { Skeleton } from "@equiem/react-admin-ui";
import { BookingFormProvider, BookingFormContext } from "../contexts/BookingFormProvider";
import type { ProfileFragmentFragment } from "../../../generated/gateway-client";
import { BookableResourcePaymentMethod, useUserCreditCardsQuery } from "../../../generated/gateway-client";
import { BookingFormContent } from "./BookingFormContent";
import { useBookingFormSubmit } from "../hooks/useBookResource";
import { CurrentProfile } from "@equiem/lib";

const profileToHost = (profile: ProfileFragmentFragment | null | undefined) => {
  return profile?.uuid == null
    ? undefined
    : {
        companyName: profile.companyV2?.name ?? "",
        email: profile.email,
        firstName: profile.firstName ?? "",
        lastName: profile.lastName ?? "",
        uuid: profile.uuid,
        profileIconUrl: profile.avatar ?? "",
      };
};

// eslint-disable-next-line complexity
export const BookingForm: React.FC = () => {
  const { t } = useTranslation();
  const { tError } = useErrorTranslation();
  const { resource, booking, start, end, timezone } = useContext(BookingModalInfo);
  const { profile, loading: profileLoading } = useContext(CurrentProfile);
  const submit = useBookingFormSubmit();
  const hasCreditCardPayment = resource.paymentMethods.find((m) => m === BookableResourcePaymentMethod.CreditCard);
  const { data, loading, error } = useUserCreditCardsQuery({
    skip: !hasCreditCardPayment || booking != null,
    fetchPolicy: "network-only",
  });

  if (loading || profileLoading || profile == null) {
    return <Skeleton.Line width="6rem" className="mr-6" />;
  }

  if (error != null) {
    return <div>{tError(error)}</div>;
  }

  if (booking?.isEditable === false) {
    return <div>{t("bookings.operations.thisBookingCanNoLongerBeChanged")}</div>;
  }

  const defaultCard = (data?.profile?.cards ?? []).find((c) => c.default);

  let defaultDate = getAvailabilityDateRange(
    resource.availabilityDateRange,
    resource.bookingWindowMinInMinutes,
    resource.bookingWindowMaxInMinutes,
    timezone,
  ).startDate.toFormat(dateFormat);
  if (booking?.startDate != null) {
    defaultDate = DateTime.fromMillis(booking.startDate, { zone: timezone }).toFormat(dateFormat);
  } else if (start != null) {
    defaultDate = DateTime.fromMillis(start, { zone: timezone }).toFormat(dateFormat);
  }

  let defaultStart = "";
  if (booking?.startDate != null) {
    defaultStart = toDefaultTime(booking.startDate, timezone, false);
  } else if (start != null && end != null) {
    defaultStart = DateTime.fromMillis(start, { zone: timezone }).toFormat(timeFormat);
  }

  let defaultEnd = "";
  if (booking?.endDate != null) {
    defaultEnd = toDefaultTime(booking.endDate, timezone, true);
  } else if (end != null) {
    defaultEnd = DateTime.fromMillis(end, { zone: timezone }).toFormat(timeFormat);
  }

  const hostProfile = booking != null ? booking.user.profile : profile;

  const initialValues: BookingFormValue = {
    title: booking?.title ?? "",
    note: booking?.note ?? "",
    adminNote: booking?.adminNote ?? "",
    bookingUuid: booking?.uuid,
    date: defaultDate,
    start: defaultStart,
    end: defaultEnd,
    host: profileToHost(hostProfile),
    roomConfiguration: booking?.roomConfig?.uuid,
    termsAccepted: false,
    addOns: booking != null ? convertBookingAddOnsToInputAddOns(booking.resource.addOns, booking.addOns) : [],
    freeBookingMode: resource.paymentRateIsFree,
    contact: booking?.invoiceContact ?? undefined,
    paymentMethod:
      booking?.paymentMethod ??
      (resource.paymentMethods.length > 0
        ? resource.paymentMethods.includes(BookableResourcePaymentMethod.Credits)
          ? BookableResourcePaymentMethod.Credits
          : resource.paymentMethods[0]
        : undefined),
    creditcard:
      defaultCard == null
        ? undefined
        : {
            paymentGatewayCustomerId: data?.profile?.paymentGatewayCustomerId,
            paymentGatewayPaymentMethodId: defaultCard.paymentGatewayCardId,
          },
    creditAccount: booking?.creditAccountUuid != null ? { uuid: booking.creditAccountUuid } : undefined,
    createAppointmentOnSubmit: false,
    // Don't really care about edit as we can't change them.
    isProxyBooking: false,
    hasSuperPower: resource.viewerPermissions?.canManageBookings === true,
  };

  return (
    <BookingFormProvider>
      <BookingFormContext.Consumer>
        {({ step }) => (
          <Formik<BookingFormValue>
            initialValues={initialValues}
            validate={(values) =>
              bookingFormValidation(
                {
                  timezone,
                  values,
                  addOns: resource.addOns,
                  availabilities: resource.availability,
                  editBookingTermsAndConditions: resource.editBookingTermsAndConditions,
                  termsAndConditions: resource.termsAndConditions,
                  step,
                },
                t,
              )
            }
            validateOnBlur={false}
            validateOnMount={false}
            onSubmit={submit}
          >
            <BookingFormContent />
          </Formik>
        )}
      </BookingFormContext.Consumer>
    </BookingFormProvider>
  );
};
