import React, { useContext, useEffect } from "react";
import { useSiteContext, stringNotEmpty } from "@equiem/lib";
import { DateTime as DateTimeUi, EmptyState, InfiniteScrollTable, Tag, useTheme } from "@equiem/react-admin-ui";
import { useTranslation, useApolloErrorTranslation } from "@equiem/localisation-eq1";

import type { Booking, PagedSiteBookingsHookResult } from "../../../hooks/usePagedSiteBookings";
import { OperationActions } from "./OperationActions";
import { withContexts } from "../../../contexts/withContexts";
import { BookingViewModal } from "./BookingViewModal";
import { ScrollBottomObserverWrapper } from "../../../components/ScrollBottomObserverWrapper";
import { useRouter } from "next/router";
import { BookingModal } from "../contexts/BookingModalContext";

const Row: React.FC<Booking & { multiSiteUser: boolean }> = ({ multiSiteUser, ...b }) => {
  const { timezone } = useSiteContext();
  const { colors } = useTheme(true);
  const { i18n } = useTranslation();

  return (
    <>
      <tr className={`booking-row ${b.cancelled ? "cancelled" : ""}`}>
        <td>
          <Tag size="large">{b.reference}</Tag>
        </td>
        {multiSiteUser && (
          <td>{[b.resource.building?.name, b.resource.destination.name].filter(stringNotEmpty).join(", ")}</td>
        )}
        <td>{b.resource.name}</td>
        <td>
          <DateTimeUi.DateDisplay language={i18n.language} datetime={b.startDate} timezone={timezone} />
        </td>
        <td>
          <DateTimeUi.TimeRange language={i18n.language} start={b.startDate} end={b.endDate} timezone={timezone} />
        </td>
        <td>
          {b.user.profile?.firstName} {b.user.profile?.lastName}
        </td>
        <td>{b.user.profile?.companyV2?.name}</td>
        <td>
          <OperationActions booking={b} />
        </td>
      </tr>
      <style jsx>{`
        .cancelled td {
          color: ${colors.muted0};
        }
      `}</style>
    </>
  );
};

const ListLocal: React.FC<PagedSiteBookingsHookResult & { multiSiteUser: boolean; isPrinting: boolean }> = ({
  bookings,
  error,
  loading,
  hasMoreData,
  multiSiteUser,
  isPrinting,
  fetchMore,
}) => {
  const { t } = useTranslation();
  const { tError } = useApolloErrorTranslation();

  // Open/close booking side modal based on query param.
  const router = useRouter();
  const modal = useContext(BookingModal);
  useEffect(() => {
    if (typeof router.query.booking === "string") {
      if (modal.id !== router.query.booking) {
        modal.setDisplayMode("view");
        modal.open(router.query.booking);
      }
    }
  }, [modal, router]);

  if (error != null) {
    return <div>{tError(error)}</div>;
  }

  const onBottomVisible = () => {
    if (hasMoreData && !loading) {
      fetchMore();
    }
  };

  return (
    <>
      <BookingViewModal />
      <ScrollBottomObserverWrapper onBottomVisible={onBottomVisible}>
        <InfiniteScrollTable
          loading={loading || isPrinting}
          hasMoreData={hasMoreData}
          scrollDownToLoad={t("bookings.operations.scrollDownToLoad")}
          rowHeaders={[
            t("bookings.operations.refNo"),
            ...(multiSiteUser ? [t("bookings.operations.location")] : []),
            t("bookings.operations.resource"),
            t("common.date"),
            t("common.time"),
            t("common.host"),
            t("common.company"),
            "",
          ]}
          rowCount={bookings.length}
          fetchMore={fetchMore}
        >
          {bookings.length === 0 && !loading && (
            <tr>
              <td colSpan={multiSiteUser ? 8 : 7}>
                <EmptyState />
              </td>
            </tr>
          )}
          {!isPrinting &&
            bookings.map((booking) => <Row key={booking.uuid} {...booking} multiSiteUser={multiSiteUser} />)}
        </InfiniteScrollTable>
      </ScrollBottomObserverWrapper>
    </>
  );
};

export const OperationsList = withContexts(ListLocal, "adminForm");
