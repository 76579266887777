import React, { forwardRef } from "react";
import { optionLabel, type Option } from "./model/Option";
import { useTheme } from "../../../contexts";
import { FormSelectFacade } from "../FormSelect/FormSelectFacade";
import { useTranslation } from "@equiem/localisation-eq1";

const MAX_ICON_DISPLAY = 5;

interface P {
  localValue: string[];
  options: Option[];
  disabled?: boolean;
  emptyLabel?: React.ReactNode | string;
  className?: string;
}
export const FormFacadeSummary = forwardRef<HTMLDivElement, P>(
  ({ localValue, options, disabled = false, emptyLabel, className = "" }, ref) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const icons = localValue.length > 0 ? localValue.slice(0, MAX_ICON_DISPLAY) : [];

    return (
      <>
        <FormSelectFacade disabled={disabled} className={className} ref={ref}>
          <>
            {icons.length > 0 ? (
              <div className="multi">
                <div className="group">
                  {icons.map((value) => {
                    const option = options.find((o) => o.value === value);
                    return option == null ? null : (
                      <div className="individual" key={option.value}>
                        {optionLabel(option, true)}
                      </div>
                    );
                  })}
                </div>
                <div>{t("requests.details.watcher", { count: localValue.length })}</div>
              </div>
            ) : (
              <>{emptyLabel}</>
            )}
          </>
        </FormSelectFacade>
        <style jsx>{`
          .multi {
            gap: ${theme.spacers.s3};
            padding: 0 ${theme.spacers.s3};
          }
          .group,
          .multi {
            display: flex;
            align-items: center;
          }
          .individual {
            margin-left: -${theme.spacers.s3};
          }
        `}</style>
      </>
    );
  },
);

FormFacadeSummary.displayName = "FormFacadeSummary";
