import { CurrentProfile } from "@equiem/lib";
import { useContext } from "react";

const whitelistEmails = [
  "jeremy.kirkham@equiem.com.au",
  "ryan.ghanbari@getequiem.com",
  "rinita.sur@getequiem.com",
  "jen.leibhart@getequiem.com",
];

export const useShowAi = () => {
  const { profile } = useContext(CurrentProfile);

  return process.env.aiEnabled === "true" || (profile != null && whitelistEmails.includes(profile.email));
};
