import React, { forwardRef } from "react";
import type { ComponentProps } from "react";
import { useTheme } from "../../contexts";

export const AccordionListItem = forwardRef<HTMLLIElement, ComponentProps<"li">>(({ children }, ref) => {
  const { sizes } = useTheme();

  return (
    <li ref={ref}>
      {children}
      <style jsx>{`
        li {
          font-size: 14px;
          line-height: ${sizes.sm}px;
          padding-bottom: 5px;
        }
      `}</style>
    </li>
  );
});

AccordionListItem.displayName = "AccordionListItem";
