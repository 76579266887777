import React from "react";

import { useTranslation } from "@equiem/localisation-eq1";

import type { CompanyDetailsQuery } from "../../../generated/settings-client";

import { RequestManagementPermission } from "./RequestManagementPermission";

interface P {
  company: NonNullable<CompanyDetailsQuery["companyV2"]>;
  modalClose: () => void;
}
export const CompanyPermissions: React.FC<P> = ({ company, modalClose }) => {
  const { t } = useTranslation();

  return (
    <div>
      <p>{t("settings.editCompany.permissionIntro")}</p>
      <RequestManagementPermission company={company} modalClose={modalClose} />
    </div>
  );
};
