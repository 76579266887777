import React from "react";
import { BookingPaymentBillingCustomer } from "./BookingPaymentBillingCustomer";
import { useBookingBillingCustomersQuery } from "../../../../generated/gateway-client";
import { useTranslation } from "@equiem/localisation-eq1";
import { Alert, Form, Skeleton } from "@equiem/react-admin-ui";

export const BookingPaymentBillingCustomers: React.FC = () => {
  const { data, loading } = useBookingBillingCustomersQuery({ fetchPolicy: "network-only" });
  const { t } = useTranslation();

  if (loading) {
    return (
      <>
        <Skeleton.Line width="25%" height="25px" className="mb-1" />
        <Skeleton.Line width="100%" height="60px" className="d-block mb-3 rounded" />
      </>
    );
  }
  const billingCustomers = data?.myBillingCustomers ?? [];

  if (billingCustomers.length === 0) {
    return (
      <Alert
        message={t("bookings.operations.noBillingCustomers")}
        variant="neutral"
        size="large"
        className="mb-4 small"
      />
    );
  }

  return (
    <div className="invoice-lists">
      <Form.Group className="mb-4" label={"Billing details"}>
        {billingCustomers.map((billingCustomer, i) => (
          <BookingPaymentBillingCustomer key={i} billingCustomer={billingCustomer} />
        ))}
      </Form.Group>
    </div>
  );
};
