import React, { useState } from "react";
import { useDisabledSiteSwitcher } from "@equiem/lib";
import { Button, EmptyState, useTheme } from "@equiem/react-admin-ui";
import { RiAddLine } from "@equiem/react-admin-ui/icons";

import { type BookableResourceStatus, useResourceCountsQuery } from "../../generated/gateway-client";
import { BookingsTab } from "../../components/BookingsTab";
import { ResourceCard, ResourceCardLoading } from "./components/ResourceCard";
import { useRouter } from "next/router";
import { useTranslation } from "@equiem/localisation-eq1";
import { defaultTabFilter, ResourcesListStatusTabs } from "./ResourcesListStatusTabs";
import { ScrollBottomObserverWrapper } from "../../components/ScrollBottomObserverWrapper";
import { usePagedResources } from "../../hooks/usePagedResources";

export const ResourcesList = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { breakpoints, spacers } = useTheme(true);
  const [tabFilter, setTabFilter] = useState(defaultTabFilter);

  useDisabledSiteSwitcher();

  const counts = useResourceCountsQuery({
    variables: {
      permissionFilters: { canManageBookings: true },
    },
  });

  const { resources, searchText, setSearchText, error, loading, hasMoreData, fetchMore, refetch } = usePagedResources({
    filters: { status: tabFilter },
    permissionFilters: { canManageBookings: true },
  });
  const tabResources = resources.filter((r) => tabFilter == null || r.status === tabFilter);

  const handleTabFilterChange = (newTabFilter: BookableResourceStatus | null) => {
    setSearchText("");
    setTabFilter(newTabFilter);
  };

  const isEmpty = !loading && !hasMoreData && resources.length === 0;

  return (
    <>
      <BookingsTab
        title={
          <ResourcesListStatusTabs
            setTabFilter={handleTabFilterChange}
            countLoading={counts.loading}
            totalCount={counts.data?.bookableResourceCounts.all}
            publishedCount={counts.data?.bookableResourceCounts.published}
            draftCount={counts.data?.bookableResourceCounts.draft}
          />
        }
        search={{ searchText, setSearchText }}
        button={
          <Button
            className="main-button"
            variant="primary"
            size="md"
            onClick={() => {
              router.push("/bookings/resources/new-resource").catch(console.error);
            }}
          >
            <RiAddLine size={16} />
            {t("bookings.resources.newResource")}
          </Button>
        }
      >
        <ScrollBottomObserverWrapper onBottomVisible={fetchMore}>
          {error != null || !isEmpty ? (
            <div className="listing">
              {error != null && <div>{error.message}</div>}
              {tabResources.map((resource) => (
                <ResourceCard key={resource.uuid} {...resource} triggerRefresh={refetch} />
              ))}
              {loading && Array.from({ length: 6 }).map((_, index) => <ResourceCardLoading key={index} />)}
            </div>
          ) : (
            <div className="empty-listing">
              <EmptyState />
            </div>
          )}
        </ScrollBottomObserverWrapper>
      </BookingsTab>
      <style jsx>{`
        .listing {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(307px, 1fr));
          justify-items: center;
          grid-gap: ${spacers.s7} ${spacers.s5};
          padding: ${spacers.s4} ${spacers.s7} ${spacers.s7};
        }
        .empty-listing {
          padding: ${spacers.s7};
        }
        .main-button {
          text-transform: uppercase;
        }
        @media screen and (max-width: ${breakpoints.md}px) {
          .listing {
            grid-template-columns: 1fr;
          }
        }
        @media screen and (max-width: ${breakpoints.sm}px) {
          .listing {
            padding: 16px;
          }
        }
      `}</style>
    </>
  );
};
