import { useTranslation } from "@equiem/localisation-eq1";
import { FilterTab, useTheme } from "@equiem/react-admin-ui";
import React from "react";

export const myBookingTabs = ["all", "upcoming", "past", "cancelled"] as const;
export type Tab = (typeof myBookingTabs)[number];

interface Props {
  activeTab: Tab;
  setActiveTab: (_activeTab: Tab) => unknown;
}

export const MyBookingsStatusTabs: React.FC<Props> = ({ activeTab, setActiveTab }) => {
  const { t } = useTranslation();
  const { spacers } = useTheme(true);

  const tabTitles: Record<Tab, string> = {
    all: t("common.all"),
    upcoming: t("bookings.operations.upcoming"),
    past: t("bookings.operations.past"),
    cancelled: t("bookings.operations.cancelled"),
  };

  return (
    <div className="bookings-tabs-container">
      {myBookingTabs.map((tab) => (
        <FilterTab
          key={tab}
          title={tabTitles[tab]}
          isActive={activeTab === tab}
          onClick={() => {
            setActiveTab(tab);
          }}
        />
      ))}
      <style jsx>{`
        .bookings-tabs-container {
          display: flex;
          gap: ${spacers.s3};
        }
        .bookings-tabs-container :global(.tab) {
          font-size: 14px;
          text-transform: capitalize;
        }
      `}</style>
    </div>
  );
};
