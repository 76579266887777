import { stringNotEmpty, StripeProvider } from "@equiem/lib";
import { useErrorTranslation, useTranslation } from "@equiem/localisation-eq1";
import React, { useContext, useEffect } from "react";
import { BookingForm } from "./BookingForm";
import { useBookableResourceQuery } from "../../../generated/gateway-client";
import { BookingModal } from "../contexts/BookingModalContext";
import { BookingModalInfoProvider } from "../contexts/BookingModalInfoProvider";
import { BookingModalContentLoading } from "./BookingModalContentLoading";

interface P {
  uuid: string;
  start?: number;
  end?: number;
}
export const BookingCreateNewModalContent: React.FC<P> = ({ uuid, start, end }) => {
  const { t } = useTranslation();
  const { tError } = useErrorTranslation();
  const modal = useContext(BookingModal);

  const { data, loading, error } = useBookableResourceQuery({
    variables: { uuid },
    fetchPolicy: "network-only",
  });

  const resource = data?.bookableResource;

  useEffect(() => {
    const title = resource?.name;
    if (stringNotEmpty(title)) {
      modal.setTitle(title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource?.name]);

  if (loading) {
    return <BookingModalContentLoading />;
  }

  if (error != null) {
    return <div>{tError(error)}</div>;
  }

  if (resource == null) {
    return <div>{t("bookings.operations.resourceNotFound")}</div>;
  }

  return (
    <BookingModalInfoProvider resource={resource} start={start} end={end}>
      <StripeProvider>
        <BookingForm />
      </StripeProvider>
    </BookingModalInfoProvider>
  );
};
