import { Array, Literal, Number, Record, String } from "runtypes";

export const title = Record({
  field: Literal("title"),
  from: Record({ value: String }),
  to: Record({ value: String }),
});

export const startDate = Record({
  field: Literal("startDate"),
  from: Record({ dateTime: Number }),
  to: Record({ dateTime: Number }),
});

export const endDate = Record({
  field: Literal("endDate"),
  from: Record({ dateTime: Number }),
  to: Record({ dateTime: Number }),
});

export const startTime = Record({
  field: Literal("startTime"),
  from: Record({ value: String }),
  to: Record({ value: String }),
});

export const endTime = Record({
  field: Literal("endTime"),
  from: Record({ value: String }),
  to: Record({ value: String }),
});

export const daysBeforeBookingToApply = Record({
  field: Literal("daysBeforeBookingToApply"),
  from: Record({ integer: Number }),
  to: Record({ integer: Number }),
});

export const percentage = Record({
  field: Literal("percentage"),
  from: Record({ integer: Number }),
  to: Record({ integer: Number }),
});

export const availabilityDays = Record({
  field: Literal("availabilityDays"),
  fromValues: Array(Record({ value: String })),
  toValues: Array(Record({ value: String })),
});

export const resources = Record({
  field: Literal("resources"),
  fromValues: Array(
    Record({ bookableResource: Record({ uuid: String, name: String, __typename: Literal("BookableResource") }) }),
  ),
  toValues: Array(
    Record({ bookableResource: Record({ uuid: String, name: String, __typename: Literal("BookableResource") }) }),
  ),
});

export const discountCreated = Record({
  field: Literal("bookingDiscountCreated"),
  from: Record({ value: String }),
  to: Record({ value: String }),
});

export const discountDeleted = Record({
  field: Literal("bookingDiscountDeleted"),
  from: Record({ value: String }),
  to: Record({ value: String }),
});
