import React from "react";

import { useTheme } from "@equiem/react-admin-ui";
import { RiAccountCircleFill } from "@equiem/react-admin-ui/icons";

interface P {
  text: string;
}
export const HumanTag: React.FC<P> = ({ text }) => {
  const theme = useTheme();

  return (
    <>
      <div className="d-flex align-items-center">
        <RiAccountCircleFill color={theme.colors.grayscale["40"]} className="mr-2" size="24px" /> {text}
      </div>
      <style jsx>{`
        div {
          color: ${theme.colors.grayscale["50"]};
        }
      `}</style>
    </>
  );
};
