import { useState, useCallback, useEffect } from "react";
import { notNullOrUndefined, stringNotEmpty } from "@equiem/lib";
import { useDebounced } from "@equiem/react-admin-ui";

import { type ResourceCatalogueQueryVariables, useResourceCatalogueQuery } from "../generated/gateway-client";

const SEARCH_TEXT_DEBOUNCE_MS = 500;
const DEFAULT_PAGE_SIZE = 20;

interface Options {
  pageSize?: number;
  forceLoadAllPages?: boolean;
}

export const usePagedResources = (
  variables: Omit<ResourceCatalogueQueryVariables, "page">,
  { pageSize = DEFAULT_PAGE_SIZE, forceLoadAllPages = false }: Options = {},
) => {
  const [searchText, setSearchText] = useState("");
  const nameFilter = useDebounced(searchText.trim(), SEARCH_TEXT_DEBOUNCE_MS);

  const result = useResourceCatalogueQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      ...variables,
      filters: { ...(variables.filters ?? {}), name: stringNotEmpty(nameFilter) ? nameFilter : null },
      page: { first: pageSize, after: null },
    },
  });
  const [loadingMore, setLoadingMore] = useState(false);
  const loading = result.loading || loadingMore;

  const hasMoreData = result.data?.bookableResourcesV2.pageInfo.hasNextPage ?? false;
  const endCursor = result.data?.bookableResourcesV2.pageInfo.endCursor ?? null;
  const canFetchMore = !loading && hasMoreData && endCursor != null;

  const fetchMoreAsync = useCallback(
    async (latestEndCursor = endCursor) =>
      result.fetchMore({
        variables: { ...variables, page: { first: pageSize, after: latestEndCursor } },
        updateQuery: (prev, { fetchMoreResult }) => {
          return {
            ...fetchMoreResult,
            bookableResourcesV2: {
              ...fetchMoreResult.bookableResourcesV2,
              edges: [...prev.bookableResourcesV2.edges, ...fetchMoreResult.bookableResourcesV2.edges],
            },
          };
        },
      }),
    [endCursor, pageSize, result, variables],
  );

  const fetchMore = useCallback(() => {
    if (!canFetchMore) {
      return;
    }

    setLoadingMore(true);
    fetchMoreAsync()
      .catch(console.error)
      .finally(() => setLoadingMore(false));
  }, [canFetchMore, fetchMoreAsync]);

  const fetchAll = useCallback(async () => {
    if (!canFetchMore) {
      return;
    }

    setLoadingMore(true);
    try {
      let latestEndCursor: string | null = endCursor;
      let latestHasMoreData: boolean = hasMoreData;
      while (latestHasMoreData) {
        const next = await fetchMoreAsync(latestEndCursor);
        latestEndCursor = next.data.bookableResourcesV2.pageInfo.endCursor ?? null;
        latestHasMoreData = next.data.bookableResourcesV2.pageInfo.hasNextPage;
      }
    } finally {
      setLoadingMore(false);
    }
  }, [canFetchMore, endCursor, hasMoreData, fetchMoreAsync]);

  useEffect(() => {
    if (canFetchMore && forceLoadAllPages) {
      fetchAll().catch(console.error);
    }
  }, [canFetchMore, forceLoadAllPages, fetchAll]);

  return {
    resources: (result.data?.bookableResourcesV2.edges ?? []).map((e) => e.node).filter(notNullOrUndefined),
    error: result.error,

    searchText,
    setSearchText,

    called: result.called,
    loading,
    loadingOriginal: result.loading,
    loadingMore,
    hasMoreData,

    fetchMore,
    fetchAll,
    refetch: result.refetch,
  };
};
