import { useContext, useMemo } from "react";
import type { ApolloError } from "@apollo/client";

import { CurrentRole, notNullOrUndefined, Role, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { useToast } from "@equiem/react-admin-ui";

import type { Building, Space } from "../../../generated/settings-client";
import {
  useAddBuildingLevelMutation,
  useAddSpaceMutation,
  useCreateOrUpdateBuildingMutation,
  useDeleteBuildingLevelMutation,
  useDeleteBuildingMutation,
  useDeleteSpaceMutation,
  useDestinationBuildingsQuery,
  useDuplicateBuildingMutation,
  useDuplicateLevelMutation,
  useFlexManagerBuildingsQuery,
  useFlexManagerLevelsLazyQuery,
  useUpdateBuildingLevelMutation,
  useUpdateBuildingOrderMutation,
  useUpdateLevelOrderMutation,
  useUpdateSpaceMutation,
  useUpdateSpaceOrderMutation,
} from "../../../generated/settings-client";
import { BuildingDataContext } from "../contexts/BuildingDataContext";
import type { BuildingFormValues, BuildingSpaceFormValues } from "../types";

type Level = { uuid: string; name: string; spaces: Space[]; count: number };

export function useBuildingData() {
  const NEW_ID = "new";
  const { t } = useTranslation();
  const site = useSiteContext();
  const toast = useToast();
  const { currentRole } = useContext(CurrentRole);
  const isFlexManager = currentRole === Role.FlexManager;
  const { selectedBuildingUuid, setSelectedBuildingUuid, selectedLevelUuid, setSelectedLevelUuid } =
    useContext(BuildingDataContext);

  const { data: destinationData, loading } = useDestinationBuildingsQuery({
    variables: {
      uuid: site.uuid,
    },
    skip: isFlexManager,
  });

  const { data: flexBuildings, loading: flexBuildingsLoading } = useFlexManagerBuildingsQuery({
    variables: {
      first: 100,
    },
    skip: !isFlexManager,
  });

  const [flexLevelQuery, { data: flexLevelData, loading: flexLevelLoading }] = useFlexManagerLevelsLazyQuery();
  const [addSpaceMutation, { loading: createLoading }] = useAddSpaceMutation();
  const [updateSpaceMutation, { loading: updateSpaceLoading }] = useUpdateSpaceMutation();
  const [deleteSpaceMutation] = useDeleteSpaceMutation();
  const [createOrUpdateBuildingMutation, { loading: updateBuildingLoading }] = useCreateOrUpdateBuildingMutation();
  const [deleteBuildingMutation] = useDeleteBuildingMutation();
  const [updateLevelMutation, { loading: updateLevelLoading }] = useUpdateBuildingLevelMutation();
  const [updateLevelOrderMutation, { loading: updateLevelOrderLoading }] = useUpdateLevelOrderMutation();
  const [updateSpaceOrderMutation, { loading: updateSpaceOrderLoading }] = useUpdateSpaceOrderMutation();
  const [updateBuildingOrderMutation, { loading: updateBuildingOrderLoading }] = useUpdateBuildingOrderMutation();
  const [duplicateLevelMutation, { loading: duplicateLevelLoading }] = useDuplicateLevelMutation();
  const [duplicateBuildingMutation, { loading: duplicateBuildingLoading }] = useDuplicateBuildingMutation();
  const [deleteBuildingLevelMutation, { loading: deleteBuildingLevelLoading }] = useDeleteBuildingLevelMutation();
  const [addBuildingLevelMutation, { loading: addBuildingLevelLoading }] = useAddBuildingLevelMutation();

  const buildingsList = useMemo(() => {
    if (isFlexManager) {
      return flexBuildings?.myFlexBuildings.edges.flatMap((edge) => edge.node);
    }

    return destinationData?.destination.buildings;
  }, [flexBuildings, destinationData]);

  const selectedBuilding: Building | undefined = useMemo(() => {
    if (buildingsList == null) {
      return undefined;
    }
    if (isFlexManager && selectedBuildingUuid != null) {
      void flexLevelQuery({
        variables: {
          building: selectedBuildingUuid,
        },
      });
    }
    return buildingsList.find((building) => building?.uuid === selectedBuildingUuid) as Building;
  }, [flexLevelQuery, buildingsList, selectedBuildingUuid]);

  const levels = useMemo(() => {
    const variables = (level: Level) => ({
      id: level.uuid,
      title: level.name,
      count: level.spaces.length,
      spaces: level.spaces,
    });
    if (isFlexManager && flexLevelData != null) {
      return flexLevelData.myFlexLevels.map((level) => variables(level as Level));
    }
    return selectedBuildingUuid != null && selectedBuilding?.buildingLevels != null
      ? selectedBuilding.buildingLevels.map((level) => variables(level as unknown as Level))
      : undefined;
  }, [flexLevelData, selectedBuilding, selectedBuildingUuid]);

  const selectedLevel = useMemo(() => {
    if (levels == null) {
      return undefined;
    }
    return levels.find((level: { id: string }) => level.id === selectedLevelUuid);
  }, [selectedLevelUuid, levels]);

  const spaces = useMemo(() => {
    return selectedLevelUuid != null
      ? selectedLevel?.spaces.map((space) => ({
          id: space.uuid,
          title: space.name,
          ownerCompanyUuid: space.ownerCompany.uuid,
          providerSpaceName: space.providerSpaceName,
        })) ?? []
      : undefined;
  }, [selectedLevel, selectedLevelUuid]);

  const buildings = useMemo(() => {
    return buildingsList
      ?.map((building) => ({
        id: building?.uuid,
        title: building?.name,
      }))
      .filter(notNullOrUndefined);
  }, [buildingsList]);

  const updateLevel = async (id: string, title: string) => {
    if (id === NEW_ID) {
      if (levels != null && selectedBuilding != null) {
        if (
          levels
            .filter((c) => c.id !== id)
            .map((c) => c.title.toLowerCase().replace(/\s/g, ""))
            .includes(title.toLowerCase().replace(/\s/g, ""))
        ) {
          toast.negative(t("settings.build.levelExists"));
          return;
        }

        await addBuildingLevelMutation({
          variables: {
            input: {
              name: title,
              buildingUuid: selectedBuilding.uuid ?? "",
            },
          },
          refetchQueries: ["DestinationBuildings", "FlexManagerBuildings"],
        });
      }
    } else {
      try {
        await updateLevelMutation({
          variables: {
            input: {
              uuid: id,
              name: title,
            },
          },
          refetchQueries: ["DestinationBuildings", "FlexManagerLevels"],
        });
      } catch (e: unknown) {
        const error = e as ApolloError;
        toast.negative(error.message);
      }
    }
  };

  const deleteLevel = async (id: string) => {
    try {
      const levelName = levels?.find((level) => level.id === id)?.title ?? "";

      await deleteBuildingLevelMutation({
        variables: {
          uuid: id,
        },
        refetchQueries: ["DestinationBuildings", "FlexManagerLevels"],
      });

      toast.positive(t("settings.build.deleteSuccess", { name: levelName }));
    } catch (e: unknown) {
      const error = e as ApolloError;
      toast.negative(error.message);
    }
  };

  const updateSpace = async ({ uuid = "new", title, ownerCompanyUuid, providerSpaceName }: BuildingSpaceFormValues) => {
    if (selectedLevelUuid != null) {
      try {
        const vars: { name: string; ownerCompanyUuid?: string } = {
          name: title,
        };
        if (uuid === NEW_ID) {
          await addSpaceMutation({
            variables: {
              input: {
                ...vars,
                ownerCompanyUuid,
                providerSpaceName,
                buildingLevelUuid: selectedLevelUuid,
              },
            },
            refetchQueries: ["DestinationBuildings", "FlexManagerLevels"],
          });
        } else if (uuid !== "new") {
          await updateSpaceMutation({
            variables: {
              input: {
                ...vars,
                uuid,
                providerSpaceName,
              },
            },
            refetchQueries: ["DestinationBuildings", "FlexManagerLevels"],
          });
        }
      } catch (e: unknown) {
        const error = e as ApolloError;
        toast.negative(error.message);
        throw e;
      }
    }
  };

  const deleteSpace = async (uuid: string) => {
    if (selectedLevelUuid != null) {
      const spaceName = selectedLevel?.spaces.find((space) => space.uuid === uuid)?.name ?? "";

      const result = await deleteSpaceMutation({
        variables: {
          uuid,
        },
        refetchQueries: ["DestinationBuildings", "FlexManagerLevels"],
      });

      if (result.data?.deleteSpace === false) {
        toast.negative(t("settings.build.failedToDeleteSpace"));
        return;
      }

      toast.positive(t("settings.build.deleteSuccess", { name: spaceName }));
    }
  };

  const createOrUpdateBuilding = async (input: BuildingFormValues) => {
    const result = await createOrUpdateBuildingMutation({
      variables: {
        input: {
          uuid: input.uuid,
          name: input.name,
          area: input.area ?? 0,
          units: input.units ?? "",
          occupants: input.occupants ?? 0,
          destinationUuid: site.uuid,
          buildingAddress: input.addressStatus === "enabled" ? input.address : undefined,
          levels: input.uuid != null ? levels?.map((level) => level.title) ?? [] : [],
        },
      },
      refetchQueries: ["DestinationBuildings", "FlexManagerBuildings"],
    });

    if (result.data?.createOrUpdateBuilding.__typename === "BuildingSyncSuccess") {
      setSelectedBuildingUuid(result.data.createOrUpdateBuilding.building.uuid);
    }

    return result;
  };

  const deleteBuilding = async (uuid: string) => {
    const buildingName = buildingsList?.find((building) => building?.uuid === uuid)?.name;

    const result = await deleteBuildingMutation({
      variables: {
        uuid,
      },
      refetchQueries: ["DestinationBuildings", "FlexManagerBuildings"],
    });

    if (result.data?.deleteBuilding === false) {
      toast.negative(t("settings.build.failedToDeleteBuilding"));
      return;
    }

    toast.positive(t("settings.build.deleteSuccess", { name: buildingName ?? "" }));
  };

  const updateBuildingOrder = async (uuids: string[]) => {
    try {
      const result = await updateBuildingOrderMutation({
        variables: {
          uuids,
        },
        refetchQueries: ["DestinationBuildings", "FlexManagerBuildings"],
      });

      if (result.data?.updateBuildingOrder === false) {
        toast.negative(t("settings.build.failedToUpdateBuildingOrder"));
      }
    } catch (e: unknown) {
      const error = e as ApolloError;
      toast.negative(error.message);
    }
  };

  const updateLevelOrder = async (uuids: string[]) => {
    try {
      const result = await updateLevelOrderMutation({
        variables: {
          uuids,
        },
        refetchQueries: ["DestinationBuildings", "FlexManagerLevels"],
      });

      if (result.data?.updateBuildingLevelOrder === false) {
        toast.negative(t("settings.build.failedToUpdateLevelOrder"));
      }
    } catch (e: unknown) {
      const error = e as ApolloError;
      toast.negative(error.message);
    }
  };

  const updateSpaceOrder = async (uuids: string[]) => {
    try {
      const result = await updateSpaceOrderMutation({
        variables: {
          uuids,
        },
        refetchQueries: ["DestinationBuildings", "FlexManagerLevels"],
      });

      if (result.data?.updateSpaceOrder === false) {
        toast.negative(t("settings.build.failedToUpdateSpaceOrder"));
      }
    } catch (e: unknown) {
      const error = e as ApolloError;
      toast.negative(error.message);
    }
  };

  const duplicateLevel = async (uuid: string) => {
    try {
      const result = await duplicateLevelMutation({
        variables: {
          uuid,
        },
        refetchQueries: ["DestinationBuildings", "FlexManagerLevels"],
      });

      return result.data?.duplicateBuildingLevel;
    } catch (e: unknown) {
      const error = e as ApolloError;
      toast.negative(error.message);
      return null;
    }
  };

  const duplicateBuilding = async (uuid: string) => {
    try {
      const result = await duplicateBuildingMutation({
        variables: {
          uuid,
        },
        refetchQueries: ["DestinationBuildings", "FlexManagerBuildings"],
      });

      return result.data?.duplicateBuilding;
    } catch (e: unknown) {
      const error = e as ApolloError;
      toast.negative(error.message);
      return null;
    }
  };

  return {
    NEW_ID,
    spaces,
    levels,
    buildings,
    destinationBuildings: buildingsList,
    loading: loading || flexBuildingsLoading,
    selectedBuildingUuid,
    selectedLevelUuid,
    selectedBuilding,
    createLoading,
    updateLoading:
      updateBuildingLoading ||
      updateLevelLoading ||
      updateSpaceLoading ||
      updateLevelOrderLoading ||
      updateSpaceOrderLoading ||
      updateBuildingOrderLoading ||
      duplicateLevelLoading ||
      duplicateBuildingLoading ||
      deleteBuildingLevelLoading ||
      flexLevelLoading ||
      addBuildingLevelLoading,
    orderLoading: updateLevelOrderLoading || updateSpaceOrderLoading || updateBuildingOrderLoading,
    duplicateLoading: duplicateLevelLoading || duplicateBuildingLoading,
    setSelectedLevelUuid,
    setSelectedBuildingUuid,
    updateSpace,
    deleteSpace,
    updateLevel,
    deleteLevel,
    createOrUpdateBuilding,
    deleteBuilding,
    updateLevelOrder,
    updateSpaceOrder,
    updateBuildingOrder,
    duplicateLevel,
    duplicateBuilding,
  };
}
