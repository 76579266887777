import React, { useContext, useEffect, useMemo } from "react";
import { BookingViewBookingInfo } from "./BookingViewBookingInfo";
import { BookingViewExtras } from "./BookingViewExtras";
import { BookingViewButton } from "./BookingViewButton";
import { resourceConfigurationToString } from "../../../lib/resourceTypeToString";
import { Alert, Tag, Text, useTheme } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import { stringNotEmpty } from "@equiem/lib";
import { BookingStatus, CancellingUserRole } from "../../../generated/gateway-client";
import { BookingCalendarContext } from "../contexts/BookingCalendarContext";
import { DateTime } from "luxon";
import { dateFormat, toDefaultTime } from "../libs/formatSelectedTime";
import { RiErrorWarningLine } from "@equiem/react-admin-ui/icons";
import { BookingResourceSummary } from "./BookingResourceSummary";
import { MyBookingCharges } from "./MyBookingCharges";
import { canCancelBooking } from "../libs/canCancelBooking";
import { canEditBooking } from "../libs/canEditBooking";
import { BookingViewVisitors } from "./BookingViewVisitors";

export const BookingView: React.FC = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { booking, timezone } = useContext(BookingModalInfo);
  const { setSelectedDate, setSelectedTime } = useContext(BookingCalendarContext);

  useEffect(() => {
    // For calendar display purporses, we tell what user picked.
    if (booking?.startDate != null) {
      setSelectedDate(timezone, DateTime.fromMillis(booking.startDate, { zone: timezone }).toFormat(dateFormat));
      setSelectedTime(timezone, {
        start: toDefaultTime(booking.startDate, timezone, false),
        end: toDefaultTime(booking.endDate, timezone, true),
      });
    }
  }, [booking?.endDate, booking?.startDate, setSelectedDate, setSelectedTime, timezone]);

  const uneditableMessage = useMemo(() => {
    if (booking != null) {
      const bookingActive = !booking.isPast && !booking.cancelled;
      if (bookingActive) {
        const canCancel = canCancelBooking(booking);
        const canEdit = canEditBooking(booking);

        if (!canCancel && !canEdit) {
          return t("bookings.operations.pleaseContactManagerToCancelEdit");
        }
        if (!booking.isEditable) {
          return t("bookings.operations.ifYouWishToEditContactManager");
        }
      }
    }

    return null;
  }, [booking, t]);

  if (booking == null) {
    return null;
  }

  let cancelledMessage: string;
  switch (booking.cancelledByRole) {
    case CancellingUserRole.Host:
      cancelledMessage = t("bookings.operations.cancelledByHost");
      break;
    case CancellingUserRole.System:
      cancelledMessage = t("bookings.operations.cancelledBySystem");
      break;
    case CancellingUserRole.User:
      cancelledMessage = t("bookings.operations.cancelledByUser");
      break;
    default:
      cancelledMessage = t("bookings.operations.cancelled");
      break;
  }

  const roomConfig = booking.roomConfig?.name;

  return (
    <>
      {booking.status === BookingStatus.PendingApproval && (
        <Alert
          className="mb-4"
          size="large"
          variant="gray"
          icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
          message={t("bookings.operations.bookingReqManagerApproval")}
        />
      )}
      {booking.status === BookingStatus.Declined && (
        <Alert
          className="mb-4"
          variant="negative"
          size="large"
          icon={<RiErrorWarningLine size={18} />}
          message={
            stringNotEmpty(booking.cancelledReason)
              ? t("bookings.operations.bookingDeclinedByManagerForTheReason", { reason: booking.cancelledReason })
              : t("bookings.operations.bookingDeclinedByManager")
          }
        />
      )}
      {uneditableMessage != null && (
        <Alert
          className="mb-4"
          size="large"
          variant="gray"
          icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
          message={uneditableMessage}
        />
      )}
      <BookingResourceSummary className="mb-7" />
      <div className="d-flex justify-content-between align-items-center pb-4">
        <Text variant="heading" size="medium">
          {stringNotEmpty(booking.title) ? booking.title : t("bookings.operations.bookingDetailsTitle")}
        </Text>
        {booking.cancelled && (
          <Tag variant="negative" size="large">
            {cancelledMessage}
          </Tag>
        )}
      </div>

      <BookingViewBookingInfo className="pb-6" />
      {stringNotEmpty(roomConfig) && (
        <div className="pb-6">
          <Text variant="heading" size="medium" className="pb-4">
            {t("bookings.operations.configuration")}
          </Text>
          <Tag variant="default" className="pb-0">
            {resourceConfigurationToString(roomConfig, t)}
          </Tag>
        </div>
      )}
      <BookingViewVisitors className="pb-6" booking={booking} />
      <BookingViewExtras className="pb-4" />
      <MyBookingCharges booking={booking} />
      <BookingViewButton />
    </>
  );
};
