import { stringNotEmpty, centsToDollar, dollarToCents } from "@equiem/lib";
import { durationString } from "@equiem/react-admin-ui";
import { DateTime } from "luxon";
import { getCancellationRatesForForm, toInputCancellationRates } from "./cancellationRate";
import { convertInputMoneyToNumber } from "./convertInputMoneyToNumber";
import { convertInputNumberToNumber } from "./convertNumberStringToNumber";
import type { FormValues } from "./formValidation";
import { AvailabilityType, DATE_FORMAT } from "./formValidation";
import { transformSiteAudiences } from "./transformSiteAudiences";
import type { BookableResourceEditQuery, UpdateBookableResourceInput } from "../generated/gateway-client";
import { addOnToInput } from "../pages/resources/components/fields/addon/addOnToInput";
import { availabilityToInput } from "../pages/resources/components/fields/availability/availabilityToInput";
import { getVideoUrl } from "./getVideoUrl";
import { isVisitorManagementEnabled } from "../pages/resources/hooks/useIsVisitorManagementEnabled";

type Resource = BookableResourceEditQuery["bookableResource"];

const stringToDateRange = (range: string) => {
  const startInclusive = range.startsWith("[");
  const endInclusive = range.endsWith("]");
  let [startDate, endDate] = range
    .slice(1, range.length - 1)
    .split(",")
    .map((str) => DateTime.fromFormat(str.trim(), DATE_FORMAT));

  if (startDate.isValid && !startInclusive) {
    startDate = startDate.plus({ days: 1 });
  }

  if (endDate.isValid && !endInclusive) {
    endDate = endDate.plus({ days: -1 });
  }

  return {
    startDate: startDate.isValid ? startDate.toISODate() : undefined,
    endDate: endDate.isValid ? endDate.toISODate() : undefined,
  };
};

// eslint-disable-next-line complexity
export const toResourceFormValues = (br: Resource): FormValues => {
  const isSlots = br.availability.some((a) => a.__typename === "BookableResourceSlotsAvailability");
  const availabilityType = br.availability.length === 0 || isSlots ? AvailabilityType.slots : AvailabilityType.flexible;

  const bookingWindowMaxInMinutes = br.bookingWindowMaxInMinutes;
  const bookingWindowMinInMinutes = br.bookingWindowMinInMinutes;
  const video = stringNotEmpty(br.video) ? { videoUrl: br.video, thumbnailUrl: "" } : null;

  return {
    start: br.availabilityDateRange != null ? stringToDateRange(br.availabilityDateRange).startDate : undefined,
    end: br.availabilityDateRange != null ? stringToDateRange(br.availabilityDateRange).endDate : undefined,
    addOns: br.addOns.map((addOn) => ({
      ...addOn,
      options: addOn.options?.map((option) => ({
        ...option,
        unitPrice: centsToDollar(option.unitPrice),
      })),
    })),
    availability: br.availability,
    availabilityType,
    bookingWindowMaxInMinutes,
    bookingWindowMinInMinutes,
    prepTimeBeforeInMinutes: br.prepTimeBeforeInMinutes ?? undefined,
    prepTimeAfterInMinutes: br.prepTimeAfterInMinutes ?? undefined,
    autoApproveBookings: br.autoApproveBookings,
    allowVisitorInvites: isVisitorManagementEnabled(br) && br.allowVisitorInvites,
    allowRecurringBooking: br.allowRecurringBooking,
    description: br.description,
    displayCapacity: br.displayCapacity ?? "",
    termsAndConditions: br.termsAndConditions ?? "",
    status: br.status,
    images: br.images.map((i) => ({
      alt: i,
      key: i,
      thumbnail_key: i,
      title: i,
      url: i,
    })),
    videoTitle: br.videoTitle,
    video,
    title: br.name,
    site: br.destination.uuid,
    siteName: br.destination.name,
    typeV2: br.typeInfo.uuid,
    building: br.building?.uuid ?? "",
    buildingName: br.building?.name ?? "",
    buildingAddress: br.building?.address ?? "",
    level: br.level?.uuid,
    levelName: br.level?.name,
    features: br.features.map((f) => f.uuid),
    roomConfigurations: br.roomConfigurations.map((rc) => rc.uuid),
    steps: 0,
    userCanEditBookings: br.userCanEditBookings,
    editBookingNoticePeriodInMinutes: br.editBookingNoticePeriodInMinutes ?? "",
    editBookingTermsAndConditions: br.editBookingTermsAndConditions ?? "",
    children: br.children.map((child) => child.uuid),
    parentNames: br.parents.map((parent) => parent.name),
    externalSyncCalendarUrl: br.externalSyncCalendarUrl ?? null,
    externalSyncEmailAddress: br.externalSyncEmailAddress ?? null,
    siteAudiences: transformSiteAudiences(br.siteAudiences, br.destination.uuid),
    businessHoursStart: br.businessHoursStart,
    businessHoursEnd: br.businessHoursEnd,
    businessHoursHalfDayDuration: durationString.fromMinutes(br.businessHoursHalfDayDurationMinutes),
    paymentRateHourly: centsToDollar(br.baseRateHourly),
    paymentRateHalfDay: centsToDollar(br.baseRateHalfDay),
    paymentRateFullDay: centsToDollar(br.baseRateFullDay),
    paymentRateHourlyAfterHours: centsToDollar(br.baseRateHourlyAfterHours),
    paymentRateHourlyWeekend: centsToDollar(br.baseRateHourlyWeekend),
    paymentRateCancellation: getCancellationRatesForForm(br),
    paymentMethods: br.basePaymentMethods,
    taxExempt: br.taxExempt,
    ownerCompanyUuid: br.company.uuid,
    ownerCompanyName: br.company.name,
    barrierControlAccessUuid: br.barrierControlAccess?.uuid ?? null,
    barrierControlVisitorAccessUuid: isVisitorManagementEnabled(br)
      ? br.barrierControlVisitorAccess?.uuid ?? null
      : null,
  };
};

// eslint-disable-next-line complexity
export const toResourceEditInput = (values: FormValues): UpdateBookableResourceInput => {
  const startValue = stringNotEmpty(values.start) ? values.start : "";
  const endValue = stringNotEmpty(values.end) ? values.end : "infinity";
  const availabilityDateRange =
    stringNotEmpty(values.start) || stringNotEmpty(values.end) ? `[${startValue}, ${endValue}]` : null;
  const displayCapacity = convertInputNumberToNumber(values.displayCapacity, true);
  const { flexibleAvailability, slotsAvailability } = availabilityToInput(values);
  const bookingWindowMaxInMinutes = values.bookingWindowMaxInMinutes;
  const bookingWindowMinInMinutes = values.bookingWindowMinInMinutes;

  if (!stringNotEmpty(values.typeV2)) {
    // should never happen - this should only ever be called with validated `values`
    throw new Error("Missing resource type");
  }

  return {
    flexibleAvailability,
    slotsAvailability,
    availabilityDateRange,
    addOns: addOnToInput(values.addOns),
    bookingWindowMaxInMinutes,
    bookingWindowMinInMinutes,
    prepTimeBeforeInMinutes: convertInputNumberToNumber(values.prepTimeBeforeInMinutes, true),
    prepTimeAfterInMinutes: convertInputNumberToNumber(values.prepTimeAfterInMinutes, true),
    autoApproveBookings: values.autoApproveBookings ?? true,
    allowVisitorInvites: values.allowVisitorInvites ?? false,
    allowRecurringBooking: values.allowRecurringBooking ?? false,
    businessHoursStart: values.businessHoursStart,
    businessHoursEnd: values.businessHoursEnd,
    businessHoursHalfDayDurationMinutes: durationString.toMinutes(values.businessHoursHalfDayDuration),
    paymentMethods: values.paymentMethods,
    paymentRateHourly: dollarToCents(convertInputMoneyToNumber(values.paymentRateHourly, true)),
    paymentRateHalfDay: dollarToCents(convertInputMoneyToNumber(values.paymentRateHalfDay, true)),
    paymentRateFullDay: dollarToCents(convertInputMoneyToNumber(values.paymentRateFullDay, true)),
    paymentRateHourlyAfterHours: dollarToCents(convertInputMoneyToNumber(values.paymentRateHourlyAfterHours, true)),
    paymentRateHourlyWeekend: dollarToCents(convertInputMoneyToNumber(values.paymentRateHourlyWeekend, true)),
    paymentRateCancellation: toInputCancellationRates(values.paymentRateCancellation),
    taxExempt: values.taxExempt,
    description: values.description,
    displayCapacity,
    status: values.status,
    images: values.images.flatMap((i) => (i.url != null ? [i.url] : [])),
    videoTitle: stringNotEmpty(values.videoTitle) ? values.videoTitle.trim() : null,
    video: getVideoUrl(values.video),
    termsAndConditions: values.termsAndConditions ?? "",
    name: values.title,
    typeV2: values.typeV2,
    buildingUuid: values.building,
    levelUuid: stringNotEmpty(values.level) ? values.level : undefined,
    features: values.features,
    roomConfigurations: values.roomConfigurations,
    userCanEditBookings: values.userCanEditBookings ?? false,
    editBookingNoticePeriodInMinutes:
      values.userCanEditBookings === true ? convertInputNumberToNumber(values.editBookingNoticePeriodInMinutes) : null,
    editBookingTermsAndConditions:
      values.userCanEditBookings === true && stringNotEmpty(values.editBookingTermsAndConditions)
        ? values.editBookingTermsAndConditions
        : null,
    children: values.children,
    externalSyncCalendarUrl: stringNotEmpty(values.externalSyncCalendarUrl) ? values.externalSyncCalendarUrl : null,
    externalSyncEmailAddress: stringNotEmpty(values.externalSyncEmailAddress) ? values.externalSyncEmailAddress : null,
    siteAudiences: values.siteAudiences.map((value) => ({
      site: value.site,
      segmentIds: value.segmentIds,
      segmentSummary: value.segmentSummary,
      paymentMethods: value.paymentMethods,
      paymentRateFullDay: dollarToCents(convertInputMoneyToNumber(value.paymentRateFullDay)),
      paymentRateHalfDay: dollarToCents(convertInputMoneyToNumber(value.paymentRateHalfDay)),
      paymentRateHourly: dollarToCents(convertInputMoneyToNumber(value.paymentRateHourly)),
      paymentRateHourlyAfterHours: dollarToCents(convertInputMoneyToNumber(value.paymentRateHourlyAfterHours)),
      paymentRateHourlyWeekend: dollarToCents(convertInputMoneyToNumber(value.paymentRateHourlyWeekend)),
    })),
    ownerCompanyUuid: values.ownerCompanyUuid,
    barrierControlAccessUuid: stringNotEmpty(values.barrierControlAccessUuid) ? values.barrierControlAccessUuid : null,
    barrierControlVisitorAccessUuid:
      values.allowVisitorInvites === true && stringNotEmpty(values.barrierControlVisitorAccessUuid)
        ? values.barrierControlVisitorAccessUuid
        : null,
  };
};
