/* eslint-disable @typescript-eslint/no-magic-numbers */
import type { ComponentProps, FormEvent, KeyboardEvent } from "react";
import React, { useContext, useState, useMemo } from "react";
import type { BorderCssProps } from "../useInputBorderCss";
import { FormGroupContext } from "../../../contexts/FormGroupContext";
import { useInputBorderCss } from "../useInputBorderCss";
import { useInputPadding } from "../useInputPadding";
import { useTheme } from "../../../contexts/Theme";
import { Tooltip } from "../../Tooltip/Tooltip";
import { RiInformationLine } from "../../../admin-icons";

const convertStringToNumber = (target?: string | number | null) => {
  if (target == null || target === "") {
    return null;
  }

  const convert = Number(target);

  return Number.isNaN(convert) ? null : convert;
};

const format = (inputValue?: string | null) => {
  const convert = convertStringToNumber(inputValue);

  return convert?.toFixed(2);
};

// eslint-disable-next-line @typescript-eslint/no-type-alias
type Props = ComponentProps<"input"> & {
  variant?: "md" | "lg";
  currency: string;
  rounded?: BorderCssProps["rounded"];
  currencyPrefix?: string;
  tooltip?: string;
};
export const FormMoney = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { id, hintId } = useContext(FormGroupContext);
  const { colors, animationDuration } = useTheme();
  const [internalValue, setInternalValue] = useState("");
  const borderCss = useInputBorderCss({ rounded: props.rounded });
  const padding = useInputPadding();
  const lineHeight = useMemo(() => (props.variant === "lg" ? "24px" : "22px"), [props.variant]);

  // Catching each input press
  const numbersOnlyKeyDownEventListener = (e: KeyboardEvent<HTMLInputElement>) => {
    if (
      // Allow ctrl/option/command keys.
      e.metaKey ||
      e.code === "Backspace" ||
      e.code === "ArrowUp" ||
      e.code === "ArrowDown" ||
      e.code === "ArrowLeft" ||
      e.code === "ArrowRight" ||
      e.code === "Tab"
    ) {
      return;
    }

    // Prevent input if it's not a digit or a decimal point,
    // or if there's already a decimal point in the input field.
    if (!/^\d$/u.test(e.key) && (e.key !== "." || (e.currentTarget.value as string).includes("."))) {
      e.preventDefault();
    }
  };

  // Catching paste operation
  const numbersOnlyOnInputEventListener = (e: FormEvent<HTMLInputElement>) => {
    let encounteredDecimalPoint = false;
    e.currentTarget.value = e.currentTarget.value
      .replace(/[^\d.]+/gu, "") // Allow only digits and a single period
      .replace(/\./gu, (m) => {
        if (m === "." && !encounteredDecimalPoint) {
          encounteredDecimalPoint = true;
          return m;
        } else {
          return "";
        }
      }); // Remove all but the first decimal point
  };

  return (
    <>
      <div className={`input-money ${props.className ?? ""} ${borderCss.className}`}>
        <span className="currency">
          {props.currencyPrefix ?? null}
          {props.currency}
        </span>
        <input
          {...props}
          type="text"
          ref={ref}
          id={props.id ?? id}
          aria-describedby={hintId}
          value={
            // I can't put this on the state init, when I save and back to form,
            // it has 2 values, and it picked the old value.
            internalValue === "" ? format(`${props.value ?? ""}`) : internalValue
          }
          onChange={(e) => {
            setInternalValue(e.currentTarget.value);

            if (props.onChange != null) {
              props.onChange(e);
            }
          }}
          onBlur={(e) => {
            setInternalValue(format(e.currentTarget.value) ?? "");

            if (props.onBlur != null) {
              props.onBlur(e);
            }
          }}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            numbersOnlyKeyDownEventListener(e);

            if (props.onKeyDown != null) {
              props.onKeyDown(e);
            }
          }}
          onInput={(e: FormEvent<HTMLInputElement>) => {
            numbersOnlyOnInputEventListener(e);

            if (props.onInput != null) {
              props.onInput(e);
            }
          }}
        />
        {props.tooltip != null && (
          <Tooltip placement="top" title={props.tooltip}>
            <div>
              <RiInformationLine color={colors.grayscale[60]} size={16} />
            </div>
          </Tooltip>
        )}
      </div>
      <style jsx>{`
        .input-money {
          display: flex;
          align-items: center;
          padding: ${padding.shorthand};
          font-size: ${props.variant === "lg" ? "16px" : "14px"} !important;
          line-height: ${lineHeight} !important;
        }
        input {
          border: 0;
          appearance: none;
          cursor: pointer;
          outline: none;
          transition: ${animationDuration} box-shadow, border;
          width: 100%;
          position: relative;
          background: #fff;
        }
        input::placeholder {
          color: ${colors.muted1};
        }
      `}</style>
      {borderCss.styles}
    </>
  );
});

FormMoney.displayName = "FormMoney";
