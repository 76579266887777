import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { isEmpty } from "lodash";

import { CurrentProfile, notNullOrUndefined, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Avatar, Form, Skeleton, useTheme } from "@equiem/react-admin-ui";

import { HumanTag } from "../../../components/HumanTag";
import { ProfileTag } from "../../../components/ProfileTag";
import type { RequestQuery } from "../../../generated/requests-client";
import { useListProfilesQuery } from "../../../generated/requests-client";
import { RequestDetailsContext } from "../contexts/RequestDetailsContext";

interface P {
  request: RequestQuery["reqMgt"]["request"];
}
export const RequestWatchers: React.FC<P> = ({ request }) => {
  const showError = useShowError();
  const theme = useTheme();
  const { t } = useTranslation();
  const profile = useContext(CurrentProfile);
  const currentUuids = useMemo(() => {
    return request.watchers.map((w) => w?.uuid).filter(notNullOrUndefined);
  }, [request.watchers]);
  const [selectedUuids, setSelectedUuids] = useState(currentUuids);

  useEffect(() => {
    setSelectedUuids(currentUuids);
  }, [currentUuids]);

  const { data, loading, error, refetch } = useListProfilesQuery({
    fetchPolicy: "network-only",
    skip: isEmpty(profile.siteProfile?.site.destination?.uuid),
    variables: {
      page: {
        first: 5,
      },
      filter: {
        active: true,
      },
      siteUuids: [profile.siteProfile?.site.destination?.uuid ?? ""],
    },
  });
  const { loading: updateLoading, update } = useContext(RequestDetailsContext);

  if (error != null) {
    showError(error);
  }

  const searchCb = useCallback(
    async (keyword: string) => {
      const kl = keyword.toLowerCase();
      const filter = kl.length === 0 ? {} : kl.includes("@") ? { email: kl } : { name: kl, email: kl };

      try {
        await refetch({ filter: { active: true, ...filter } });
      } catch (e: unknown) {
        showError(e);
      }
    },
    [refetch, showError],
  );

  const options = useMemo(() => {
    const lists = (data?.listProfiles.edges ?? []).flatMap((edge) => {
      if (edge.node == null || request.watchers.some((watcher) => watcher?.uuid === edge.node?.uuid)) {
        return [];
      }

      return [
        {
          label: {
            searchText: `${edge.node.firstName} ${edge.node.lastName}`,
            element: (
              <ProfileTag
                profileIconUrl={edge.node.avatar ?? undefined}
                companyName={edge.node.companyV2?.name ?? ""}
                email={edge.node.email}
                firstName={edge.node.firstName ?? ""}
                lastName={edge.node.lastName ?? ""}
              />
            ),
            facadeElement: (
              <Avatar
                size="small"
                imageUrl={edge.node.avatar ?? undefined}
                firstName={edge.node.firstName ?? ""}
                lastName={edge.node.lastName ?? ""}
              />
            ),
          },
          value: edge.node.uuid,
        },
      ];
    });

    return request.watchers.length > 0
      ? request.watchers
          .flatMap((watcher) => {
            if (watcher == null) {
              return [];
            }

            return {
              label: {
                searchText: `${watcher.firstName} ${watcher.lastName}`,
                element: (
                  <ProfileTag
                    profileIconUrl={watcher.avatar ?? undefined}
                    companyName={watcher.companyV2?.name ?? ""}
                    email={watcher.email}
                    firstName={watcher.firstName ?? ""}
                    lastName={watcher.lastName ?? ""}
                  />
                ),
                facadeElement: (
                  <Avatar
                    size="small"
                    imageUrl={watcher.avatar ?? undefined}
                    firstName={watcher.firstName ?? ""}
                    lastName={watcher.lastName ?? ""}
                  />
                ),
              },
              value: watcher.uuid,
            };
          })
          .concat(lists)
      : lists;
  }, [data?.listProfiles.edges, request.watchers]);

  if (loading) {
    return <Skeleton.Line width="250px" height="50px" />;
  }

  return (
    <>
      <div>
        <Form.MultiSelect
          options={options}
          mobileView="minimal"
          onChange={(e) => {
            setSelectedUuids(e.target.value);
          }}
          facade="summary"
          variant="wrap"
          className="watchers"
          searchCb={searchCb}
          searchPlaceholder={t("common.findAPerson")}
          emptyLabel={<HumanTag text={t("requests.unassigned") as string} />}
          value={selectedUuids}
          disabled={updateLoading}
          onClose={(selected) => {
            const same =
              selected.length === currentUuids.length &&
              selected.map((uuid) => currentUuids.some((s) => s === uuid)).every((s) => s);
            if (!same) {
              // Update function already handle the nice catch.
              update(request, { watchers: selected }).catch(console.log);
            }
          }}
        />
      </div>
      <style jsx>{`
        div {
          margin-left: -${theme.spacers.s3};
        }
      `}</style>
    </>
  );
};
