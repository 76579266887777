import React, { useState } from "react";

import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { useTheme } from "../../contexts";
import { Avatar } from "../Avatar/Avatar";
import { Text } from "../Text/Text";
import type { IconType } from "../../admin-icons";
import { RiArrowRightLine } from "../../admin-icons";

interface BaseProps {
  title: string;
  date: Date;
  profile: {
    avatar?: string | null;
    firstName: string;
    lastName: string;
  };
}

interface MessageProps extends BaseProps {
  message: string;
}

interface ValueProps extends BaseProps {
  value: string | React.ReactNode;
  previousValue?: string | React.ReactNode;
}

interface IconValueProps extends ValueProps {
  icon: IconType | string;
}

interface FileProps extends BaseProps {
  file: string[];
}

type Props = BaseProps | MessageProps | ValueProps | IconValueProps | FileProps;

type OmitValues = "title" | "date" | "profile";

const ActivityIconValue: React.FC<Omit<IconValueProps, OmitValues>> = ({ icon: Icon, value, previousValue }) => {
  return (
    <div className="d-block">
      {previousValue != null && (
        <>
          <Icon className="mr-2" size="16px" /> {previousValue}
          <RiArrowRightLine size="16px" className="ml-2" />
        </>
      )}
      <Icon className="mr-2" size="16px" /> {value}
    </div>
  );
};

const ActivityTextValue: React.FC<Omit<ValueProps, OmitValues>> = ({ value, previousValue }) => {
  const [showPrevious, setShowPrevious] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <div className="text">{value}</div>
      {showPrevious && <div className="text">{previousValue}</div>}
      {previousValue != null && (
        <Text
          variant="label"
          className="cursor-pointer"
          color={theme.colors.grayscale["50"]}
          onClick={() => setShowPrevious((x) => !x)}
        >
          {showPrevious ? t("common.hidePrevious") : t("common.viewPrevious")}
        </Text>
      )}
      <style jsx>{`
        .text {
          background: ${theme.colors.grayscale_5};
          border-radius: 4px;
          padding: 0.5rem;
          margin: 0 0 0.5rem;
        }
      `}</style>
    </>
  );
};

const ActivityCustomValue: React.FC<Omit<ValueProps, OmitValues>> = ({ value, previousValue }) => {
  return (
    <div className="d-block">
      {previousValue != null ? (
        <>
          {previousValue} <RiArrowRightLine size="16px" className="ml-2" /> {value}
        </>
      ) : (
        value
      )}
    </div>
  );
};

export const Activity: React.FC<Props> = ({ title, profile, date, ...props }) => {
  const theme = useTheme();
  const { i18n } = useTranslation();

  return (
    <>
      <div className="activity">
        <div className="avatar">
          <Avatar size="medium" imageUrl={profile.avatar} firstName={profile.firstName} lastName={profile.lastName} />
        </div>
        <div>
          <span className="name">
            {profile.firstName} {profile.lastName}
          </span>
          <span className="title">{` ${title}`}</span>
        </div>
        <div className="when">{formatters.relativelong(date, i18n.language)}</div>
        <div className="details pt-3">
          {"message" in props && <span data-eq-test="message">{props.message}</span>}
          {"value" in props && !("icon" in props) && typeof props.value === "string" && (
            <ActivityTextValue {...props} />
          )}
          {"value" in props && !("icon" in props) && typeof props.value !== "string" && (
            <ActivityCustomValue {...props} />
          )}
          {"value" in props && "icon" in props && <ActivityIconValue {...props} />}
        </div>
      </div>
      <style jsx>{`
        .activity {
          position: relative;
          padding: 0 1.5rem 2.5rem;
          border-left: 1px solid ${theme.colors.border};
        }
        :global(.order-reverse) .activity:first-child,
        :global(.order-regular) .activity:last-child {
          border-color: transparent;
        }
        .avatar {
          position: absolute;
          left: -16px;
          z-index: 1;
        }
        .name {
          font-weight: 500;
        }
        .when {
          font-size: 12px;
        }
        .when {
          color: ${theme.colors.grayscale["60"]};
        }
      `}</style>
    </>
  );
};
