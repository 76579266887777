import { stringNotEmpty } from "@equiem/lib";
import { Form } from "@equiem/react-admin-ui";
import { Field } from "formik";
import React, { useMemo } from "react";
import type { BookableResourceAddOn } from "../../../../generated/gateway-client";
import { BookableResourceAddOnType } from "../../../../generated/gateway-client";
import { useAddOnError } from "../../hooks/useAddOnError";
import { useTranslation } from "@equiem/localisation-eq1";

interface P {
  addOn: BookableResourceAddOn | null;
  index: number;
}
export const AddonFreeText: React.FC<P> = ({ addOn, index }) => {
  const { t } = useTranslation();
  const error = useAddOnError(index);
  const showTooltip = useMemo(() => {
    return stringNotEmpty(addOn?.tooltipTxt);
  }, [addOn?.tooltipTxt]);

  if (addOn == null || addOn.type !== BookableResourceAddOnType.FreeText) {
    return null;
  }

  return (
    <Form.Group
      label={addOn.name ?? t("common.unknown")}
      showTooltip={showTooltip}
      tooltipText={addOn.tooltipTxt ?? undefined}
      required={addOn.mandatory}
      error={error}
    >
      <Field name={`addOns.${index}.value`} as={Form.Textarea} rows={3} />
    </Form.Group>
  );
};
