/* eslint complexity: ["error", 99] */
import React from "react";
import { DateTime } from "luxon";

import { useSiteContext } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Activity, Tag, DateTime as DT } from "@equiem/react-admin-ui";
import { RiCalendarEventLine } from "@equiem/react-admin-ui/icons";
import type { ActivitiesQuery, Weekday } from "../../../generated/gateway-client";
import {
  availabilityDays,
  daysBeforeBookingToApply,
  discountCreated,
  discountDeleted,
  endDate,
  endTime,
  percentage,
  resources,
  startDate,
  startTime,
  title,
} from "../typeguards/activityRunTypes";
import { formatWeekday } from "../../../lib/formatWeekday";
import WeekdayTypeguard from "../../../lib/WeekdayTypeguard";

type Activity = NonNullable<ActivitiesQuery["activities"]["edges"][number]["node"]>;

export const BookingDiscountActivityDisplay: React.FC<{ activity: Activity }> = ({ activity }) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { timezone } = useSiteContext();
  const date = new Date(activity.timestamp);

  // if first name is missing (ie profile service down) set both lastName and avatar to null so that it displays “Someone”
  // and displays (S) where the avatar goes. <Activity /> handles missing avatar nicely :)
  const profile =
    activity.user?.firstName != null
      ? {
          firstName: activity.user.firstName,
          lastName: activity.user.lastName ?? "",
          avatar: activity.user.avatar,
        }
      : {
          firstName: t("common.someone"),
          lastName: "",
        };

  const formatDateTime = (timestamp: DateTime) => `${formatters.dateshort(timestamp, i18n.language)}`;

  const convertWeekdays = (days: string[]) => {
    return days
      .map((day) => {
        const formattedDay = formatWeekday(day as Weekday, i18n.language, "long").toLowerCase();
        if (WeekdayTypeguard.guard(formattedDay)) {
          return t(`bookings.common.daysOfWeek.${formattedDay}`);
        } else {
          console.error("Unknown day detected", formattedDay);
          return day;
        }
      })
      .join(", ");
  };

  if (title.guard(activity.change)) {
    return (
      <Activity
        profile={profile}
        title={t("bookings.discounts.activity.titleChanged")}
        date={date}
        previousValue={activity.change.from.value}
        value={activity.change.to.value}
      />
    );
  }

  if (daysBeforeBookingToApply.guard(activity.change)) {
    return (
      <Activity
        profile={profile}
        title={t("bookings.discounts.activity.numberOfDaysChanged")}
        date={date}
        previousValue={activity.change.from.integer}
        value={activity.change.to.integer}
      />
    );
  }

  if (percentage.guard(activity.change)) {
    return (
      <Activity
        profile={profile}
        title={t("bookings.discounts.activity.percentageChanged")}
        date={date}
        previousValue={`${activity.change.from.integer}%`}
        value={`${activity.change.to.integer}%`}
      />
    );
  }

  if (availabilityDays.guard(activity.change)) {
    return (
      <Activity
        profile={profile}
        title={t("bookings.discounts.activity.availabilityDaysChanged")}
        date={date}
        previousValue={convertWeekdays(activity.change.fromValues.map((v) => v.value))}
        value={convertWeekdays(activity.change.toValues.map((v) => v.value))}
      />
    );
  }

  if (startTime.guard(activity.change) || endTime.guard(activity.change)) {
    const timeChangeText =
      activity.change.field === "startTime"
        ? t("bookings.discounts.activity.startTimeChanged")
        : t("bookings.discounts.activity.endTimeChanged");

    return (
      <Activity
        profile={profile}
        title={timeChangeText}
        date={date}
        previousValue={
          <DT.TimeDisplay
            datetime={DateTime.fromFormat(activity.change.from.value, "h:mma").toMillis()}
            language={i18n.language}
          />
        }
        value={
          <DT.TimeDisplay
            datetime={DateTime.fromFormat(activity.change.to.value, "h:mma").toMillis()}
            language={i18n.language}
          />
        }
      />
    );
  }

  if (startDate.guard(activity.change) || endDate.guard(activity.change)) {
    const dateChangeText =
      activity.change.field === "startDate"
        ? t("bookings.discounts.activity.startDateChanged")
        : t("bookings.discounts.activity.endDateChanged");
    const fromDateTime = DateTime.fromMillis(activity.change.from.dateTime, { zone: timezone });
    const toDateTime = DateTime.fromMillis(activity.change.to.dateTime, { zone: timezone });

    return (
      <Activity
        profile={profile}
        title={dateChangeText}
        date={date}
        previousValue={
          <span data-eq-test="datetime-from">
            <RiCalendarEventLine />
            {` ${formatDateTime(fromDateTime)}`}
            <br />
          </span>
        }
        value={
          <span data-eq-test="datetime-to">
            <RiCalendarEventLine />
            {` ${formatDateTime(toDateTime)}`}
          </span>
        }
      />
    );
  }

  if (resources.guard(activity.change)) {
    return (
      <Activity
        profile={profile}
        title={t("bookings.discounts.activity.resourcesChanged")}
        date={date}
        previousValue={activity.change.fromValues.map((from, i) => (
          <Tag className="m-2" key={i}>
            {from.bookableResource.name}
          </Tag>
        ))}
        value={activity.change.toValues.map((from, i) => (
          <Tag className="m-2" key={i}>
            {from.bookableResource.name}
          </Tag>
        ))}
      />
    );
  }

  if (discountCreated.guard(activity.change) || discountDeleted.guard(activity.change)) {
    const changeText =
      activity.change.field === "bookingDiscountCreated"
        ? t("bookings.discounts.activity.discountCreated")
        : t("bookings.discounts.activity.discountDeleted");
    return <Activity profile={profile} title={changeText} date={date} />;
  }

  // unsupported activity: log info to browser console, uncomment `// activity,` to debug locally ;)
  console.info({
    message: "unsupported activity",
    field: activity.change?.field,
    type: activity.change?.type,
    __typename: activity.change?.__typename,
    activity,
  });

  return <></>;
};
