import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import Link from "next/link";
import { SubTopMenu } from "@equiem/lib";
import type { TabItem, FilterItem, FilterValue } from "@equiem/react-admin-ui";
import { ButtonLink, Tabs, ComplexFilter, useTheme, Form, useIsMobileWidth } from "@equiem/react-admin-ui";
import { RiSettings3Line, RiSearchLine } from "@equiem/react-admin-ui/icons";

import { useTranslation } from "@equiem/localisation-eq1";
import { BookingsAuthContext } from "../contexts/BookingsAuthContext";

interface TitleRowProps {
  title?: string | React.ReactNode;
  navigation?: React.ReactNode;
  search?: { searchText: string; setSearchText: (searchText: string) => unknown };
  filters?: {
    loading?: boolean;
    items: Record<string, FilterItem>;
    initialValues?: Record<string, FilterValue>;
    showFilterRow?: boolean;
    setValues: (values: Record<string, FilterValue>) => unknown;
  };
  button?: React.ReactNode;
  creationMode?: boolean;
}

interface TabProps extends TitleRowProps {
  children: React.ReactNode;
}

const BookingsTitleRow: React.FC<TitleRowProps> = ({ title, navigation, search, filters, button }) => {
  const [showFilterRow, setShowFilterRow] = useState(false);
  const { breakpoints, spacers } = useTheme(true);
  const isMobile = useIsMobileWidth();
  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (filters?.initialValues != null) {
      setShowFilterRow(Object.keys(filters.initialValues).some((key) => key in filters.items));
    }
  }, [filters]);

  const onFilterChange = useCallback(
    (values: Record<string, FilterValue>) => {
      if (filters?.setValues != null) {
        filters.setValues(values);
        setShowFilterRow(Object.keys(values).some((key) => key in filters.items));
      }
    },
    [filters],
  );

  if (
    filters?.loading === true ||
    (title == null && navigation == null && filters?.items == null && search == null && button == null)
  ) {
    return null;
  }

  return (
    <div className="title-row-container">
      <ComplexFilter
        loading={filters?.loading}
        filters={filters?.items ?? {}}
        initialValues={filters?.initialValues}
        language={i18n.language}
        autoShow
        onChange={onFilterChange}
      >
        {({ renderFilterButton, renderChips, renderClearButton }) => (
          <>
            <SubTopMenu className="controls-container mt-4" border={false}>
              <h2 className="title">{title}</h2>
              <span>{navigation}</span>
              <span className="controls">
                {search != null && (
                  <div className="search-container">
                    <Form.InputExtended
                      icon={RiSearchLine}
                      placeholder={`${t("common.search")}...`}
                      variant="sm"
                      value={search.searchText}
                      onChange={(e) => search.setSearchText(e.target.value)}
                      onClear={() => search.setSearchText("")}
                      clearable
                    />
                  </div>
                )}
                <span className="buttons my-2">
                  {filters != null && renderFilterButton("primary", "bottom-end")}
                  {button}
                </span>
              </span>
            </SubTopMenu>
            {!isMobile && showFilterRow && (
              <div className="filters mb-6 px-6">
                <div>{renderChips()}</div>
                <div>{renderClearButton()}</div>
              </div>
            )}
          </>
        )}
      </ComplexFilter>
      <style jsx>{`
        .title {
          font-weight: bold;
        }
        .controls,
        .buttons {
          display: flex;
          gap: ${spacers.s3};
          align-items: center;
        }
        .controls .search-container {
          width: 270px;
        }
        .filters {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        @media (max-width: ${breakpoints.lg}px) {
          .search-container {
            width: 100%;
          }
        }

        @media screen and (max-width: ${breakpoints.md}px) {
          .title-row-container :global(.controls-container) {
            align-items: flex-start !important;
            flex-wrap: wrap;
            gap: ${spacers.s4};
          }
          .controls {
            flex-basis: 100%;
          }
          .controls .search-container {
            flex-grow: 1;
          }
        }
      `}</style>
    </div>
  );
};

export const BookingsTab: React.FC<TabProps> = ({ title, navigation, search, filters, button, children }) => {
  const { colors } = useTheme(true);
  const { t } = useTranslation();
  const router = useRouter();
  const { canObserveBuildingCompany, canManageBuildingCompany, canManageSiteCompany, canManageSite } =
    useContext(BookingsAuthContext);
  const showSettings = canManageBuildingCompany || canManageSite || canManageSiteCompany;
  const canManage = canManageSiteCompany || canManageSite || canManageBuildingCompany;
  const canObserve = canManage || canObserveBuildingCompany;

  const tabs = useMemo<TabItem[]>(() => {
    return [
      { title: t("breadcrumbs.catalogue"), key: "/bookings" },
      ...(canManage ? [{ title: t("breadcrumbs.resources"), key: "/bookings/resources" }] : []),
      { title: t("breadcrumbs.myBookings"), key: "/bookings/my-bookings" },
      ...(canObserve ? [{ title: t("breadcrumbs.operations"), key: "/bookings/operations" }] : []),
      ...(canObserve ? [{ title: t("breadcrumbs.reporting"), key: "/bookings/reporting" }] : []),
    ];
  }, [t, canManage, canObserve]);

  const handleTabSelect = (key: string) => {
    void router.push(key);
  };

  return (
    <>
      <Head>
        <title>{t("bookings.operations.bookingsOperations")}</title>
      </Head>
      <div className="cont">
        <SubTopMenu btmPadding={false}>
          <h1 className="mb-3 font-weight-bold">{t("bookings.operations.bookings")}</h1>
          {showSettings && (
            <div className="actions d-flex">
              <Link href="/bookings/settings" passHref legacyBehavior>
                <ButtonLink variant="ghost" size="md">
                  <RiSettings3Line size={16} />
                  {t("common.settings")}
                </ButtonLink>
              </Link>
            </div>
          )}
        </SubTopMenu>
        <SubTopMenu btmPadding={false} topPadding={false} minHeight={false} alignItems="flex-end" sticky>
          <Tabs items={tabs} selected={router.pathname} tabLinks onSelect={handleTabSelect} />
        </SubTopMenu>
        <BookingsTitleRow title={title} navigation={navigation} search={search} filters={filters} button={button} />
        {children}
      </div>
      <style jsx>{`
        .cont {
          background: ${colors.white};
          min-height: 100%;
        }
      `}</style>
    </>
  );
};
