import { Array, Literal, Null, Number, Record, String } from "runtypes";

// const status = Record({ name: String, type: ReqMgtStatusType });
// no enum support in runtypes :( https://github.com/pelotom/runtypes/issues/66

// space.name;
// space.buildingLevel.name;
// space.buildingLevel.building.name;
const space = Record({
  name: String,
  buildingLevel: Record({
    name: String,
    building: Record({
      name: String,
    }),
  }),
});

const profile = Record({
  uuid: String,
  firstName: String,
  lastName: String,
  avatar: String.nullable(),
});

export const category = Record({
  field: Literal("category"),
  from: Record({ category: Record({ name: String }) }),
  to: Record({ category: Record({ name: String }) }),
});

export const subCategoryUpdated = Record({
  field: Literal("subcategory"),
  from: Record({ subCategory: Record({ name: String }) }),
  to: Record({ subCategory: Record({ name: String }) }),
});

export const subCategoryAdded = Record({
  field: Literal("subcategory"),
  from: Null,
  to: Record({ subCategory: Record({ name: String }) }),
});

export const subCategoryRemoved = Record({
  field: Literal("subcategory"),
  from: Record({ subCategory: Record({ name: String }) }),
  to: Null,
});

export const location = Record({
  field: Literal("location"),
  from: Record({ space }),
  to: Record({ space }),
});

export const assignee = Record({
  field: Literal("assignee"),
  from: Record({ profile }).Or(Null),
  to: Record({ profile }),
});

export const description = Record({
  field: Literal("description"),
  from: Record({ value: String }),
  to: Record({ value: String }),
});

export const reported = Record({
  field: Literal("reported"),
  from: Record({ dateTime: Number }),
  to: Record({ dateTime: Number }),
});

export const completed = Record({
  field: Literal("completed"),
  from: Record({ dateTime: Number }),
  to: Record({ dateTime: Number }),
});

export const firstReporter = Record({
  field: Literal("reporter"),
  from: Null,
  to: Record({ profile }),
});

export const reporterUpdated = Record({
  field: Literal("reporter"),
  from: Record({ profile }),
  to: Record({ profile }),
});

export const attachmentsUpdated = Record({
  field: Literal("attachments"),
  from: Record({ attachments: Record({ key: String }) }),
  to: Record({ attachments: Record({ key: String }) }),
});

export const attachmentsRemoved = Record({
  field: Literal("deletedAttachments"),
  from: Record({ attachments: Record({ key: String }) }),
  to: Record({ attachments: Record({ key: String }) }),
});

export const watchers = Record({
  field: Literal("watchers"),
  fromValues: Array(Record({ profile })),
  toValues: Array(Record({ profile })),
});
