import React, { useState } from "react";
import { Modal, Form, Button, useToast } from "@equiem/react-admin-ui";
import { useTranslation, useServerMessageTranslation, useErrorTranslation } from "@equiem/localisation-eq1";

import {
  useCancelResourceBookingMutation,
  BookableResourceAvailabilityCalendarDocument,
} from "../../../generated/gateway-client";
import { useCancelBookingCharges } from "../hooks/useBookResource";
import { CurrencyAmount } from "../../../lib/CurrencyAmount";

interface Props {
  /** minimal set of required fields, for reuse */
  booking: {
    uuid: string;
    reference: string;
    resource: {
      building?: { uuid: string } | null;
    };
  };
  showModal: boolean;
  onHide: () => void;
}

export const OperationsCancelBookingModal: React.FC<Props> = ({ booking, showModal, onHide }) => {
  const { t } = useTranslation();
  const { tServer } = useServerMessageTranslation();
  const { tError } = useErrorTranslation();
  const toast = useToast();
  const charges = useCancelBookingCharges({ bookingReference: booking.reference, skip: !showModal });
  const [mutation, { loading: mutationLoading }] = useCancelResourceBookingMutation();
  const [acceptPrice, setAcceptPrice] = useState(false);

  const cancellationPrice = charges.data?.bookingCharges.cancellationPrice?.total ?? 0;
  const submitDisabled = charges.loading || (cancellationPrice > 0 && !acceptPrice) || mutationLoading;

  const cancelBooking = async () => {
    try {
      const result = await mutation({
        variables: { uuid: booking.uuid, acceptTerms: true },
        refetchQueries: [BookableResourceAvailabilityCalendarDocument],
      });
      if (result.data?.cancelResourceBooking.__typename === "BookingSuccessResponse") {
        toast.neutral(t("bookings.operations.bookingHasBeenCancelled", { bookingReference: booking.reference }));
      } else if (result.data?.cancelResourceBooking.__typename === "BookingFailureResponse") {
        toast.negative(tServer(result.data.cancelResourceBooking.localisedReason));
      } else {
        console.error(result);
        throw new Error("Failed to cancel booking");
      }
    } catch (e: unknown) {
      console.error(e);
      toast.negative(tError(e));
    } finally {
      onHide();
    }
  };

  return (
    <>
      <Modal.Dialog
        title={t("bookings.operations.cancelBooking")}
        show={showModal}
        onHide={onHide}
        hideOnEsc={true}
        centered={true}
      >
        <Modal.Header closeButton={false} noBorder={true} />
        <Modal.Body>
          <div className="my-4">
            {t("bookings.operations.areYouSureCancel", { bookingReference: booking.reference })}
          </div>
          {charges.loading ? (
            <div className="d-block my-4 line shimmer" />
          ) : (
            cancellationPrice > 0 && (
              <div className="mt-6 controls">
                <Form.Checkbox
                  className="form-checkbox"
                  label={
                    <>
                      {t("bookings.operations.iHaveInformed")}{" "}
                      <CurrencyAmount
                        amount={cancellationPrice}
                        buildingUuid={booking.resource.building?.uuid ?? null}
                      />
                    </>
                  }
                  id={"acceptPrice"}
                  value={acceptPrice}
                  onChange={(e) => setAcceptPrice(e.target.checked)}
                />
              </div>
            )
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="footer">
            <Button className="mr-2" size="md" variant="ghost" onClick={onHide}>
              {t("bookings.operations.noTakeMeBack")}
            </Button>
            <Button
              size="md"
              variant="danger"
              disabled={submitDisabled}
              onClick={() => {
                void cancelBooking();
              }}
            >
              {t("bookings.operations.yesCancelBooking")}
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Dialog>
      <style jsx>{`
        .line {
          display: inline-block;
          height: 1.5em;
          width: 50%;
        }
        .controls :global(.form-checkbox) {
          align-items: unset;
        }
      `}</style>
    </>
  );
};
