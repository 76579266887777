import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import type { ApolloError } from "@apollo/client";
import type { FormikProps } from "formik";
import { Formik } from "formik";
import { isEqual } from "lodash";

import { CurrentProfile, useShowError, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Modal, useConfirmer, useToast } from "@equiem/react-admin-ui";

import type { CategoryPresetsQuery } from "../generated/requests-client";
import { useCategoryData } from "../pages/settings/category/hooks/useCategoryData";
import { ModalContext } from "../pages/settings/contexts/ModalContext";

import type { CategoryFormValues } from "./utils/categoryFormTypes";
import { getValidationSchema } from "./utils/categoryFormTypes";
import type { CategoryTabKey } from "./utils/categoryTabs";
import { CategoryCreateForm } from "./CategoryCreateForm";
import { CategoryDetails } from "./CategoryDetails";
import { CategoryEditForm } from "./CategoryEditForm";

type CategoryPreset = CategoryPresetsQuery["reqMgt"]["categoryPresets"][number];

export const CategoryModal: React.FC = () => {
  const { t } = useTranslation();
  const { uuid: siteUuid } = useSiteContext();
  const { canManageRegion } = useContext(CurrentProfile);
  const [selectedTab, setSelectedTab] = useState<CategoryTabKey>("general");
  const {
    setSelectedCategoryUuid,
    createCategoryMutation,
    updateCategoryMutation,
    seedCategoryMutation,
    categoriesMap,
    presets,
  } = useCategoryData();
  const toast = useToast();
  const showError = useShowError();
  const formInnerRef = useRef<FormikProps<CategoryFormValues>>(null);
  const { withConfirmation } = useConfirmer();
  const [showModal, setShowModal] = useState(false);
  const modal = useContext(ModalContext);

  const isUpdateModal = modal.id != null;
  const [isEditing, setIsEditing] = useState(!isUpdateModal);
  const category = useMemo(() => (modal.id != null ? categoriesMap.get(modal.id) : undefined), [modal, categoriesMap]);

  const [selectedPreset, setSelectedPreset] = useState<CategoryPreset>();
  const showTypeField = !isUpdateModal && selectedPreset == null;

  const handleNameChange = useCallback(
    (newName: string) => {
      const preset = presets.find(({ categoryName }) => categoryName.toLowerCase() === newName.trim().toLowerCase());
      setSelectedPreset(preset);
    },
    [presets],
  );

  const onClose = useCallback(() => {
    setSelectedPreset(undefined);
    setShowModal(false);
    setIsEditing(!isUpdateModal);
    modal.close();
  }, [setShowModal, setSelectedPreset, modal, isUpdateModal]);

  const onCloseModal = useCallback(
    (extraCondition = false) => {
      !isEqual(formInnerRef.current?.values, formInnerRef.current?.initialValues) ||
      (typeof extraCondition === "boolean" && extraCondition)
        ? withConfirmation({
            title: t("common.areYouSure"),
            message: t("common.cancelMessage"),
            confirmButtonText: t("common.yesCancel"),
            cancelButtonText: t("home.widgets.cancelNo"),
            confirmButtonVariant: "danger",
            onConfirm: onClose,
          })()
        : onClose();
    },
    [withConfirmation, t, onClose, formInnerRef],
  );

  const content = useMemo(() => {
    if (isUpdateModal) {
      if (isEditing) {
        return (
          <CategoryEditForm
            showTypeField={showTypeField}
            selectedPreset={selectedPreset}
            onNameChange={handleNameChange}
            onCloseModal={onCloseModal}
            setSelectedTab={(tab: string) => {
              setSelectedTab(tab as CategoryTabKey);
            }}
            selectedTab={selectedTab}
          />
        );
      }
      return (
        <CategoryDetails
          setSelectedTab={(tab: string) => {
            setSelectedTab(tab as CategoryTabKey);
          }}
          selectedTab={selectedTab}
          onClickEdit={() => {
            setIsEditing(true);
          }}
        />
      );
    }
    return (
      <CategoryCreateForm
        showTypeField={showTypeField}
        selectedPreset={selectedPreset}
        onNameChange={handleNameChange}
        onCloseModal={onCloseModal}
      />
    );
  }, [
    selectedPreset,
    isEditing,
    isUpdateModal,
    showTypeField,
    onCloseModal,
    setSelectedTab,
    selectedTab,
    handleNameChange,
  ]);

  useEffect(() => {
    setIsEditing(!isUpdateModal);
    if (modal.activeModal === "CategoryModal") {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [modal.activeModal, isUpdateModal]);

  return (
    <>
      <Modal.Dialog
        title={
          isUpdateModal
            ? isEditing
              ? t("requests.category.edit")
              : t("requests.category.details")
            : t("requests.category.newCategory")
        }
        show={showModal}
        sideModal={true}
        onHide={onCloseModal}
        hideOnEsc={true}
        hideOnClick={true}
        size="md"
      >
        <Modal.Header closeButton noBorder={false} onClose={onCloseModal} supportsMobile />
        <Formik
          enableReinitialize
          initialValues={
            modal.id == null
              ? { name: "", iconName: "", type: "", buildings: [], ownerCompanyUuid: "", queueUuid: "" }
              : {
                  name: category?.name ?? "",
                  iconName: category?.iconName ?? "",
                  type: category?.type ?? "",
                  ownerCompanyUuid: category?.ownerCompany.uuid ?? "",
                  queueUuid: category?.queue?.uuid ?? "",
                  buildings: category?.buildings.map((building) => building?.uuid ?? "") ?? [],
                }
          }
          innerRef={formInnerRef}
          validationSchema={getValidationSchema(t, showTypeField, canManageRegion)}
          onSubmit={async (
            { name, iconName, type, buildings, ownerCompanyUuid, queueUuid },
            { setFieldError },
          ): Promise<string | undefined> => {
            try {
              let categoryUuid = "";
              if (isUpdateModal) {
                if (category?.uuid == null) {
                  toast.negative(t("common.changesSaveFailed"));
                  console.error("Category UUID missing");
                  return;
                }

                await updateCategoryMutation({
                  variables: {
                    uuid: category.uuid,
                    name,
                    iconName,
                    siteUuid,
                    buildingUuids: buildings,
                    presetUuid: name === category.name ? category.presetUuid : null,
                  },
                });
                categoryUuid = category.uuid;
                toast.neutral(t("common.changesSaved"));
              } else if (selectedPreset != null) {
                const variables: {
                  uuid: string;
                  iconName: string;
                  siteUuid: string;
                  buildingUuids: string[];
                  ownerCompanyUuid?: string;
                  queueUuid: string;
                } = {
                  uuid: selectedPreset.uuid,
                  iconName,
                  siteUuid,
                  buildingUuids: buildings,
                  queueUuid,
                };
                if (ownerCompanyUuid != null && ownerCompanyUuid !== "") {
                  variables.ownerCompanyUuid = ownerCompanyUuid;
                }
                const { data } = await seedCategoryMutation({
                  variables,
                });
                if (data?.reqMgt.seedCategory.uuid != null) {
                  setSelectedCategoryUuid(data.reqMgt.seedCategory.uuid);
                  categoryUuid = data.reqMgt.seedCategory.uuid;
                }
                toast.positive(t("requests.category.newCategoryAdded"));
              } else {
                const variables: {
                  name: string;
                  type: string;
                  iconName: string;
                  siteUuid: string;
                  buildingUuids: string[];
                  ownerCompanyUuid?: string;
                  queueUuid: string;
                } = {
                  name,
                  type,
                  iconName,
                  siteUuid,
                  buildingUuids: buildings,
                  queueUuid,
                };
                if (ownerCompanyUuid != null && ownerCompanyUuid !== "") {
                  variables.ownerCompanyUuid = ownerCompanyUuid;
                }
                const { data } = await createCategoryMutation({
                  variables,
                });
                if (data?.reqMgt.createCategory.uuid != null) {
                  setSelectedCategoryUuid(data.reqMgt.createCategory.uuid);
                  categoryUuid = data.reqMgt.createCategory.uuid;
                }
                toast.positive(t("requests.category.newCategoryAdded"));
              }
              onClose();
              // eslint-disable-next-line consistent-return
              return categoryUuid;
            } catch (e: unknown) {
              if (
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                (e as ApolloError)?.graphQLErrors?.some((gqlError) => gqlError?.extensions?.code === "ALREADY_EXISTS")
              ) {
                console.error(e);
                setFieldError("name", t("requests.category.alreadyExists"));
                return;
              }

              showError(e);
            }
          }}
        >
          {content}
        </Formik>
      </Modal.Dialog>
    </>
  );
};
