import type { PropsWithChildren } from "react";
import React, { createContext, useContext } from "react";
import { type BookableResourceFragmentFragment, type BookingFragmentFragment } from "../../../generated/gateway-client";
import { Site } from "@equiem/lib";
import { getCreditCurrency } from "../libs/getCreditCurrency";

type Resource = BookableResourceFragmentFragment;
type Booking = BookingFragmentFragment;

export interface BookingModalContentContext {
  resource: Resource;
  booking?: Booking;
  start?: number;
  end?: number;
  timezone: string;
  buildingUuid?: string | undefined | null;
  currency?: string;
}

export const BookingModalInfo = createContext<BookingModalContentContext>({
  resource: {} as unknown as Resource,
  timezone: "Australia/Melbourne",
});

interface P extends PropsWithChildren {
  resource?: Resource;
  booking?: Booking;
  start?: number;
  end?: number;
}
export const BookingModalInfoProvider: React.FC<P> = ({ resource, booking, start, end, children }) => {
  const site = useContext(Site);

  if (booking?.resource == null && resource == null) {
    throw new Error("Missing a resource.");
  }

  const inputResource = booking?.resource ?? resource!;

  return (
    <BookingModalInfo.Provider
      value={{
        resource: inputResource,
        booking,
        start,
        end,
        timezone: booking?.timezone ?? resource!.building?.timezone ?? site.timezone,
        buildingUuid: inputResource.building?.uuid ?? null,
        currency: getCreditCurrency(inputResource.paymentMethods),
      }}
    >
      {children}
    </BookingModalInfo.Provider>
  );
};
