import React, { useCallback, useEffect, useState } from "react";

import { useRequestEnabled, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Alert, Form, Text, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiErrorWarningLine } from "@equiem/react-admin-ui/icons";

import { useWidgetContext } from "../../../contexts/WidgetContext";
import { type CompanyDetailsQuery, useUpdateCompanyPermissionsMutation } from "../../../generated/settings-client";

interface P {
  company: NonNullable<CompanyDetailsQuery["companyV2"]>;
  className?: string;
  modalClose: () => void;
}
export const RequestManagementPermission: React.FC<P> = ({ company, modalClose, className = "" }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const showError = useShowError();
  const { subscribe, setActionState } = useWidgetContext();
  const [everyoneCanOpenRequest, setEveryoneCanOpenRequest] = useState(
    company.reqMgtRequestPermissions.everyoneCanOpenRequest,
  );
  const [mutation, { loading }] = useUpdateCompanyPermissionsMutation();
  const toast = useToast();
  const isRequestManagementEnabled = useRequestEnabled();

  const doIt = useCallback(async () => {
    if (loading) {
      return;
    }

    setActionState("onSave", "loading");

    if (company.reqMgtRequestPermissions.everyoneCanOpenRequest !== everyoneCanOpenRequest) {
      await mutation({
        variables: {
          companyUuid: company.uuid,
          input: { everyoneCanOpenRequest },
        },
      });
    }

    toast.positive(t("settings.createCompany.companySuccessfullyUpdated", { companyName: company.name }));
    setActionState("onSave", "normal");
    modalClose();
  }, [
    company.name,
    company.reqMgtRequestPermissions.everyoneCanOpenRequest,
    company.uuid,
    everyoneCanOpenRequest,
    loading,
    modalClose,
    mutation,
    t,
    toast,
    setActionState,
  ]);

  useEffect(() => {
    return subscribe("onSave", () => {
      doIt().catch(showError);
    });
  }, [doIt, subscribe, showError]);

  return (
    <div className={className}>
      <h4 className="font-weight-bold">{t("settings.editCompany.requestManagement")}</h4>
      {!isRequestManagementEnabled ? (
        <Alert
          size="large"
          variant="gray"
          icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
          message={t("settings.moduleNotEnabled")}
          className="my-3"
        />
      ) : (
        <>
          <div className="pt-4 pb-3">
            <Text variant="label" color={colors.muted0}>
              {t("settings.editCompany.requestManagementIntro")}
            </Text>
          </div>
          <Form.RadioButton
            name="whoCanOpenRequests"
            disabled={loading}
            defaultValue="all"
            label={t("settings.editCompany.requestManagementAllCan")}
            className="pb-3"
            defaultChecked={everyoneCanOpenRequest}
            onChange={() => setEveryoneCanOpenRequest(true)}
          />
          <Form.RadioButton
            name="whoCanOpenRequests"
            disabled={loading}
            defaultValue="selected"
            label={t("settings.editCompany.requestManagementSelectedUser")}
            extraInfo={t("settings.editCompany.requestManagementSelectedUserAdditionalInfo")}
            defaultChecked={!everyoneCanOpenRequest}
            onChange={() => setEveryoneCanOpenRequest(false)}
          />
        </>
      )}
    </div>
  );
};
