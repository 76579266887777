import type { ProfileContext } from "@equiem/lib";

import type { BookingsSite } from "../pages/resources/hooks/useAuthorizedDestinationDetails";
import { type FormValues, AvailabilityType } from "./formValidation";

// these fields will be reset to their initial values if the site is changed during resource creation
export const siteSpecificFields = [
  "typeV2",
  "features",
  "roomConfigurations",
  "paymentMethods",
  "siteAudiences",
  "children",
  "manualApprovalCompanies",
  "allowVisitorInvites",
  "barrierControlAccessUuid",
  "barrierControlVisitorAccessUuid",
] as const satisfies ReadonlyArray<keyof FormValues>;

export const resourceCreateFormInitialValues = (
  siteUuid: string,
  siteName: string,
  profile: ProfileContext,
  viewerLocations: BookingsSite[],
): FormValues => {
  const site = viewerLocations.find((s) => s.uuid === siteUuid);
  const building = site?.buildings[0];
  const level = building?.buildingLevels[0];

  return {
    addOns: [],
    autoApproveBookings: true,
    allowVisitorInvites: false,
    allowRecurringBooking: false,
    availability: [],
    availabilityType: AvailabilityType.slots,
    bookingWindowMinInMinutes: 0,
    bookingWindowMaxInMinutes: 20160, // 14 days (60 * 24 * 14)
    building: building?.uuid ?? "",
    buildingName: building?.name ?? "",
    buildingAddress: building?.address ?? "",
    businessHoursEnd: "17:00",
    businessHoursHalfDayDuration: "04:00",
    businessHoursStart: "09:00",
    children: [],
    description: "",
    displayCapacity: "",
    editBookingNoticePeriodInMinutes: 0,
    images: [],
    features: [],
    level: level?.uuid,
    levelName: level?.name,
    parentNames: [],
    paymentRateCancellation: [],
    paymentRateFullDay: 0,
    paymentRateHalfDay: 0,
    paymentRateHourly: 0,
    paymentRateHourlyAfterHours: 0,
    paymentRateHourlyWeekend: 0,
    prepTimeAfterInMinutes: 0,
    prepTimeBeforeInMinutes: 0,
    roomConfigurations: [],
    site: siteUuid,
    siteName,
    siteAudiences: [],
    steps: 0,
    taxExempt: false,
    title: "",
    typeV2: "",
    // This will default to the user's own company on the server, but make sure
    // we show this fact in the setting to regional managers.
    ownerCompanyUuid: profile.profile?.companyV2?.uuid ?? "",
    ownerCompanyName: profile.profile?.companyV2?.name ?? "",
  };
};
