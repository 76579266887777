import { stringIsEmpty, useQueryState, useSiteContext, useDisabledSiteSwitcher } from "@equiem/lib";
import { useApolloErrorTranslation, useTranslation } from "@equiem/localisation-eq1";
import type { FilterItem, RadioIconOption } from "@equiem/react-admin-ui";
import { RadioIcons, EmptyState, Text, useTheme } from "@equiem/react-admin-ui";
import { RiCalendar2Line, RiEyeLine, RiListCheck } from "@equiem/react-admin-ui/icons";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useMemo } from "react";
import { CatalogueCalendar } from "./components/calendar-view/CatalogueCalendar";
import { CatalogueCalendarNavigation } from "./components/calendar-view/CatalogueCalendarNavigation";
import { CatalogueCard, CatalogueCardLoading } from "./components/CatalogueCard";
import { useResourceFilters } from "./hooks/useResourceFilters";
import { BookingCreateNewModal } from "../operations/components/BookingCreateNewModal";
import { BookingsTab } from "../../components/BookingsTab";
import { withContexts } from "../../contexts/withContexts";
import { BookableResourceStatus } from "../../generated/gateway-client";
import { useRouter } from "next/router";
import { BookingModal } from "../operations/contexts/BookingModalContext";
import { CatalogueMetrics } from "./components/CatalogueMetrics";
import { CatalogueView as View } from "./models/CatalogueView";
import { usePagedResources } from "../../hooks/usePagedResources";
import { ScrollBottomObserverWrapper } from "../../components/ScrollBottomObserverWrapper";

const isView = (v: unknown): v is View => Object.values(View).includes(v as View);

const noop = () => undefined;

const EmptyCatalogue = () => {
  const { t } = useTranslation();
  const { colors, spacers } = useTheme();

  return (
    <>
      <div className="empty-catalogue-icon">
        <RiEyeLine size="24" color={colors.transparent.black[40]} />
      </div>
      <Text variant="text" size="small" color={colors.transparent.black[40]} className="my-0 text-center">
        {t("bookings.resources.catalogueCurrentlyEmpty")}
      </Text>
      <style jsx>{`
        .empty-catalogue-icon {
          display: flex;
          padding: ${spacers.s5};
          justify-content: center;
          align-items: center;
          border-radius: 40px;
          background: ${colors.transparent.black[5]};
        }
      `}</style>
    </>
  );
};

// eslint-disable-next-line complexity
const BrowseCatalogueLocal = () => {
  const { t } = useTranslation();
  const { tError } = useApolloErrorTranslation();
  const { timezone } = useSiteContext();
  const { breakpoints, spacers } = useTheme();

  useDisabledSiteSwitcher();

  const viewOptions: Array<RadioIconOption<View>> = [
    {
      icon: RiListCheck,
      value: View.LIST,
      className: View.LIST,
      tooltipText: t("bookings.operations.list"),
    },
    {
      icon: RiCalendar2Line,
      value: View.CALENDAR,
      className: View.CALENDAR,
      tooltipText: t("bookings.operations.calendar"),
    },
  ];

  const [{ view }, setQueryState] = useQueryState({
    initial: { view: View.LIST },
    parse: {
      view: (v) => (isView(v) ? v : View.LIST),
    },
    clearQueryOnChange: true,
    rememberLastState: true,
  });

  const {
    loading: filtersLoading,
    filterOptions,
    filterValues,
    filters: { managedBy, ...filters },
    onFilterChange,
  } = useResourceFilters({ canManageBookings: true, isSegmentedToViewer: true });

  // exclude date from filters for calendar view
  const items =
    view === View.CALENDAR
      ? Object.keys(filterOptions).reduce<Record<string, FilterItem>>((acc, key) => {
          if (key !== "date") {
            acc[key] = filterOptions[key];
          }
          return acc;
        }, {})
      : filterOptions;

  const complexFilters = useMemo(
    () => ({
      loading: filtersLoading,
      items,
      initialValues: filterValues,
      setValues: onFilterChange,
    }),
    [filterValues, filtersLoading, items, onFilterChange],
  );

  const { resources, searchText, setSearchText, loading, loadingOriginal, hasMoreData, error, called, fetchMore } =
    usePagedResources({
      filters: { ...filters, status: BookableResourceStatus.Published },
      permissionFilters: { canManageBookings: true, isSegmentedToViewer: true },
    });

  const isFiltered = !stringIsEmpty(searchText) || managedBy != null || Object.values(filters).some((f) => f != null);

  const resourcesToShow = useMemo(() => {
    if (filtersLoading) {
      return [];
    }

    const managedByMeFilterSelected = managedBy === "me";
    if (!managedByMeFilterSelected) {
      return resources;
    }

    return resources.filter((resource) => resource.viewerPermissions?.canManageBookings ?? false);
  }, [resources, filtersLoading, managedBy]);

  // Open/close resource side modal based on query param.
  const router = useRouter();
  const modal = useContext(BookingModal);
  useEffect(() => {
    if (typeof router.query.resource === "string") {
      modal.open(router.query.resource);
    } else if (modal.id != null && view !== View.CALENDAR) {
      modal.close();
    }
  }, [router.query.resource, modal, view]);

  const anythingLoading = filtersLoading || loading;
  const isEmpty = !anythingLoading && !hasMoreData && resources.length === 0;

  return (
    <>
      <BookingsTab
        title={
          <RadioIcons
            options={viewOptions}
            value={view}
            onChange={(newView: View) => setQueryState({ view: newView })}
          />
        }
        navigation={
          view === View.CALENDAR && (
            <CatalogueCalendarNavigation
              startDate={filters.date ?? DateTime.local({ zone: timezone }).startOf("day").toMillis()}
            />
          )
        }
        search={{ searchText, setSearchText }}
        filters={complexFilters}
      >
        <CatalogueMetrics view={view} readyToSend={called && !loadingOriginal}>
          <ScrollBottomObserverWrapper onBottomVisible={error == null && view === View.LIST ? fetchMore : noop}>
            {error != null && <div className="ml-6">{tError(error)}</div>}
            {error == null && isEmpty && (
              <div className="empty-listing">{isFiltered ? <EmptyState /> : <EmptyCatalogue />}</div>
            )}
            {error == null && !isEmpty && (
              <>
                {view === View.LIST && (
                  <div className="listing">
                    {resourcesToShow.map((resource) => (
                      <CatalogueCard key={resource.uuid} resource={resource} />
                    ))}
                    {anythingLoading &&
                      Array.from({ length: 6 }).map((_, index) => <CatalogueCardLoading key={index} />)}
                  </div>
                )}
                {view === View.CALENDAR && (
                  <CatalogueCalendar
                    timezone={timezone}
                    resources={resourcesToShow}
                    loading={anythingLoading}
                    loadingOriginal={loadingOriginal}
                    startDate={filters.date ?? DateTime.local({ zone: timezone }).startOf("day").toMillis()}
                    fetchMore={fetchMore}
                  />
                )}
              </>
            )}
          </ScrollBottomObserverWrapper>
        </CatalogueMetrics>
      </BookingsTab>
      <BookingCreateNewModal />
      <style jsx>{`
        .listing {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(307px, 1fr));
          justify-items: center;
          grid-gap: ${spacers.s7} ${spacers.s5};
          padding: ${spacers.s4} ${spacers.s7} ${spacers.s7};
        }
        .empty-listing {
          display: flex;
          flex-direction: column;
          gap: ${spacers.s5};
          padding: ${spacers.s7};
          width: 100%;
          align-items: center;
          align-self: center;
        }
        @media screen and (max-width: ${breakpoints.md}px) {
          .listing {
            grid-template-columns: 1fr;
          }
        }
        @media screen and (max-width: ${breakpoints.sm}px) {
          .listing {
            padding: ${spacers.s5};
          }
        }
      `}</style>
    </>
  );
};

export const BrowseCatalogue = withContexts(BrowseCatalogueLocal);
