/* eslint-disable @typescript-eslint/no-magic-numbers */
import React, { useCallback, useMemo } from "react";
import {
  Modal,
  Form as EqForm,
  Button,
  useToast,
  useTheme,
  ProgressCircle,
  remainingCharacters,
} from "@equiem/react-admin-ui";
import { useTranslation, useErrorTranslation } from "@equiem/localisation-eq1";
import getSymbolFromCurrency from "currency-symbol-map";

import type {
  BookingFragmentFragment,
  ExistingBookingChargesQuery,
  RefundBookingItem,
} from "../../../generated/gateway-client";
import { RefundBookingItemType, useRefundBookingItemsMutation } from "../../../generated/gateway-client";
import { Formik, Form, Field } from "formik";
import type { FlatBookingAddOns } from "../libs/groupExistingBookingAddOns";
import { formatCurrency } from "../../../lib/formatCurrency";
import { BookingChargeAdjustmentAndRefund } from "./BookingChargeAdjustmentAndRefund";
import type { PartialRefundFormValue } from "../models/PartialRefundFormValue";
import { partialRefundFormValidation } from "../libs/partialRefundFormValidation";

interface P {
  booking: BookingFragmentFragment;
  charges: NonNullable<NonNullable<ExistingBookingChargesQuery>["siteBookingByReference"]>;
  addOns: FlatBookingAddOns[];
  showModal: boolean;
  onHide: () => void;
}
export const BookingAdjustmentRefundModal: React.FC<P> = ({ booking, showModal, onHide, charges, addOns }) => {
  const { t, i18n } = useTranslation();
  const { tError } = useErrorTranslation();
  const toast = useToast();
  const [mutation, { loading }] = useRefundBookingItemsMutation();
  const theme = useTheme();
  const reasonLength = 50;

  const currencyCode = charges.currencyCode ?? "AUD";
  const symbolCurrency = currencyCode === "CREDITS" ? null : getSymbolFromCurrency(currencyCode);

  const validate = useCallback((values: PartialRefundFormValue) => partialRefundFormValidation(t, values), [t]);

  const submit = async (values: PartialRefundFormValue) => {
    try {
      const refunds: RefundBookingItem[] = [];

      const bookingInput = Number(values.booking);
      if (!isNaN(bookingInput) && bookingInput > 0) {
        refunds.push({
          typeUuid: booking.uuid,
          type: RefundBookingItemType.Booking,
          amount: Math.round(parseFloat(bookingInput.toFixed(2)) * 100),
        });
      }

      const addOnsKeys = Object.keys(values.addOns);
      addOnsKeys.forEach((uuid) => {
        const addOnsInput = Number(values.addOns[uuid]);
        if (!isNaN(addOnsInput) && addOnsInput > 0) {
          refunds.push({
            typeUuid: uuid,
            type: RefundBookingItemType.AddOn,
            amount: Math.round(parseFloat(addOnsInput.toFixed(2)) * 100),
          });
        }
      });

      const adjustmentsKeys = Object.keys(values.adjustments);
      adjustmentsKeys.forEach((uuid) => {
        const adjustmentInput = Number(values.adjustments[uuid]);
        if (!isNaN(adjustmentInput) && adjustmentInput > 0) {
          refunds.push({
            typeUuid: uuid,
            type: RefundBookingItemType.Adjustment,
            amount: Math.round(parseFloat(adjustmentInput.toFixed(2)) * 100),
          });
        }
      });

      if (refunds.length === 0) {
        toast.negative(t("bookings.operations.pleaseSpecifyAmount"));
        return;
      }

      await mutation({
        variables: {
          input: {
            booking: booking.uuid,
            reason: values.reason,
            refunds,
          },
        },
      });

      onHide();
    } catch (e: unknown) {
      console.error(e);
      toast.negative(tError(e));
    }
  };

  const paidAddOns = useMemo(() => addOns.filter((a) => a.amount > 0), [addOns]);
  const paidAdjustments = useMemo(() => charges.adjustments.filter((a) => a.amount > 0), [charges.adjustments]);

  return (
    <Modal.Dialog
      title={t("bookings.operations.refundsAndAdditionalCharges")}
      show={showModal}
      onHide={onHide}
      hideOnEsc={true}
      centered
      scrollable
      size="md"
    >
      <Modal.Header closeButton={true} noBorder={false} intro={t("bookings.operations.addRefund")} />
      <Formik<PartialRefundFormValue>
        initialValues={{ booking: "", reason: "", addOns: {}, adjustments: {} }}
        validate={validate}
        onSubmit={submit}
      >
        {({ submitForm, errors, values }) => (
          <>
            <Modal.Body>
              <Form autoComplete="off">
                <EqForm.Group label={t("bookings.operations.refundAmount")} required>
                  {charges.resourcePrice > 0 && (
                    <>
                      <div className="info">
                        <div>{t("bookings.operations.bookingReference", { reference: booking.reference })}</div>
                        <div className="amount">
                          <div className="pr-3">
                            {formatCurrency(
                              charges.discounts.length > 0 ? charges.resourcePriceWithDiscount : charges.resourcePrice,
                              currencyCode,
                              i18n.language,
                            )}
                          </div>
                          <EqForm.Group error={errors.booking} className="mb-0">
                            <Field
                              as={EqForm.Money}
                              currencyPrefix="-"
                              currency={symbolCurrency}
                              id="booking"
                              name="booking"
                              min={0}
                              max={9999}
                              type="number"
                              placeholder={0}
                            />
                          </EqForm.Group>
                        </div>
                      </div>
                      {charges.partialRefunds.map((r) => (
                        <BookingChargeAdjustmentAndRefund
                          key={r.uuid}
                          name={
                            t("bookings.operations.refundLineItem", {
                              item: t("bookings.operations.bookingReference", {
                                reference: booking.reference,
                              }) as string,
                              user: r.user.profile?.displayName ?? t("common.unknown"),
                            }) as string
                          }
                          amount={formatCurrency(r.amount, currencyCode, i18n.language)}
                          description={r.reason}
                          className="mb-3"
                        />
                      ))}
                    </>
                  )}

                  {paidAddOns.map((ao) => (
                    <React.Fragment key={ao.uuid}>
                      <div className="info" key={ao.uuid}>
                        <div>
                          {ao.description} {ao.quantity > 1 ? `(x${ao.quantity})` : null}
                        </div>
                        <div className="amount">
                          <div className="pr-3">{formatCurrency(ao.amount, currencyCode, i18n.language)}</div>
                          <EqForm.Group error={errors.addOns?.[ao.uuid]} className="mb-0">
                            <Field
                              as={EqForm.Money}
                              currencyPrefix="-"
                              currency={symbolCurrency}
                              id={`addOns.${ao.uuid}`}
                              name={`addOns.${ao.uuid}`}
                              min={0}
                              max={9999}
                              type="number"
                              placeholder={0}
                            />
                          </EqForm.Group>
                        </div>
                      </div>
                      {ao.partialRefunds.map((r) => (
                        <BookingChargeAdjustmentAndRefund
                          key={r.uuid}
                          name={
                            t("bookings.operations.refundLineItem", {
                              item: ao.description,
                              user: r.user,
                            }) as string
                          }
                          amount={formatCurrency(r.amount, currencyCode, i18n.language)}
                          description={r.reason}
                          className="mb-3"
                        />
                      ))}
                    </React.Fragment>
                  ))}

                  {paidAdjustments.map((adj) => (
                    <React.Fragment key={adj.uuid}>
                      <div className="info">
                        <div>
                          {t("bookings.operations.adjustmentLineItem", {
                            user: adj.user.profile?.displayName ?? t("common.unknown"),
                          })}
                          <div>{adj.reason}</div>
                        </div>
                        <div className="amount">
                          <div className="pr-3">{formatCurrency(adj.amount, currencyCode, i18n.language)}</div>
                          <EqForm.Group error={errors.adjustments?.[adj.uuid]} className="mb-0">
                            <Field
                              as={EqForm.Money}
                              currencyPrefix="-"
                              currency={symbolCurrency}
                              id={`adjustments.${adj.uuid}`}
                              name={`adjustments.${adj.uuid}`}
                              min={0}
                              max={9999}
                              type="number"
                              placeholder={0}
                            />
                          </EqForm.Group>
                        </div>
                      </div>
                      {adj.partialRefunds.map((r) => (
                        <BookingChargeAdjustmentAndRefund
                          key={r.uuid}
                          name={
                            t("bookings.operations.refundLineItem", {
                              item: t("bookings.operations.additionalCharge"),
                              user: r.user.profile?.displayName ?? t("common.unknown"),
                            }) as string
                          }
                          amount={formatCurrency(r.amount, currencyCode, i18n.language)}
                          description={r.reason}
                          className="mb-3"
                        />
                      ))}
                    </React.Fragment>
                  ))}
                </EqForm.Group>
                <EqForm.Group
                  required
                  error={errors.reason}
                  className="reason"
                  hint={t("common.remainingCharacters", {
                    count: remainingCharacters(reasonLength, values.reason.length),
                  })}
                  label={t("bookings.operations.reasonForRefund")}
                >
                  <Field
                    as={EqForm.Input}
                    id="reason"
                    name="reason"
                    maxLength={reasonLength}
                    placeholder={t("bookings.operations.reasonsOfTheAdjustment")}
                  />
                </EqForm.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              {!loading && (
                <Button className="mr-2" size="md" variant="ghost" onClick={onHide}>
                  {t("common.cancel")}
                </Button>
              )}
              <Button
                size="md"
                variant="primary"
                disabled={loading}
                onClick={() => {
                  submitForm().catch(console.log);
                }}
              >
                {loading && <ProgressCircle size="xs" className="mr-2" />} {t("bookings.operations.addRefund")}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
      <style jsx>{`
        .info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0 0 ${theme.spacers.s3};
        }
        .amount {
          display: flex;
          align-items: center;
          white-space: nowrap;
        }
        .info :global(input) {
          width: 60px;
          flex: 0;
        }
      `}</style>
    </Modal.Dialog>
  );
};
