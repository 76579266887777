import React from "react";
import { DateTime } from "@equiem/react-admin-ui";

import { BookingApprovalStatus } from "../../../generated/gateway-client";
import type { BookingFragmentFragment } from "../../../generated/gateway-client";
import { ValuePills } from "../../../components/ValuePills";
import { BookingActivityLogChangeRow } from "./BookingActivityLogChangeRow";
import { useTranslation } from "@equiem/localisation-eq1";

type Change = BookingFragmentFragment["updateHistory"][number];

type User = Change["updatedBy"] | Change["user"];

interface Props {
  change: Change;
  timezone: string;
}

interface TimeChangeProps {
  startDate: number;
  endDate: number;
  timezone: string;
}

interface ApprovalStatusChangeProps {
  value: BookingApprovalStatus;
  reason?: string | null;
}

const TimeChange: React.FC<TimeChangeProps> = ({ startDate, endDate, timezone }) => {
  const { i18n } = useTranslation();
  return (
    <ValuePills>
      <DateTime.DateDisplay language={i18n.language} datetime={startDate} timezone={timezone} />{" "}
      <DateTime.TimeRange language={i18n.language} start={startDate} end={endDate} timezone={timezone} />
    </ValuePills>
  );
};

const ApprovalStatusChange: React.FC<ApprovalStatusChangeProps> = ({ value, reason }) => {
  const { t } = useTranslation();

  let status: string;
  switch (value) {
    case BookingApprovalStatus.Approved:
      status = t("bookings.operations.status.approved");
      break;
    case BookingApprovalStatus.Declined:
      status = t("bookings.operations.status.declined");
      break;
    case BookingApprovalStatus.Pending:
      status = t("bookings.operations.status.pendingApproval");
      break;
    default:
      // will never happen unless we add new enum values
      status = (value as string).toLowerCase();
      break;
  }

  return (
    <>
      {status} {reason != null ? `(${reason})` : null}
    </>
  );
};

const getName = (user: User) => `${user?.profile?.firstName} ${user?.profile?.lastName}`;

export const BookingActivityLogChange: React.FC<Props> = ({ change, timezone }) => {
  const common = { changeInitiator: getName(change.updatedBy), timestamp: change.updatedAt, timezone };

  return (
    <>
      {change.approvalStatus != null && (
        <BookingActivityLogChangeRow
          {...common}
          changeType="approvalStatus"
          value={<ApprovalStatusChange value={change.approvalStatus} reason={change.cancelledReason} />}
        />
      )}
      {change.time != null && (
        <BookingActivityLogChangeRow
          {...common}
          changeType="time"
          value={<TimeChange startDate={change.time.startDate} endDate={change.time.endDate} timezone={timezone} />}
        />
      )}
      {change.roomConfiguration != null && (
        <BookingActivityLogChangeRow {...common} changeType="roomConfiguration" value={change.roomConfiguration.name} />
      )}
      {change.user != null && (
        <BookingActivityLogChangeRow {...common} changeType="user" value={getName(change.user)} />
      )}
      {change.cancelledReason != null && change.approvalStatus == null && (
        <BookingActivityLogChangeRow {...common} changeType="cancelledReason" value={change.cancelledReason} />
      )}
      {change.adjustmentReason != null && (
        <BookingActivityLogChangeRow {...common} changeType="adjustment" value={change.adjustmentReason} />
      )}
      {change.partialRefundReason != null && (
        <BookingActivityLogChangeRow {...common} changeType="partialRefund" value={change.partialRefundReason} />
      )}
      {change.note != null && <BookingActivityLogChangeRow {...common} changeType="note" value={change.note} />}
      {change.adminNote != null && (
        <BookingActivityLogChangeRow {...common} changeType="adminNote" value={change.adminNote} />
      )}
    </>
  );
};
