import type { CompanyDestinationInput, CreateOrUpdateCompanyMutation } from "../generated/gateway-client";
import { useCreateOrUpdateCompanyMutation } from "../generated/gateway-client";
import _ from "lodash";

export interface AccessGroupsDefaultValues {
  accessGroupsUuids: Record<string, boolean>;
  defaultAccessGroupUuid: string | undefined;
}

export interface CompanyFormValues {
  companyDestination?: CompanyDestinationInput;
  destinations?: string[];
  industry?: string;
  name: string;
  uuid?: string;
  validationValues?: string[];
  attributes?: string[];
  levels?: string[];
  autoApproveRegistrations?: boolean;
  addsCardsAutomatically?: boolean;
  defaultAccessGroupUuid?: string;
  accessGroupsUuids: Record<string, boolean>;
}

export function useCompanyFormSubmit(companyUuid?: string, activeDestination?: string, validationMessage?: string) {
  const [mutation, { loading }] = useCreateOrUpdateCompanyMutation();

  const onSubmit = async (
    data: CompanyFormValues,
    initialValues: CompanyFormValues,
  ): Promise<CreateOrUpdateCompanyMutation | undefined | null> => {
    const containsAtSymbol =
      data.validationValues?.filter((v) => {
        return v.indexOf("@") !== -1;
      }) ?? [];

    if (containsAtSymbol.length > 0) {
      return null;
    }

    let destinations: string[] | undefined = undefined;
    if (companyUuid == null && activeDestination != null) {
      destinations = [activeDestination];
    } else if (companyUuid != null && data.destinations != null) {
      destinations = data.destinations;
    }
    const formAccessGroups = Object.entries(data.accessGroupsUuids)
      .map(([key, value]) => {
        return value ? key : undefined;
      })
      .filter(Boolean) as string[];
    const accessGroupChanged =
      data.defaultAccessGroupUuid !== initialValues.defaultAccessGroupUuid ||
      !_.isEqual(data.accessGroupsUuids, initialValues.accessGroupsUuids);

    const result = await mutation({
      variables: {
        input: {
          uuid: companyUuid,
          name: data.name,
          industry: data.industry,
          destinations,
          validation: {
            message: validationMessage,
            values:
              data.validationValues?.map((validation) => ({
                value: validation,
                rule: "EXACT_EMAIL_DOMAIN",
              })) ?? [],
          },
          companyDestination:
            activeDestination != null
              ? {
                  destinationUuid: activeDestination,
                  buildingLevels: data.levels,
                  companyAttributes: data.attributes,
                  autoApproveRegistrations: data.autoApproveRegistrations ?? false,
                }
              : undefined,
          ...(accessGroupChanged && {
            accessGroupsUuids: formAccessGroups,
            defaultAccessGroupUuid: data.defaultAccessGroupUuid,
          }),
          addsCardsAutomatically: data.addsCardsAutomatically,
        },
      },
    });

    return result.data;
  };

  return {
    loading,
    onSubmit,
  };
}
