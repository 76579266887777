import { Literal, Union } from "runtypes";

const WeekdayTypeguard = Union(
  Literal("sunday"),
  Literal("monday"),
  Literal("tuesday"),
  Literal("wednesday"),
  Literal("thursday"),
  Literal("friday"),
  Literal("saturday"),
);

export default WeekdayTypeguard;
