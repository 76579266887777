import React, { useContext, useState } from "react";

import { Form, useTheme } from "@equiem/react-admin-ui";

import type { RequestQuery } from "../../../generated/requests-client";
import { RequestDetailsContext } from "../contexts/RequestDetailsContext";

interface P {
  request: RequestQuery["reqMgt"]["request"];
}
export const RequestDescriptionEdit: React.FC<P> = ({ request }) => {
  const theme = useTheme();
  const { loading, update } = useContext(RequestDetailsContext);
  const [localValue, setLocalValue] = useState<string>(request.description);

  return (
    <>
      <div>
        <Form.Textarea
          value={localValue}
          className="p-3"
          onBlur={() => {
            if (localValue !== request.description) {
              // Update function already handle the nice catch.
              update(request, { description: localValue }).catch(console.log);
            }
          }}
          onChange={(e) => {
            setLocalValue(e.target.value);
          }}
          showChrome="onInteraction"
          disabled={loading}
        />
      </div>
      <style jsx>{`
        div {
          margin: 0 -${theme.spacers.s3};
        }
      `}</style>
    </>
  );
};

export const RequestDescription: React.FC<P> = ({ request }) => {
  return (
    <>
      <div>
        {request.viewerRelations.canManage ? (
          <RequestDescriptionEdit request={request} />
        ) : (
          <div className="pt-4">{request.description}</div>
        )}
      </div>
    </>
  );
};
