import type { ReactNode } from "react";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";

import { CurrentProfile, stringNotEmpty, UrlParams } from "@equiem/lib";
import type { FilterValue } from "@equiem/react-admin-ui";
import { FilterTextModifier, FilterType, useDebounced } from "@equiem/react-admin-ui";

import type { RequestFilterEntitiesQuery } from "../../../generated/requests-client";
import {
  type ReqMgtRequestsDateFilter,
  ReqMgtRequestsFilterScope,
  ReqMgtStatusType,
  useRequestFilterEntitiesQuery,
} from "../../../generated/requests-client";
import {
  translateAssignee,
  translateBuilding,
  translateCategory,
  translateCreated,
  translateReporter,
  translateReporterCompany,
  translateScope,
  translateSearchTerm,
  translateStatus,
  translateUnassigned,
  translateWatching,
} from "../utils/translate";

import type { ActiveTab } from "./ActiveTab";
import { RequestsAccessContext } from "./RequestsAccessContext";
import { RequestsScopeContext } from "./RequestsScopeContext";

export type RequestFilterType =
  | "status"
  | "category"
  | "company"
  | "reporter"
  | "assignee"
  | "building"
  | "created"
  | "search"
  | "watchedByUuid";
export type RequestFiltersResult = Partial<Record<RequestFilterType, FilterValue>>;

interface FilterParams {
  searchTerm?: string | undefined;
  statusUuids?: string[] | undefined;
  reporterUuids?: string[] | undefined;
  watchedByUuid?: string | undefined;
  assigneeUuids?: string[] | undefined;
  buildingUuids?: string[] | undefined;
  categoryUuids?: string[] | undefined;
  created?: ReqMgtRequestsDateFilter | undefined;
  reporterCompanyUuids?: string[] | undefined;
  scope?: ReqMgtRequestsFilterScope | undefined;
}

interface RequestsFilterContext {
  filters: RequestFiltersResult;
  allowedBuildings: string[];
  debouncedFilters: RequestFiltersResult;
  search?: string;
  tabs: {
    open: boolean;
    completed: boolean;
    all: boolean;
  };
  defaultCountFilters: {
    open: FilterParams;
    completed: FilterParams;
    all: FilterParams;
  };
  activeTab: ActiveTab;
  setActiveTab: React.Dispatch<React.SetStateAction<ActiveTab>>;
  clearSearch: () => void;
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setFilters: React.Dispatch<React.SetStateAction<Partial<Record<RequestFilterType, FilterValue>>>>;
  translatedFilters: FilterParams | undefined;
  entities?: RequestFilterEntitiesQuery["reqMgt"]["requestFilterEntities"];
}

export const RequestsFilterContext = createContext<RequestsFilterContext>({
  filters: {},
  allowedBuildings: [],
  debouncedFilters: {},
  translatedFilters: {},
  entities: undefined,
  tabs: {
    open: false,
    completed: false,
    all: false,
  },
  defaultCountFilters: {
    open: {},
    completed: {},
    all: {},
  },
  activeTab: "all",
  setActiveTab: () => {
    throw new Error("Not init");
  },
  search: undefined,
  clearSearch: () => {
    throw new Error("Not init");
  },
  handleSearch: () => {
    throw new Error("Not init");
  },
  setFilters: () => {
    throw new Error("Not init");
  },
});

export const RequestsFilterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [filters, setFilters] = useState<RequestFiltersResult>({});
  const { setParam, deleteParam } = useContext(UrlParams);
  const { currentScope } = useContext(RequestsScopeContext);
  const access = useContext(RequestsAccessContext);
  const router = useRouter();
  const { query } = useRouter();
  const [activeTab, setActiveTab] = useState<ActiveTab>(() => {
    if (query.tab != null) {
      return query.tab as ActiveTab;
    }

    return "open";
  });
  const searchFromQueryParams = router.query.search as string | undefined;
  const [search, setSearch] = useState<string | undefined>(searchFromQueryParams ?? "");
  const debouncedSearch = useDebounced(search, 500);
  const debouncedFilters = useDebounced(filters, 300);
  const { profile } = useContext(CurrentProfile);

  const { data: entitiesData } = useRequestFilterEntitiesQuery();
  const statuses = useMemo(() => entitiesData?.reqMgt.requestFilterEntities.statuses ?? [], [entitiesData]);

  // react on tab reset
  useEffect(() => {
    if (router.query.tab === undefined) {
      setActiveTab("open");
    }
  }, [router.query.tab]);

  // react on tab selection change
  useEffect(() => {
    if (router.query.tab !== activeTab) {
      setParam("tab", activeTab);
    }
  }, [activeTab]);

  useEffect(() => {
    if (stringNotEmpty(debouncedSearch)) {
      if (debouncedSearch !== router.query.search) {
        setParam("search", debouncedSearch);
      }
    } else if (router.query.search != null) {
      deleteParam("search");
    }
  }, [debouncedSearch]);

  const tabs = {
    open: true,
    all: true,
    completed: true,
  };

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    [setSearch],
  );

  const clearSearch = useCallback(() => {
    setSearch("");
  }, [setSearch]);

  const allowedBuildings = useMemo(() => access.buildings.map((b) => b.uuid), [access.buildings]);

  const translatedFilters = useMemo(() => {
    const filter = {
      assigneeUuids: translateAssignee(currentScope, filters, profile?.uuid),
      buildingUuids: translateBuilding(activeTab, currentScope, filters, allowedBuildings),
      categoryUuids: translateCategory(filters),
      created: translateCreated(filters),
      reporterCompanyUuids: translateReporterCompany(
        activeTab,
        currentScope,
        filters,
        access.workplaceManager ? profile?.companyV2?.uuid : undefined,
      ),
      reporterUuids: translateReporter(currentScope, filters, profile?.uuid),
      searchTerm: translateSearchTerm({
        search: {
          modifier: FilterTextModifier.includes,
          type: FilterType.text,
          value: debouncedSearch,
        },
      }),
      statusUuids: translateStatus(filters, activeTab, statuses),
      unassigned: translateUnassigned(activeTab),
      watchedByUuid: translateWatching(filters, profile?.uuid),
      scope: translateScope(currentScope),
    };

    const nonEmpty = Object.values(filter).some((v) => v != null);

    return nonEmpty ? filter : undefined;
  }, [
    access.workplaceManager,
    activeTab,
    allowedBuildings,
    debouncedSearch,
    filters,
    statuses,
    profile?.companyV2?.uuid,
    profile?.uuid,
    currentScope,
  ]);

  const defaultCountFilters = useMemo(() => {
    const companyFilter =
      currentScope === "all" ? { reporterCompanyUuids: translatedFilters?.reporterCompanyUuids } : undefined;
    const openStatuses = statuses.filter((x) => x.type !== ReqMgtStatusType.Completed).map((x) => x.uuid);
    const completeStatuses = statuses.filter((x) => x.type === ReqMgtStatusType.Completed).map((x) => x.uuid);
    const myProfile = profile?.uuid != null ? [profile.uuid] : undefined;

    return {
      open: {
        ...companyFilter,
        buildingUuids: currentScope === "all" ? allowedBuildings : undefined,
        reporterUuids: currentScope === "my" ? myProfile : undefined,
        statusUuids: openStatuses,
        assigneeUuids: currentScope === "assigned" ? myProfile : undefined,
        scope: currentScope === "my" ? ReqMgtRequestsFilterScope.My : undefined,
      } satisfies FilterParams,
      completed: {
        ...companyFilter,
        buildingUuids: currentScope === "all" ? allowedBuildings : undefined,
        reporterUuids: currentScope === "my" ? myProfile : undefined,
        assigneeUuids: currentScope === "assigned" ? myProfile : undefined,
        statusUuids: completeStatuses,
        scope: currentScope === "my" ? ReqMgtRequestsFilterScope.My : undefined,
      } satisfies FilterParams,
      all: {
        ...companyFilter,
        reporterUuids: currentScope === "my" ? myProfile : undefined,
        assigneeUuids: currentScope === "assigned" ? myProfile : undefined,
        buildingUuids: currentScope === "all" ? allowedBuildings : undefined,
        scope: currentScope === "my" ? ReqMgtRequestsFilterScope.My : undefined,
      } satisfies FilterParams,
    };
  }, [allowedBuildings, currentScope, profile?.uuid, translatedFilters?.reporterCompanyUuids, statuses]);

  return (
    <RequestsFilterContext.Provider
      value={{
        activeTab,
        defaultCountFilters,
        allowedBuildings,
        clearSearch,
        debouncedFilters,
        filters,
        handleSearch,
        search,
        setFilters,
        setActiveTab,
        tabs,
        translatedFilters,
        entities: entitiesData?.reqMgt.requestFilterEntities,
      }}
    >
      {children}
    </RequestsFilterContext.Provider>
  );
};
