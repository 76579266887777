import React, { useContext, useEffect, useState } from "react";

import { useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Dropdown, Form, Skeleton, Text, useDebounced, useTheme } from "@equiem/react-admin-ui";

import { CategoryDisplay } from "../../../components/CategoryDisplay";
import {
  type RequestQuery,
  useCategoriesDropdownQuery,
  useSubCategoriesQuery,
} from "../../../generated/requests-client";
import { RequestDetailsContext } from "../contexts/RequestDetailsContext";
import { useAutoWidth } from "../hooks/useAutoWidth";

interface P {
  request: RequestQuery["reqMgt"]["request"];
  className?: string;
}

const RequestCategoryEdit: React.FC<P> = ({ request, className }) => {
  const { ref, width } = useAutoWidth();
  const theme = useTheme();
  const { t } = useTranslation();
  const showError = useShowError();
  const {
    data: catData,
    loading: catLoading,
    error: catError,
  } = useCategoriesDropdownQuery({ variables: { buildingUuid: request.space?.buildingLevel.building.uuid } });
  const { loading, update } = useContext(RequestDetailsContext);

  const requestSubcategory = request.subCategory?.uuid;
  const [categoryUuid, setCategoryUuid] = useState(request.category.uuid);
  const [subCategoryUuid, setSubCategoryUuid] = useState<string | undefined | null>(requestSubcategory);

  const {
    data: subData,
    loading: subLoading,
    error: subError,
    refetch: subRefect,
  } = useSubCategoriesQuery({
    variables: { categoryUuid: request.category.uuid },
  });

  const debouncedCategory = useDebounced(categoryUuid, 500);
  useEffect(() => {
    subRefect({ categoryUuid: debouncedCategory }).catch(showError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCategory]);

  const categories = catData?.reqMgt.categories ?? [];
  const subcategories = subData?.reqMgt.subCategories ?? [];

  if (catError != null) {
    showError(catError);
    return null;
  }
  if (subError != null) {
    showError(subError);
    return null;
  }

  return (
    <div ref={ref} className={`cat ${className}`}>
      <Dropdown.Container
        mobileView="minimal"
        disabled={loading}
        placement="bottom-start"
        width={width}
        trigger={
          <Form.SelectFacade disabled={loading}>
            <CategoryDisplay
              name={request.category.name}
              subcategory={request.subCategory?.name}
              icon={request.category.iconName}
              disabled={loading}
            />
          </Form.SelectFacade>
        }
        onClose={() => {
          const category = categoryUuid === request.category.uuid ? undefined : categoryUuid;
          const subCategory =
            subCategoryUuid === undefined || requestSubcategory === subCategoryUuid
              ? undefined
              : subCategoryUuid === ""
              ? null
              : subCategoryUuid;
          if (category === undefined && subCategory === undefined) {
            return;
          }

          update(request, { category, subCategory }).catch(console.log);
        }}
      >
        <div className="inside">
          <div>
            <div className="pb-3">
              <Text variant="label" color={theme.colors.grayscale["50"]}>
                {t("requests.details.category")}
              </Text>
            </div>
            {catLoading ? (
              <Skeleton.Line width="100%" height="40px" borderRadius={theme.borderRadius} />
            ) : (
              <Form.DynamicSelect
                className="category"
                disabled={loading}
                value={categoryUuid}
                mobileView="minimal"
                name="category"
                options={categories.map((cat) => ({
                  value: cat.uuid,
                  label: cat.name,
                }))}
                onChange={(e) => {
                  setCategoryUuid(e.target.value ?? "");
                  setSubCategoryUuid(null);
                }}
              />
            )}
          </div>

          <div>
            <div className="pb-3">
              <Text variant="label" color={theme.colors.grayscale["50"]} className="pb-2">
                {t("requests.details.subcategory")}
              </Text>
            </div>
            {subLoading ? (
              <Skeleton.Line width="100%" height="40px" borderRadius={theme.borderRadius} />
            ) : (
              <>
                {subcategories.length === 0 ? (
                  <div>{t("requests.details.noSubcategory")}</div>
                ) : (
                  <Form.DynamicSelect
                    className="subcategory"
                    disabled={loading}
                    value={subCategoryUuid ?? ""}
                    mobileView="minimal"
                    name="category"
                    options={[
                      { value: "", label: `${t("common.select")} ${t("requests.details.subcategory")}` },
                    ].concat(
                      subcategories.map((sub) => ({
                        value: sub.uuid,
                        label: sub.name,
                      })),
                    )}
                    onChange={(e) => {
                      setSubCategoryUuid(e.target.value);
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </Dropdown.Container>
      <style jsx>{`
        .cat {
          margin-left: -${theme.spacers.s3};
        }
        .inside {
          display: flex;
          flex-direction: column;
          gap: ${theme.spacers.s4};
          padding: ${theme.spacers.s3};
        }
      `}</style>
    </div>
  );
};

export const RequestCategory: React.FC<P> = ({ request, className }) => {
  if (request.viewerRelations.canManage) {
    return <RequestCategoryEdit request={request} className={className} />;
  }

  return (
    <div className={`py-3 ${className}`}>
      <CategoryDisplay
        name={request.category.name}
        subcategory={request.viewerRelations.internalParticipant ? request.subCategory?.name : undefined}
        icon={request.category.iconName}
      />
    </div>
  );
};
