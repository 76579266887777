import { useCompanyDetailsQuery } from "../../../generated/settings-client";

export function useCompanyDetails({ companyUuid, siteUuid }: { companyUuid?: string; siteUuid: string }) {
  const { loading: companyLoading, data: companyData } = useCompanyDetailsQuery({
    fetchPolicy: "network-only",
    variables: { uuid: companyUuid ?? "", siteUuid },
    skip: companyUuid == null,
  });

  return {
    loading: companyLoading,
    company: companyData?.companyV2,
  };
}
