import React from "react";
import { ErrorMessage, Field, FieldArray, Form, getIn, useFormikContext } from "formik";
import { get } from "lodash";

import { stringIsEmpty, UserSearchHOC, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form as EqForm, MemberCard } from "@equiem/react-admin-ui";

interface QueueUserProfile {
  uuid: string;
  firstName?: string | null | undefined;
  lastName?: string | null;
  email: string;
  companyUuid?: string | undefined;
  companyName?: string | undefined;
  profileIconUrl?: string | null | undefined;
}
export interface QueueFormValues {
  uuid?: string;
  name: string;
  ownerCompanyUuid: string;
  requestManagers: QueueUserProfile[];
  requestAssignees: QueueUserProfile[];
}

interface Props {
  loading: boolean;
  companies: Array<{ uuid: string; name: string }>;
  canCreateQueues: boolean;
}

export const QueueForm: React.FC<Props> = ({ loading, companies, canCreateQueues }) => {
  const { uuid: siteUuid } = useSiteContext();
  const { t } = useTranslation();
  const { values, touched, errors, setFieldValue, dirty, isSubmitting } = useFormikContext<QueueFormValues>();

  const getFieldError = (field: string): string | undefined =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    get(touched, field.split(".")[0]) === true || Array.isArray(get(touched, field)) || (field === "iconName" && dirty)
      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        get(errors, field)?.toString()
      : undefined;

  const isUsersArrayFieldInvalid = (type: "requestManagers" | "requestAssignees") => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return getIn(errors, `${type}[0]`)?.companyUuid as string | undefined;
  };

  const userIsInTheList = (user: { uuid: string }, currentUsersList: Array<{ uuid: string }>) => {
    return currentUsersList.some((u) => u.uuid === user.uuid);
  };

  return (
    <>
      <Form>
        <div className="queue-header">
          <span className="queue-header">{t("requests.queues.queueInformation")}</span>
        </div>
        <EqForm.Group label={t("common.name")} required error={getFieldError("name")}>
          <Field
            as={EqForm.Input}
            id="name"
            name="name"
            disabled={!canCreateQueues}
            placeholder={t("requests.queues.addName")}
          />
        </EqForm.Group>

        {canCreateQueues && (
          <EqForm.Group label={t("requests.queues.owner")} required error={getFieldError("ownerCompanyUuid")}>
            <Field
              name="ownerCompanyUuid"
              as={EqForm.Select}
              placeholder={t("requests.queues.selectCompany")}
              disabled={loading || companies.length === 0}
            >
              <option value="" disabled>
                {loading
                  ? `${t("common.loading")}...`
                  : companies.length === 0
                  ? t("requests.queues.noCompaniesFound")
                  : t("requests.queues.selectCompany")}
              </option>
              {companies.map(({ uuid, name }) => (
                <option key={uuid} value={uuid}>
                  {name}
                </option>
              ))}
            </Field>
          </EqForm.Group>
        )}
        <div className="queue-header">
          <span>{t("requests.queues.queueAgents")}</span>
        </div>
        {!stringIsEmpty(values.ownerCompanyUuid) && (
          <>
            <EqForm.Group required={true} label={t("requests.queues.requestManagers")}>
              <UserSearchHOC
                siteUuids={[siteUuid]}
                disabled={isSubmitting}
                placeholder={`${t("common.searchUsers")}...`}
                buttonText={(user) =>
                  userIsInTheList(user, values.requestManagers) ? t("common.remove") : t("common.add")
                }
                onSelect={(user) => {
                  userIsInTheList(user, values.requestManagers)
                    ? setFieldValue(
                        "requestManagers",
                        values.requestManagers.filter((x) => x.uuid !== user.uuid),
                      ).catch(console.error)
                    : setFieldValue("requestManagers", [
                        ...values.requestManagers,
                        {
                          uuid: user.uuid,
                          firstName: user.firstName,
                          lastName: user.lastName,
                          email: user.email,
                          companyUuid: user.companyV2?.uuid,
                          companyName: user.companyV2?.name,
                          profileIconUrl: user.avatar,
                        },
                      ]).catch(console.error);
                }}
              />
              {values.requestManagers.length > 0 && (
                <FieldArray
                  name="requestManagers"
                  render={() => (
                    <>
                      {values.requestManagers.map((reqMgr) => (
                        <EqForm.Group key={`rq-m${reqMgr.uuid}`} className="user-form-group">
                          <MemberCard.Card
                            className={"selected-member mt-2"}
                            showButton={true}
                            buttonVariant="outline"
                            buttonText={t("common.remove")}
                            onButtonClick={() => {
                              setFieldValue(
                                "requestManagers",
                                values.requestManagers.filter((x) => x.uuid !== reqMgr.uuid),
                              ).catch(console.error);
                            }}
                            email={reqMgr.email}
                            companyName={reqMgr.companyName ?? ""}
                            lastName={reqMgr.lastName ?? ""}
                            firstName={reqMgr.firstName ?? ""}
                            profileIconUrl={reqMgr.profileIconUrl ?? undefined}
                          />
                        </EqForm.Group>
                      ))}
                    </>
                  )}
                />
              )}
              <ErrorMessage
                render={() => {
                  const err = isUsersArrayFieldInvalid("requestManagers");
                  return err != null ? <p className="error-text">{err}</p> : <></>;
                }}
                name={""}
              />
            </EqForm.Group>
            <EqForm.Group required={true} label={t("requests.queues.requestAssignees")}>
              <UserSearchHOC
                siteUuids={[siteUuid]}
                disabled={isSubmitting || values.requestManagers.length === 0}
                placeholder={`${t("common.searchUsers")}...`}
                buttonText={(user) =>
                  userIsInTheList(user, values.requestAssignees) ? t("common.remove") : t("common.add")
                }
                onSelect={(user) => {
                  userIsInTheList(user, values.requestAssignees)
                    ? setFieldValue(
                        "requestAssignees",
                        values.requestAssignees.filter((x) => x.uuid !== user.uuid),
                      ).catch(console.error)
                    : setFieldValue("requestAssignees", [
                        ...values.requestAssignees,
                        {
                          uuid: user.uuid,
                          firstName: user.firstName,
                          lastName: user.lastName,
                          email: user.email,
                          companyUuid: user.companyV2?.uuid,
                          companyName: user.companyV2?.name,
                          profileIconUrl: user.avatar,
                        },
                      ]).catch(console.error);
                }}
              />
              {values.requestAssignees.length > 0 && (
                <FieldArray
                  name="requestAssignees"
                  render={() => (
                    <>
                      {values.requestAssignees.map((reqMgr) => (
                        <EqForm.Group key={`rq-a${reqMgr.uuid}`} className="user-form-group">
                          <MemberCard.Card
                            className={"selected-member mt-2"}
                            showButton={true}
                            buttonVariant="outline"
                            buttonText={t("common.remove")}
                            onButtonClick={() => {
                              setFieldValue(
                                "requestAssignees",
                                values.requestAssignees.filter((x) => x.uuid !== reqMgr.uuid),
                              ).catch(console.error);
                            }}
                            email={reqMgr.email}
                            companyName={reqMgr.companyName ?? ""}
                            lastName={reqMgr.lastName ?? ""}
                            firstName={reqMgr.firstName ?? ""}
                            profileIconUrl={reqMgr.profileIconUrl ?? undefined}
                          />
                        </EqForm.Group>
                      ))}
                    </>
                  )}
                />
              )}
              <ErrorMessage
                render={() => {
                  const err = isUsersArrayFieldInvalid("requestAssignees");
                  return err != null ? <p className="error-text">{err}</p> : <></>;
                }}
                name={""}
              />
            </EqForm.Group>
          </>
        )}
      </Form>
      <style jsx>{`
        :global(.error-user-card) {
          border-color: #e6000e !important;
        }
        :global(.user-form-group) {
          margin: 0px !important;
        }
        :global(.error-text) {
          color: #e6000e !important;
          font-size: 13px;
        }
        :global(.queue-header) {
          font-weight: 700;
          font-size: 20px;
          padding-bottom: 20px;
        }
      `}</style>
    </>
  );
};
