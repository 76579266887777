/* eslint-disable complexity */
import React, { useEffect, useMemo, useState } from "react";
import { saveAs } from "file-saver";
import { parse } from "json2csv";
import { Duration } from "luxon";

import { type WidgetProps, Role } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import type { MetricUnit } from "@equiem/react-admin-ui";
import {
  ButtonLink,
  Dropdown,
  IconButton,
  SingleMetric,
  Text,
  Tooltip,
  useTheme,
  useWidgetScroll,
  Widget as WidgetUI,
} from "@equiem/react-admin-ui";
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiFileChartLine,
  RiInformationLine,
  RiMoreFill,
  RiQuestionnaireLine,
} from "@equiem/react-admin-ui/icons";

import { StatusTypeIcon } from "../components/StatusTypeIcon";
import {
  ReqMgtStatusType,
  useRequestsStatsQuery,
  useRequestStatsFilterEntitiesQuery,
} from "../generated/requests-client";

type Props = Pick<WidgetProps, "setLoading" | "currentRole" | "viewer">;

export const RequestsStatsWidget: React.FC<Props> = ({ setLoading, currentRole, viewer }) => {
  const { colors } = useTheme();
  const { t, i18n } = useTranslation();
  const { gridRef, scrollWidget, isLeftScrollPossible, isRightScrollPossible } = useWidgetScroll();

  const [lastDays, setLastDays] = useState(7);
  const [buildingUuid, setSelectedBuilding] = useState<string>();
  const [companyUuid, setSelectedCompany] = useState<string>();
  const { data: filterData } = useRequestStatsFilterEntitiesQuery();
  const { data: statsData, loading } = useRequestsStatsQuery({
    variables: {
      filter: {
        lastDays,
        companyUuid,
        buildingUuid,
      },
    },
  });

  useEffect(() => {
    if (currentRole === Role.WorkplaceManager) {
      setSelectedCompany(viewer.company?.uuid);
    }
  }, [currentRole, viewer]);

  useEffect(() => {
    if (!loading) {
      setLoading(false);
    }
  }, [loading, setLoading]);

  const selectedCompany = useMemo(() => {
    if (companyUuid == null) {
      return undefined;
    }

    return filterData?.reqMgt.requestFilterEntities.companies.find((c) => c.uuid === companyUuid);
  }, [companyUuid, filterData]);

  const selectedBuilding = useMemo(() => {
    if (buildingUuid == null) {
      return undefined;
    }

    return filterData?.reqMgt.requestFilterEntities.buildings.find((c) => c.uuid === buildingUuid);
  }, [buildingUuid, filterData]);

  const stats = statsData?.reqMgt.requestsStats;
  const isPropertyManager = currentRole === Role.PropertyManager;

  const averageResolutionTime = stats?.averageResolutionTime ?? 0;

  // Full string for tooltip
  const averageResolutionTimeFullString = Duration.fromObject(
    { minutes: averageResolutionTime },
    { locale: i18n.language },
  )
    .shiftTo("days", "hours", "minutes")
    .toHuman({ unitDisplay: "narrow" });

  // Short string for value
  let averageResolutionTimeString = "";

  if (averageResolutionTime <= 1440) {
    const roundedHours = Math.round(averageResolutionTime / 60);
    averageResolutionTimeString = Duration.fromObject({ hours: roundedHours }, { locale: i18n.language })
      .shiftTo("hours")
      .toHuman({ unitDisplay: "narrow" });
  } else {
    const roundedDays = Math.round(averageResolutionTime / 1440);
    averageResolutionTimeString = Duration.fromObject({ days: roundedDays }, { locale: i18n.language })
      .shiftTo("days")
      .toHuman({ unitDisplay: "narrow" });
  }
  const generateCSV = () => {
    const opts = { fields: ["metric", "value"] };
    const data = [
      {
        metric: t("requests.widgets.newRequests"),
        value: statsData?.reqMgt.requestsStats.newRequests.toString(),
      },
      {
        metric: t("requests.widgets.completedRequests"),
        value: statsData?.reqMgt.requestsStats.completedRequests.toString(),
      },
      {
        metric: `${t("requests.widgets.activeRequests")} - ${t("requests.status.type.NOT_STARTED")}`,
        value: statsData?.reqMgt.requestsStats.notStartedRequests.toString(),
      },
      {
        metric: `${t("requests.widgets.activeRequests")} - ${t("requests.status.type.IN_PROGRESS")}`,
        value: statsData?.reqMgt.requestsStats.inProgressRequests.toString(),
      },
    ];

    if (isPropertyManager) {
      data.push({
        metric: t("requests.widgets.averageResolutionTime"),
        value: averageResolutionTimeString,
      });

      data.push({
        metric: t("requests.widgets.unassignedRequests"),
        value: statsData?.reqMgt.requestsStats.unassignedRequests.toString(),
      });
    }

    const csv = parse(data, opts);
    saveAs(new Blob(["\ufeff", csv]), "requests_metrics.csv", { autoBom: false });
  };

  const prepareTrendProps = (value: number) => {
    if (value > 0) {
      return { upTrend: { value, unit: "Percentage" as MetricUnit } };
    } else if (value < 0) {
      return { downTrend: { value, unit: "Percentage" as MetricUnit } };
    }

    return undefined;
  };

  return (
    <WidgetUI.Widget
      disableOverflow
      icon={RiQuestionnaireLine}
      tooltip={isPropertyManager ? t("requests.widgets.insightsTooltip") : t("requests.widgets.insightsTooltipPM")}
      title={t("requests.widgets.insights") ?? ""}
      buttonText={t("visitors.widgets.backToTop")}
      dropdownMenu={
        <Dropdown.Icon icon={RiMoreFill}>
          <Dropdown.Item icon={RiFileChartLine} onClick={generateCSV}>
            {t("home.widgets.exportToCsv")}
          </Dropdown.Item>
        </Dropdown.Icon>
      }
    >
      <div className="container">
        <div className="filters">
          <div className="dropdowns">
            <Dropdown.Button size="sm" title={t("home.widgets.lastDays", { duration: lastDays }) ?? ""} variant="ghost">
              <Dropdown.Item onClick={() => setLastDays(7)} selected={lastDays === 7}>
                {t("home.widgets.lastDays", { duration: 7 })}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setLastDays(30)} selected={lastDays === 30}>
                {t("home.widgets.lastDays", { duration: 30 })}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setLastDays(90)} selected={lastDays === 90}>
                {t("home.widgets.lastDays", { duration: 90 })}
              </Dropdown.Item>
            </Dropdown.Button>
            {isPropertyManager && (
              <>
                <Dropdown.Button
                  size="sm"
                  title={buildingUuid != null ? selectedBuilding?.name : t("requests.widgets.allBuildings")}
                  variant="ghost"
                >
                  <Dropdown.Item onClick={() => setSelectedBuilding(undefined)} selected={buildingUuid === undefined}>
                    {t("requests.widgets.allBuildings")}
                  </Dropdown.Item>
                  {filterData?.reqMgt.requestFilterEntities.buildings.map((b) => (
                    <Dropdown.Item
                      onClick={() => setSelectedBuilding(b.uuid)}
                      selected={buildingUuid === b.uuid}
                      key={b.uuid}
                    >
                      {b.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Button>
                <Dropdown.Button
                  size="sm"
                  title={companyUuid != null ? selectedCompany?.name : t("requests.widgets.allCompanies")}
                  variant="ghost"
                >
                  <Dropdown.Item onClick={() => setSelectedCompany(undefined)} selected={companyUuid === undefined}>
                    {t("requests.widgets.allCompanies")}
                  </Dropdown.Item>
                  {filterData?.reqMgt.requestFilterEntities.companies.map((b) => (
                    <Dropdown.Item
                      onClick={() => setSelectedCompany(b.uuid)}
                      selected={companyUuid === b.uuid}
                      key={b.uuid}
                    >
                      {b.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Button>
              </>
            )}
            <Tooltip placement="bottom-start" title={t("requests.widgets.filterTooltip")}>
              <div className="mb-2" style={{ display: "inline-block" }}>
                <RiInformationLine
                  style={{
                    color: colors.medium,
                    cursor: "pointer",
                  }}
                />
              </div>
            </Tooltip>
          </div>
          <div className="arrows">
            <IconButton onClick={() => scrollWidget("left")} disabled={!isLeftScrollPossible}>
              <RiArrowLeftSLine size={24} />
            </IconButton>
            <IconButton onClick={() => scrollWidget("right")} disabled={!isRightScrollPossible}>
              <RiArrowRightSLine size={24} />
            </IconButton>
          </div>
        </div>
        <div className="scroll-container">
          <div className="stats" ref={gridRef}>
            <SingleMetric
              id="new-requests"
              title={t("requests.widgets.newRequests")}
              tooltip={t("requests.widgets.newRequestsTooltip")}
              value={{ value: stats?.newRequests ?? 0 }}
              loading={loading}
              {...prepareTrendProps(stats?.trends.newRequests ?? 0)}
            />
            <SingleMetric
              id="completed-requests"
              title={t("requests.widgets.completedRequests")}
              tooltip={t("requests.widgets.completedRequestsTooltip")}
              value={{ value: stats?.completedRequests ?? 0 }}
              loading={loading}
              {...prepareTrendProps(stats?.trends.completedRequests ?? 0)}
            />
            {isPropertyManager && (
              <SingleMetric
                id="average-resolution-time"
                title={t("requests.widgets.averageResolutionTime")}
                tooltip={t("requests.widgets.averageResolutionTimeTooltip")}
                value={{
                  value: averageResolutionTime > 0 ? averageResolutionTimeString : 0,
                  tooltip: averageResolutionTimeFullString,
                }}
                loading={loading}
                {...prepareTrendProps(stats?.trends.averageResolutionTime ?? 0)}
              />
            )}
            <SingleMetric
              id="active-requests"
              title={t("requests.widgets.activeRequests")}
              tooltip={t("requests.widgets.activeRequestsTooltip")}
              value={{ value: (stats?.inProgressRequests ?? 0) + (stats?.notStartedRequests ?? 0) }}
              loading={loading}
              {...prepareTrendProps(stats?.trends.activeByStatus ?? 0)}
            >
              <div className="statuses">
                <div className="status">
                  <StatusTypeIcon type={ReqMgtStatusType.NotStarted} className="d-flex" />
                  <Text variant="text" size="small" weight="medium" component="span">
                    {t("requests.status.type.NOT_STARTED")}
                  </Text>
                  <h2 className="value">{stats?.notStartedRequests ?? 0}</h2>
                </div>
                <div className="status">
                  <StatusTypeIcon type={ReqMgtStatusType.InProgress} className="d-flex" />
                  <Text variant="text" size="small" weight="medium" component="span">
                    {t("requests.status.type.IN_PROGRESS")}
                  </Text>
                  <h2 className="value">{stats?.inProgressRequests ?? 0}</h2>
                </div>
              </div>
            </SingleMetric>
            {isPropertyManager && (
              <SingleMetric
                id="unassigned-requests"
                title={t("requests.widgets.unassignedRequests")}
                tooltip={t("requests.widgets.unassignedRequestsTooltip")}
                value={{ value: stats?.unassignedRequests ?? 0 }}
                loading={loading}
                action={
                  <ButtonLink variant="outline" href="/request-management?tab=unassigned" size="sm">
                    {t("requests.widgets.viewUnassigned")}
                  </ButtonLink>
                }
              />
            )}
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .empty {
            flex-grow: 1;
          }
          .container {
            display: flex;
            flex-direction: column;
            padding: 0.5rem;
            padding-bottom: 0;
            align-items: flex-start;
            overflow-x: hidden;
          }

          .filters {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
          }

          .dropdowns {
            display: flex;
            align-items: center;
            gap: 0.25rem;
          }

          .arrows {
            display: flex;
            gap: 0.25rem;
          }

          .stats {
            margin-top: 0.5rem;
            display: grid;
            grid-template-columns: repeat(${isPropertyManager ? 5 : 3}, minmax(200px, 1fr));
            grid-template-rows: 252px;
            grid-gap: 0.5rem;
            width: 100%;
          }

          .status {
            display: grid;
            grid-template-columns: 1rem 1fr auto;
            grid-gap: 0.25rem;
            padding: 0.5rem 0;
            border-bottom: 1px solid ${colors.grayscale[10]};
            align-items: center;
          }

          .status .value {
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 1.5rem;
          }

          .status:first-child {
            border-top: 1px solid ${colors.grayscale[10]};
          }

          .scroll-container {
            overflow-x: auto;
            width: 100%;
            scroll-snap-type: x mandatory;
          }

          .scroll-container::-webkit-scrollbar {
            display: none;
          }
        `}
      </style>
    </WidgetUI.Widget>
  );
};
