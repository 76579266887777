import { useState } from "react";
import type { FilterItem, FilterValue } from "@equiem/react-admin-ui";
import { FilterDateModifier, FilterTextModifier, FilterOptionsModifier } from "@equiem/react-admin-ui";
import {
  RiBuilding4Line,
  RiTable2,
  RiArticleLine,
  RiInputMethodLine,
  RiRepeat2Line,
  RiHomeLine,
} from "@equiem/react-admin-ui/icons";
import { useTranslation } from "@equiem/localisation-eq1";
import { stringNotEmpty, useSiteContext } from "@equiem/lib";
import { DateTime } from "luxon";
import pickBy from "lodash/pickBy";
import { BookingStatus, useBookableResourceFilterOptionsQuery } from "../../../generated/gateway-client";
import { resourceTypeToString } from "../../../lib/resourceTypeToString";

export interface SiteBookingsFilters {
  startDate?: number;
  endDate?: number;
  siteUuid?: string[];
  buildingUuid?: string[];
  levelUuid?: string[];
  resourceName?: string;
  resourceTypeUuid?: string[];
  status?: BookingStatus[];
}

const filterKeys = {
  list: ["site", "building", "level", "resourceName", "resourceTypeUuid", "date", "bookingStatus"],
  calendar: ["site", "building", "level", "resourceName", "resourceTypeUuid"],
} as const;

type View = keyof typeof filterKeys;
type FilterKey = (typeof filterKeys)[View][number] | (typeof filterKeys)[View][number];
type ConcreteFilterValues = Partial<Record<FilterKey, FilterValue>>;

interface Result {
  multiSiteUser: boolean;
  filterItems: Record<string, FilterItem>;
  queryFilter: SiteBookingsFilters;
  setFilterValues: (values: Record<string, FilterValue>) => void;
}

const getOptionValue = <T extends string = string>(v: FilterValue | undefined): T[] | undefined =>
  v?.type === "options" && v.value != null && v.value.length > 0 ? v.value.map(({ value }) => value as T) : undefined;
const getTextValue = (v: FilterValue | undefined) =>
  v?.type === "text" && stringNotEmpty(v.value) ? v.value.trim() : undefined;

function getFilter(values: ConcreteFilterValues, view: View, timezone: string): SiteBookingsFilters {
  // ComplexeFilter remembers filters for other views that might not be relevant
  // to this view. That's useful for the user, but we should exclude them from
  // the query filters.
  const viewValues: ConcreteFilterValues = pickBy(values, (_, key) =>
    (filterKeys[view] as ReadonlyArray<string>).includes(key),
  );

  let startDate: number | undefined = undefined;
  let endDate: number | undefined = undefined;

  if (viewValues.date?.type === "date" && viewValues.date.value != null) {
    const [fromIso, untilIso] = Array.isArray(viewValues.date.value)
      ? viewValues.date.value
      : ([viewValues.date.value, viewValues.date.value] as const);

    startDate = DateTime.fromISO(fromIso, { zone: timezone }).startOf("day").toMillis();
    endDate = DateTime.fromISO(untilIso, { zone: timezone }).endOf("day").toMillis();
  }

  return {
    startDate,
    endDate,
    siteUuid: getOptionValue(viewValues.site),
    buildingUuid: getOptionValue(viewValues.building),
    levelUuid: getOptionValue(viewValues.level),
    resourceName: getTextValue(viewValues.resourceName),
    resourceTypeUuid: getOptionValue(viewValues.resourceTypeUuid),
    status: getOptionValue<BookingStatus>(viewValues.bookingStatus),
  };
}

const toFilterOption = (val: { uuid: string; name: string }, contextTokens: Array<string | undefined> = []) => {
  const context = contextTokens.filter(stringNotEmpty).join(", ");
  return { value: val.uuid, label: `${val.name}${stringNotEmpty(context) ? ` (${context})` : ""}` };
};

export function useSiteBookingsFilters(view: View): Result {
  const [values, setValues] = useState<ConcreteFilterValues>({});
  const currentSite = useSiteContext();
  const { t } = useTranslation();

  const { data } = useBookableResourceFilterOptionsQuery({
    variables: { permissionFilters: { canObserveBookings: true } },
    fetchPolicy: "cache-and-network",
  });
  const options = data?.bookableResourceFilterOptions;

  const siteOptions = options?.sites.map((s) => toFilterOption(s)).sort((a, b) => a.label.localeCompare(b.label)) ?? [];
  const selectedSites = getOptionValue(values.site) ?? [];
  const singleSite = siteOptions.length === 1 || selectedSites.length === 1;

  const buildingOptions = (options?.buildings ?? [])
    .filter((b) => selectedSites.length === 0 || selectedSites.some((s) => b.destination?.uuid === s))
    .map((b) => toFilterOption(b, [!singleSite ? b.destination?.name : undefined]));
  const selectedBuildings = getOptionValue(values.building) ?? [];
  const singleBuilding = options?.buildings.length === 1 || selectedBuildings.length === 1;

  const levelOptions = (options?.levels ?? [])
    .filter((l) => selectedSites.length === 0 || selectedSites.some((s) => l.building.destination?.uuid === s))
    .filter((l) => selectedBuildings.length === 0 || selectedBuildings.some((b) => l.building.uuid === b))
    .map((l) =>
      toFilterOption(l, [
        !singleBuilding || !singleSite ? l.building.name : undefined,
        !singleSite ? l.building.destination?.name : undefined,
      ]),
    );

  const site: FilterItem = {
    title: t("common.site"),
    icon: RiHomeLine,
    type: "options",
    modifiers: [FilterOptionsModifier.includes],
    disabled: siteOptions.length <= 1,
    options: siteOptions,
  };
  const building: FilterItem = {
    title: t("bookings.resources.resourceBuilding"),
    icon: RiBuilding4Line,
    type: "options",
    modifiers: [FilterOptionsModifier.includes],
    disabled: buildingOptions.length <= 1,
    options: buildingOptions,
  };
  const level: FilterItem = {
    title: t("bookings.resources.resourceLevel"),
    icon: RiTable2,
    type: "options",
    modifiers: [FilterOptionsModifier.includes],
    disabled: levelOptions.length <= 1,
    options: levelOptions,
  };
  const resourceName: FilterItem = {
    title: t("bookings.resources.resourceName"),
    icon: RiArticleLine,
    type: "text",
    modifiers: [FilterTextModifier.includes],
  };
  const resourceTypeUuid: FilterItem = {
    title: t("bookings.resources.resourceTypeFull"),
    icon: RiInputMethodLine,
    type: "options",
    modifiers: [FilterOptionsModifier.includes],
    disabled: (options?.resourceTypes.length ?? 0) <= 1,
    options: (options?.resourceTypes ?? [])
      .map((rt) => ({
        value: rt.uuid,
        label: resourceTypeToString(rt.name, t),
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  };
  const date: FilterItem = {
    title: t("common.date"),
    type: "date",
    modifiers: [FilterDateModifier.is, FilterDateModifier.between],
  };
  const bookingStatus: FilterItem = {
    title: t("common.status"),
    icon: RiRepeat2Line,
    type: "options",
    options: [
      { value: BookingStatus.PendingApproval, label: t("bookings.operations.status.pendingApproval") },
      { value: BookingStatus.Approved, label: t("bookings.operations.status.approved") },
      { value: BookingStatus.Declined, label: t("bookings.operations.status.declined") },
      { value: BookingStatus.Cancelled, label: t("bookings.reports.cancelled") },
      { value: BookingStatus.PendingPayment, label: t("bookings.operations.status.pendingPayment") },
      { value: BookingStatus.PaymentFailed, label: t("bookings.operations.status.paymentFailed") },
    ],
    modifiers: [FilterOptionsModifier.includes],
  };

  const items: Record<View, Partial<Record<FilterKey, FilterItem>>> = {
    list: {
      site,
      building,
      level,
      resourceName,
      resourceTypeUuid,
      date,
      bookingStatus,
    },
    calendar: {
      site,
      building,
      level,
      resourceName,
      resourceTypeUuid,
    },
  };

  return {
    multiSiteUser: siteOptions.length > 1,
    filterItems: items[view],
    queryFilter: getFilter(values, view, currentSite.timezone),
    setFilterValues: setValues,
  };
}
