import React, { forwardRef } from "react";
import { useInputBorderCss } from "../useInputBorderCss";
import { useDropdownChevronBackground } from "../../../hooks/useDropdownChevronBackground";
import { useTheme } from "../../../contexts";

interface P {
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
}
export const FormSelectFacade = forwardRef<HTMLDivElement, P>(
  ({ disabled = false, className = "", children, ...props }, ref) => {
    const theme = useTheme();
    const borderCss = useInputBorderCss({ showChrome: "onInteraction" });
    const dropdownBg = useDropdownChevronBackground({
      color: theme.colors.grayscale[100],
    });

    return (
      <>
        <div ref={ref} className={`triggerInput ${borderCss.className} ${className}`} {...props}>
          {children}
        </div>
        <style jsx>{`
          .triggerInput {
            padding: ${theme.spacers.s3};
            opacity: ${disabled ? "0.5" : "1"};
            cursor: ${disabled ? "not-allowed" : "pointer"};
            background-color: ${disabled ? theme.colors.grayscale[5] : theme.colors.white} !important;
          }
          .triggerInput:hover,
          .triggerInput:focus,
          .focus-in {
            background: ${dropdownBg} right 11px center/16px 16px no-repeat;
          }
        `}</style>
      </>
    );
  },
);

FormSelectFacade.displayName = "FormSelectFacade";
