import { useContext, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { parse } from "json2csv";
import { DateTime } from "luxon";

import { CurrentProfile, notNullOrUndefined, Site } from "@equiem/lib";
import { formatters, useTranslation } from "@equiem/localisation-eq1";

import type {
  ActivitiesQuery,
  ActivitiesQueryVariables,
  ActivityChangeReqMgtStatusValue,
  ActivitySingleChange,
  AllRequestsReportQuery,
  AllRequestsReportQueryVariables,
} from "../../../generated/requests-client";
import {
  ActivitiesDocument,
  ActivityChangeFieldType,
  AllRequestsReportDocument,
  ReqMgtStatusType,
  useLogRequestsCsvExportMutation,
} from "../../../generated/requests-client";
import { RequestsAccessContext } from "../contexts/RequestsAccessContext";
import { RequestsFilterContext } from "../contexts/RequestsFilterContext";

const DEFAULT_PAGE_SIZE = 25;

export const useRequestExportData = () => {
  const { t, i18n } = useTranslation();
  const { name: siteName, uuid: siteUuid } = useContext(Site);
  const [reportLoading, setReportLoading] = useState(false);
  const { translatedFilters: filter } = useContext(RequestsFilterContext);
  const client = useApolloClient();
  const { profile } = useContext(CurrentProfile);
  const access = useContext(RequestsAccessContext);
  const [logUserHistory] = useLogRequestsCsvExportMutation();
  const getCSVFields = (): string[] => {
    const fields = [
      { title: t("requests.csv.dateTimeCreated"), visible: true },
      { title: t("requests.csv.closedDateTime"), visible: true },
      { title: t("requests.csv.reporter"), visible: true },
      { title: t("requests.csv.reporterCompany"), visible: true },
      { title: t("requests.csv.id"), visible: true },
      { title: t("requests.csv.status"), visible: true },
      { title: t("requests.csv.building"), visible: true },
      { title: t("requests.csv.level"), visible: true },
      { title: t("requests.csv.space"), visible: true },
      { title: t("requests.csv.category"), visible: true },
      { title: t("requests.csv.subCategory"), visible: access.propertyManager || access.requestManager },
      { title: t("requests.csv.assignee"), visible: access.propertyManager || access.requestManager },
      { title: t("requests.csv.description"), visible: true },
      { title: t("requests.csv.attachments"), visible: true },
      { title: t("requests.csv.watchers"), visible: true },
      { title: t("requests.csv.lastEditedDateTime"), visible: true },
      { title: t("requests.csv.lastEditedBy"), visible: true },
      { title: t("requests.csv.activityAndComments"), visible: true },
    ];

    return fields.filter((item) => item.visible).reduce((acc: string[], curr) => [...acc, curr.title], []);
  };

  const getDateTimeString = (value: number) =>
    `${formatters.datelong(value, i18n.language)} ${formatters.timeshort(value, i18n.language)}`;

  const getActivity = async (entityUuid: string) => {
    const result = await client.query<ActivitiesQuery, ActivitiesQueryVariables>({
      query: ActivitiesDocument,
      fetchPolicy: "network-only",
      variables: {
        entityUuid: entityUuid,
        first: DEFAULT_PAGE_SIZE,
      },
    });

    return result.data.activities.edges;
  };

  const getNameString = (user?: { firstName?: string | null; lastName?: string | null } | null) => {
    if (user == null) {
      return null;
    }

    return `${user.firstName} ${user.lastName?.substring(0, 1)}`;
  };

  const exportRequestCsv = async () => {
    setReportLoading(true);

    const requests: AllRequestsReportQuery["reqMgt"]["requests"]["edges"] = [];
    const fetchRequests = async (after?: string | null) => {
      await client.clearStore();
      const { data } = await client.query<AllRequestsReportQuery, AllRequestsReportQueryVariables>({
        query: AllRequestsReportDocument,
        fetchPolicy: "network-only",
        variables: {
          filter: filter,
          page: {
            first: DEFAULT_PAGE_SIZE,
            after: after != null ? after : null,
          },
        },
      });
      return data;
    };

    const firstRequestsChunk = await fetchRequests();
    requests.push(...firstRequestsChunk.reqMgt.requests.edges);

    let hasNextPage = firstRequestsChunk.reqMgt.requests.pageInfo.hasNextPage;
    let endCursor = firstRequestsChunk.reqMgt.requests.pageInfo.endCursor;

    while (hasNextPage) {
      const requestsChunk = await fetchRequests(endCursor);
      hasNextPage = requestsChunk.reqMgt.requests.pageInfo.hasNextPage;
      endCursor = requestsChunk.reqMgt.requests.pageInfo.endCursor;
      requests.push(...requestsChunk.reqMgt.requests.edges);
    }

    const csvDataPromises = requests
      .map(async (edge) => {
        const request = edge.node;

        if (request == null) {
          return undefined;
        }

        const activities = await getActivity(request.uuid);
        const lastEdit = activities
          .filter((x) => x.node?.change != null)
          .sort((a, b) => (a.node?.timestamp ?? 0) - (b.node?.timestamp ?? 0))
          .at(-1);

        const activitiesWithComments = activities
          .filter((x) => x.node?.comment != null)
          .sort((a, b) => (a.node?.timestamp ?? 0) - (b.node?.timestamp ?? 0));

        const completedActivityTimestamp = activities
          .filter(
            (x) =>
              x.node?.change?.type === ActivityChangeFieldType.RequestStatus &&
              ((x.node.change as ActivitySingleChange).to as ActivityChangeReqMgtStatusValue).status?.type ===
                ReqMgtStatusType.Completed,
          )
          .sort((a, b) => (a.node?.timestamp ?? 0) - (b.node?.timestamp ?? 0))
          .at(-1)?.node?.timestamp;

        const row = {
          [t("requests.csv.dateTimeCreated")]: getDateTimeString(request.created),
          [t("requests.csv.closedDateTime")]:
            completedActivityTimestamp != null ? getDateTimeString(completedActivityTimestamp) : null,
          [t("requests.csv.reporter")]: getNameString(request.reporter),
          [t("requests.csv.reporterCompany")]: request.reporter?.company?.name,
          [t("requests.csv.id")]: request.reference,
          [t("requests.csv.status")]: request.status.name,
          [t("requests.csv.building")]: request.space?.buildingLevel.building.name,
          [t("requests.csv.level")]: request.space?.buildingLevel.name,
          [t("requests.csv.space")]: request.space?.name,
          [t("requests.csv.category")]: request.category.name,
          [t("requests.csv.subCategory")]: request.subCategory?.name,
          [t("requests.csv.assignee")]: getNameString(request.assignee),
          [t("requests.csv.description")]: request.description,
          [t("requests.csv.attachments")]: request.attachments
            ?.map((attachment) => `${attachment.filename}`)
            .join("\n"),
          [t("requests.csv.watchers")]: request.watchers.length,
          [t("requests.csv.lastEditedDateTime")]: getDateTimeString(request.updated),
          [t("requests.csv.lastEditedBy")]: getNameString(lastEdit?.node?.user),
          [t("requests.csv.activityAndComments")]:
            activitiesWithComments.length > 0
              ? activitiesWithComments
                  .map((comment) => `${getNameString(comment.node?.user)}: ${comment.node?.comment}`)
                  .join("\n")
              : null,
        };

        return row;
      })
      .filter(notNullOrUndefined);

    const csvData = await Promise.all(csvDataPromises);

    const flattenedCsvData = csvData.flat();
    const csv = parse(flattenedCsvData, {
      fields: getCSVFields(),
    });
    const blob = new Blob(["\ufeff", csv]);
    setReportLoading(false);

    return { blob: blob, count: flattenedCsvData.length };
  };

  const exportCsv = async () => {
    const exportResult = await exportRequestCsv();
    const url = URL.createObjectURL(exportResult.blob);
    const element = document.createElement("a");
    element.href = url;
    const dateStr = DateTime.now()
      .setLocale("en-gb")
      .toLocaleString({ day: "2-digit", month: "long", year: "numeric" })
      .replace(" ", "");
    element.download = `${siteName}RequestExport-${dateStr}.${exportResult.count}.csv`;

    if (profile?.uuid != null) {
      await logUserHistory({
        variables: {
          destinationUuid: siteUuid,
          profileUuid: profile.uuid,
        },
      });
    }

    document.body.appendChild(element);
    element.click();
  };

  return { exportCsv, reportLoading };
};
