import { Widget as WidgetUI } from "@equiem/react-admin-ui";
import { Site, notNullOrUndefined } from "@equiem/lib";
import type { WidgetProps } from "@equiem/lib";
import { RiCalendarCheckLine } from "@equiem/react-admin-ui/icons";
import React, { useEffect, useContext } from "react";
import type { FC } from "react";
import { DateTime } from "luxon";
import groupBy from "lodash/groupBy";

import { useSiteBookingsLazyQuery } from "../../../generated/gateway-client";
import { dateNumberToStringDate } from "../../../lib/dateNumberToStringDate";
import { BookingDayView } from "./components/BookingDayView";
import { useTranslation } from "@equiem/localisation-eq1";

const getVariables = () => {
  const now = DateTime.now().set({ second: 0, millisecond: 0 });
  return {
    date: now.valueOf(),
    endDate: now.plus({ months: 1 }).valueOf(),
    skipCancelled: true,
    page: { first: 20 },
  };
};

const REFRESH_MS = 60000;

export const Widget: FC<WidgetProps> = ({ setLoading }) => {
  const { uuid, timezone } = useContext(Site);
  const [fetchSiteBookings, { data, previousData }] = useSiteBookingsLazyQuery({ variables: getVariables() });
  const { t } = useTranslation();

  useEffect(() => {
    fetchSiteBookings({ variables: getVariables() }).catch((e) => console.error(e));

    const refreshInterval = setInterval(() => {
      fetchSiteBookings({ variables: getVariables() }).catch((e) => console.error(e));
    }, REFRESH_MS);

    return () => clearInterval(refreshInterval);
  }, [uuid, fetchSiteBookings]);

  useEffect(() => {
    if (data != null) {
      setLoading(false);
    }
  }, [data, setLoading]);

  const bookings = ((data ?? previousData)?.siteBookingsList.edges ?? [])
    .map((edge) => edge.node)
    .filter(notNullOrUndefined)
    .filter((booking) => booking.startDate > Date.now());
  const bookingsByDate = groupBy(bookings, ({ startDate }) => dateNumberToStringDate(startDate, timezone));

  return (
    <>
      <WidgetUI.Widget
        buttonText={t("visitors.widgets.backToTop")}
        icon={RiCalendarCheckLine}
        title={t("bookings.operations.upcomingBookings")}
        noResults={{
          hasNoResults: bookings.length === 0,
          text: t("bookings.operations.thereNoUpcomingBookings"),
        }}
        className="upcoming-bookings"
        tooltip={t("bookings.operations.getQuickView")}
      >
        <div className="d-flex flex-column pb-3">
          {Object.values(bookingsByDate).map((groupBookings, index) => (
            <BookingDayView key={index} bookings={groupBookings} date={groupBookings[0].startDate} />
          ))}
        </div>
      </WidgetUI.Widget>
    </>
  );
};
