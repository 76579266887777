import type { PropsWithChildren } from "react";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import type { FormikHelpers, FormikProps } from "formik";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";

import { CurrentProfile, useShowError, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import {
  Button,
  Form as EqForm,
  Modal,
  ProgressCircle,
  useConfirmer,
  useTheme,
  useToast,
} from "@equiem/react-admin-ui";

import type { ReqMgtCreateCategoryInput, ReqMgtStatusType, StatusFragment } from "../../../generated/requests-client";
import { useCompaniesV2LazyQuery } from "../../../generated/requests-client";
import { ModalContext } from "../contexts/ModalContext";
import { useStatusData } from "../hooks/useStatusData";
import type { Status } from "../types";

interface Props extends PropsWithChildren {
  editingStatus: Status;
}

export const StatusWidget: React.FC<Props> = ({ editingStatus }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const showError = useShowError();
  const modal = useContext(ModalContext);
  const { uuid } = useSiteContext();
  const { profile, canManageRegion } = useContext(CurrentProfile);
  const { spacers, colors } = useTheme();
  const toast = useToast();
  const { withConfirmation } = useConfirmer();
  const formInnerRef = useRef<FormikProps<Status>>(null);
  const isNewSpace = editingStatus.uuid == null;
  const initialValues: Status = {
    uuid: editingStatus.uuid,
    type: editingStatus.type,
    name: editingStatus.name,
    ownerCompanyUuid: isNewSpace ? undefined : editingStatus.ownerCompanyUuid,
  };

  const { updateStatus, createStatus, createLoading, updateLoading } = useStatusData();

  const validationSchema = () =>
    yup.object().shape({
      name: yup.string().required(),
      ownerCompanyUuid: canManageRegion ? yup.string().required() : yup.string(),
    });

  const [companiesQuery, companiesQueryData] = useCompaniesV2LazyQuery({
    variables: {
      first: 100,
      destinationUuid: uuid,
    },
  });
  const companies = useMemo(() => companiesQueryData.data?.companiesV2.edges ?? [], [companiesQueryData]);

  const onClose = useCallback(() => {
    setShowModal(false);
    modal.close();
  }, [modal]);

  const onCloseModal = useCallback(() => {
    formInnerRef.current?.dirty === true
      ? withConfirmation({
          title: t("common.areYouSure"),
          message: t("settings.editProfile.cancellingAgreement"),
          confirmButtonText: t("common.yesCancel"),
          cancelButtonText: t("home.widgets.cancelNo"),
          confirmButtonVariant: "danger",
          onConfirm: onClose,
        })()
      : onClose();
  }, [withConfirmation, t, onClose]);

  useEffect(() => {
    if (modal.activeModal === "StatusWidget") {
      if (canManageRegion) {
        companiesQuery().catch((e) => {
          console.error(e);
        });
      }
      setShowModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.activeModal]);

  const handleSubmit = async (values: Status, { setSubmitting }: FormikHelpers<Status>) => {
    try {
      setSubmitting(true);
      values.uuid == null
        ? await createStatus(values as ReqMgtCreateCategoryInput & { type: ReqMgtStatusType })
        : await updateStatus(values as StatusFragment);
      onClose();
      toast.positive(isNewSpace ? t("requests.status.statusAdded") : t("requests.status.statusUpdated"));
    } catch (e: unknown) {
      showError(e);
    }
    setSubmitting(false);
  };

  return (
    <>
      <Modal.Dialog
        title={isNewSpace ? t("requests.status.addStatus") : t("requests.status.updateStatus")}
        show={showModal}
        sideModal={true}
        onHide={onCloseModal}
        hideOnEsc={true}
        hideOnClick={true}
        size="md"
      >
        <Modal.Header closeButton onClose={onCloseModal} noBorder={false} />
        {profile != null ? (
          <Formik
            initialValues={initialValues}
            innerRef={formInnerRef}
            onSubmit={handleSubmit}
            validationSchema={validationSchema()}
          >
            {({ errors, touched, submitForm, dirty, isValid, isSubmitting }) => (
              <>
                <Modal.Body>
                  <h1 className="title">{t("requests.status.header")}</h1>
                  <Form>
                    <EqForm.Group
                      label={t("requests.status.statusName")}
                      required
                      error={touched.name != null && errors.name != null ? errors.name : undefined}
                    >
                      <Field
                        id="name"
                        name="name"
                        placeholder={t("requests.status.statusNamePlaceholder")}
                        as={EqForm.Input}
                        maxLength={300}
                      />
                    </EqForm.Group>
                    {canManageRegion && (
                      <EqForm.Group
                        label={t("requests.status.owner")}
                        required
                        error={
                          touched.ownerCompanyUuid != null && errors.ownerCompanyUuid != null
                            ? t("requests.status.ownerPlaceholder")
                            : undefined
                        }
                      >
                        <Field
                          id="ownerCompanyUuid"
                          name="ownerCompanyUuid"
                          as={EqForm.Select}
                          disabled={initialValues.uuid != null}
                        >
                          <option value="">{t("requests.status.ownerPlaceholder")}</option>
                          {companies.flatMap(({ node }) => (
                            <option key={node?.name} value={node?.uuid}>
                              {node?.name}
                            </option>
                          ))}
                        </Field>
                      </EqForm.Group>
                    )}

                    {/* <EqForm.Group
                      label={t("requests.status.permissions")}
                      required
                      showTooltip
                      tooltipText={t("requests.status.permissionsTooltip")}
                    >
                      <EqForm.RadioButton
                        id="users-all"
                        label={t("settings.allUsers")}
                        checked={values.type === "public"}
                        onChange={() => {
                          setFieldValue("type", "public").catch(console.error);
                        }}
                      />
                      <br />
                      <EqForm.RadioButton
                        id="users-selected"
                        label={t("requests.status.onlySelected")}
                        checked={values.type === "private"}
                        onChange={() => {
                          setFieldValue("type", "private").catch(console.error);
                          // probably we should display <UserSearch /> somewhere here
                        }}
                      />
                    </EqForm.Group> */}
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="ghost" className="mr-4" onClick={onCloseModal}>
                    {t("common.cancel")}
                  </Button>
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={!dirty || !isValid || isSubmitting || createLoading || updateLoading}
                    onClick={() => {
                      void submitForm();
                    }}
                  >
                    {(createLoading || updateLoading) && <ProgressCircle size="xs" />}
                    {isNewSpace ? t("requests.status.addStatus") : t("common.saveChanges")}
                  </Button>
                </Modal.Footer>
              </>
            )}
          </Formik>
        ) : (
          <>
            <Modal.Body>
              <div className="text-center pt-8">
                {createLoading || updateLoading ? <ProgressCircle size="lg" /> : t("common.unknownErrorPleaseTryAgain")}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="ghost" className="mr-4" onClick={onClose}>
                {t("common.cancel")}
              </Button>
              <Button disabled type="submit" variant="primary">
                {t("settings.editProfile.saveChanges")}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal.Dialog>

      <style jsx>{`
        .title {
          font-weight: 700;
          line-height: 24px;
          margin: 8px 0 24px;
        }
        .content {
          padding: 0 ${spacers.s6} 0;
        }
        hr {
          border: none;
          border-top: 1px solid ${colors.border};
          margin-bottom: 16px;
        }
      `}</style>
    </>
  );
};
