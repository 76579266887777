import React, { useCallback, useContext, useMemo, useState } from "react";
import { isEmpty } from "lodash";

import { CurrentProfile, stringNotEmpty, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form, Skeleton, useTheme } from "@equiem/react-admin-ui";

import { ProfileTag } from "../../../components/ProfileTag";
import type { RequestQuery } from "../../../generated/requests-client";
import { useListProfilesQuery } from "../../../generated/requests-client";
import { RequestDetailsContext } from "../contexts/RequestDetailsContext";

interface P {
  request: RequestQuery["reqMgt"]["request"];
}
const RequestReporterEdit: React.FC<P> = ({ request }) => {
  const showError = useShowError();
  const theme = useTheme();
  const profile = useContext(CurrentProfile);
  const { t } = useTranslation();
  const [selectedReporter, setSelectedReporter] = useState(request.reporter?.uuid);
  const { data, loading, error, refetch } = useListProfilesQuery({
    fetchPolicy: "network-only",
    skip: isEmpty(profile.siteProfile?.site.destination?.uuid),
    variables: {
      page: {
        first: 5,
      },
      filter: {
        active: true,
      },
      siteUuids: [profile.siteProfile?.site.destination?.uuid ?? ""],
    },
  });
  const { loading: updateLoading, update } = useContext(RequestDetailsContext);

  if (error != null) {
    showError(error);
  }

  const searchCb = useCallback(
    async (keyword: string) => {
      const kl = keyword.toLowerCase();
      const filter = kl.length === 0 ? {} : kl.includes("@") ? { email: kl } : { name: kl, email: kl };

      try {
        await refetch({ filter: { active: true, ...filter } });
      } catch (e: unknown) {
        showError(e);
      }
    },
    [refetch, showError],
  );

  const options = useMemo(() => {
    const lists = (data?.listProfiles.edges ?? []).flatMap((edge) => {
      if (edge.node == null || request.reporter?.uuid === edge.node.uuid) {
        return [];
      }
      const params = {
        profileIconUrl: edge.node.avatar ?? undefined,
        companyName: edge.node.companyV2?.name ?? "",
        email: edge.node.email,
        firstName: edge.node.firstName ?? "",
        lastName: edge.node.lastName ?? "",
      };

      return [
        {
          label: <ProfileTag {...params} />,
          facadeLabel: <ProfileTag {...params} hideDetails />,
          value: edge.node.uuid,
        },
      ];
    });

    if (request.reporter == null) {
      return lists;
    }

    const reporterP = {
      profileIconUrl: request.reporter.avatar ?? undefined,
      companyName: request.reporter.companyV2?.name ?? "",
      email: request.reporter.email,
      firstName: request.reporter.firstName ?? "",
      lastName: request.reporter.lastName ?? "",
    };

    return [
      {
        label: <ProfileTag {...reporterP} />,
        facadeLabel: <ProfileTag {...reporterP} hideDetails />,
        value: request.reporter.uuid,
      },
    ].concat(lists);
  }, [data?.listProfiles.edges, request.reporter]);

  if (loading) {
    return <Skeleton.Line width="250px" height="50px" />;
  }

  return (
    <>
      <div>
        <Form.DynamicSelect
          size="sm"
          search
          searchPlaceholder={t("common.findAPerson")}
          searchCb={searchCb}
          disabled={updateLoading}
          showSelectedOnList
          mobileView="minimal"
          name="rreporter"
          showChrome="onInteraction"
          value={request.reporter?.uuid ?? ""}
          options={options}
          onChange={(event) => {
            const uuid = event.target.value;
            if (stringNotEmpty(uuid) && uuid !== selectedReporter) {
              // Update function already handle the nice catch.
              update(request, { reporter: uuid })
                .then(() => {
                  setSelectedReporter(uuid);
                })
                .catch(console.log);
            }
          }}
        />
      </div>
      <style jsx>{`
        div {
          margin-left: -${theme.spacers.s3};
        }
      `}</style>
    </>
  );
};

export const RequestReporter: React.FC<P> = ({ request }) => {
  return (
    <>
      {request.viewerRelations.canManage ? (
        <RequestReporterEdit request={request} />
      ) : (
        <div className="py-3">
          {request.reporter != null && (
            <ProfileTag
              profileIconUrl={request.reporter.avatar ?? undefined}
              companyName={request.reporter.companyV2?.name ?? ""}
              email={request.reporter.email}
              firstName={request.reporter.firstName ?? ""}
              lastName={request.reporter.lastName ?? ""}
              hideDetails
            />
          )}
        </div>
      )}
    </>
  );
};
