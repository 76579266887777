import { useTranslation } from "@equiem/localisation-eq1";
import { AccordionList, AccordionListItem, DateTime as DT } from "@equiem/react-admin-ui";
import React, { useContext, useMemo } from "react";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";
import { groupWeekday } from "../../../lib/formatWeekday";
import { toDateTime } from "../../../lib/dateTimeHelpers";

export const BookingDiscountsDisplay: React.FC = () => {
  const { i18n, t } = useTranslation();
  const { resource, timezone } = useContext(BookingModalInfo);

  // only show the discount flag if there are resource charges (excluding extras, etc.)
  const hasResourceCharges =
    resource.paymentRateHourly > 0 ||
    resource.paymentRateHalfDay > 0 ||
    resource.paymentRateFullDay > 0 ||
    resource.paymentRateHourlyAfterHours > 0 ||
    resource.paymentRateHourlyWeekend > 0;
  const hasDiscounts = hasResourceCharges && resource.discounts.length > 0;
  const hasMultipleDiscounts = hasResourceCharges && resource.discounts.length > 1;

  const discountList = resource.discounts.map(({ percentage, availability }) => {
    return (
      <>
        <strong>{t("bookings.discounts.saveDiscount", { percentage })}</strong> -{" "}
        {availability.__typename === "BookingDiscountEarlyBookingAvailability" && (
          <>{t("bookings.discounts.bookDaysInAdvance", { count: availability.daysBeforeBookingToApply })}</>
        )}
        {availability.__typename === "BookingDiscountPeriodicAvailability" && (
          <>
            {t("bookings.discounts.bookInDays", {
              days: groupWeekday(availability.days ?? [], i18n.language, "long", t("common.to").toLocaleLowerCase()),
            })}
            {availability.startTime != null && availability.endTime != null && (
              <>
                {" "}
                {t("common.between").toLocaleLowerCase()}{" "}
                <DT.TimeRange
                  language={i18n.language}
                  start={toDateTime(availability.startTime)?.toMillis() ?? 0}
                  end={toDateTime(availability.endTime)?.toMillis() ?? 0}
                  timezone={timezone}
                />
              </>
            )}
          </>
        )}
      </>
    );
  });

  const headerText = useMemo(() => {
    const totalDiscountPercentage = resource.discounts.reduce((sum, { percentage }) => sum + percentage, 0);
    const percentage = totalDiscountPercentage > 100 ? 100 : totalDiscountPercentage;

    return hasMultipleDiscounts ? (
      <strong>{t("bookings.discounts.saveUptoDiscount", { percentage })}</strong>
    ) : (
      discountList[0]
    );
  }, [resource, hasMultipleDiscounts, t, discountList]);

  return (
    <>
      {hasDiscounts && (
        <AccordionList headerText={headerText} showCollapseButton={hasMultipleDiscounts} showCollapsed={false}>
          {discountList.map((discountDisplay, i) => (
            <AccordionListItem key={i}>{discountDisplay}</AccordionListItem>
          ))}
        </AccordionList>
      )}
    </>
  );
};
