import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { CurrentProfile, notNullOrUndefined, useShowError } from "@equiem/lib";

import type { Building, BuildingLevel, ProfileSiteProfileConnection, Space } from "../../../generated/requests-client";
import {
  ProfileRegistrationType,
  useCategoriesLazyQuery,
  useMyResidenceLazyQuery,
  useRequestBuildingLevelsLazyQuery,
  useRequestBuildingsLazyQuery,
} from "../../../generated/requests-client";
import { CreateRequestContext } from "../CreateRequestContext";

export function useBuildingData() {
  const showError = useShowError();
  const { profile } = useContext(CurrentProfile);
  const isUserResidential = useMemo(
    () =>
      profile?.siteProfiles.edges.some((sp) => sp.node?.registrationType === ProfileRegistrationType.Residential) ??
      false,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const [myResidenceQuery, { data: myResidenceData, loading: myResidenceLoading }] = useMyResidenceLazyQuery();
  const [buildingQuery, { data: buildingsData, loading: buildingsLoading, error: buildingsError }] =
    useRequestBuildingsLazyQuery();
  const [categoriesQuery, { data: categoriesData, loading: categoriesLoading }] = useCategoriesLazyQuery();
  const [levelQuery, { data: levelsData, loading: levelLoading }] = useRequestBuildingLevelsLazyQuery();
  const { values } = useContext(CreateRequestContext);
  const [searchQuery, setSearchQuery] = useState("");

  const extractBuildings = useCallback(
    (myResidenceSitesProfile: ProfileSiteProfileConnection | undefined): Array<Pick<Building, "uuid" | "name">> => {
      const buildingsWithSpaces = (myResidenceSitesProfile?.edges ?? [])
        .flatMap((prof) => prof.node?.apartment?.buildingLevels.filter((level) => level.spaces.length > 0))
        .filter(notNullOrUndefined)
        .reduce<Record<string, { uuid: string; name: string }>>((obj, level) => {
          obj[level.building.uuid] = { uuid: level.building.uuid, name: level.building.name };
          return obj;
        }, {});
      return Object.values(buildingsWithSpaces);
    },
    [myResidenceData?.profile?.siteProfiles],
  );

  const extractBuildingLevels = useCallback(
    (myResidenceSitesProfile: ProfileSiteProfileConnection | undefined, buildingUuid: string) => {
      if (myResidenceSitesProfile != null) {
        const residence = myResidenceSitesProfile.edges.find(
          (sp) => sp.node?.apartment?.buildingLevels != null && sp.node.apartment.buildingLevels.length > 0,
        );
        return (
          residence?.node?.apartment?.buildingLevels
            .map((level) =>
              values.buildingUuid != null &&
              level.spaces.length > 0 && // don't show levels without spaces
              (level.building as Building).uuid === buildingUuid
                ? level
                : null,
            )
            .filter(notNullOrUndefined) ?? []
        );
      }

      return [];
    },
    [myResidenceData?.profile?.siteProfiles],
  );

  const buildings = useMemo(() => {
    if (isUserResidential) {
      return extractBuildings(myResidenceData?.profile?.siteProfiles as ProfileSiteProfileConnection);
    }
    return buildingsData?.reqMgt.buildings ?? [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingsData?.reqMgt.buildings, myResidenceData?.profile?.siteProfiles]);

  const buildingLevels = useMemo(() => {
    if (isUserResidential) {
      return extractBuildingLevels(
        myResidenceData?.profile?.siteProfiles as ProfileSiteProfileConnection,
        values.buildingUuid ?? "",
      );
    }
    return levelsData?.reqMgt.buildingLevels ?? [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelsData?.reqMgt.buildingLevels, myResidenceData?.profile?.siteProfiles]);

  if (buildingsError != null) {
    showError(buildingsError);
  }

  useEffect(() => {
    if (isUserResidential) {
      myResidenceQuery().catch(showError);
    } else {
      buildingQuery().catch(showError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values.buildingUuid != null) {
      if (!isUserResidential) {
        levelQuery({ variables: { buildingUuid: values.buildingUuid } }).catch(showError);
      }
      categoriesQuery({ variables: { buildingUuid: values.buildingUuid } }).catch(showError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.buildingUuid]);

  const levels = useMemo(() => {
    return searchQuery.length > 0
      ? buildingLevels.filter(
          (level) => level.name.toLowerCase().includes(searchQuery.toLowerCase()) && level.spaces.length > 0,
        )
      : buildingLevels;
  }, [buildingLevels, searchQuery]);

  const spaces: Space[] = useMemo(() => {
    const buildingLevel = buildingLevels.find((bl: { uuid: string }) => bl.uuid === values.levelUuid) as BuildingLevel;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const result = buildingLevel != null ? buildingLevel.spaces : [];

    return searchQuery.length > 0
      ? result.filter((space: { name: string }) => space.name.toLowerCase().includes(searchQuery.toLowerCase()))
      : result;
  }, [buildingLevels, searchQuery, values.levelUuid]);

  const categories = useMemo(() => {
    return categoriesData?.reqMgt.categories ?? [];
  }, [categoriesData]);

  const locationName = useMemo(() => {
    const building = buildings.find((b) => b.uuid === values.buildingUuid);
    const level = levels.find((l) => l.uuid === values.levelUuid);
    const space = spaces.find((s) => s.uuid === values.spaceUuid);

    if (building == null || level == null || space == null) {
      return "";
    }

    return `${building.name}, ${level.name}, ${space.name}`;
  }, [buildings, levels, spaces, values]);

  const currentCategory = useMemo(() => {
    return categories.find((c) => c.uuid === values.categoryUuid);
  }, [categories, values]);

  const loading = useMemo(() => {
    return categoriesLoading || levelLoading || buildingsLoading || myResidenceLoading;
  }, [categoriesLoading, levelLoading, buildingsLoading, myResidenceLoading]);

  return {
    buildings,
    levels,
    spaces,
    categories,
    locationName,
    currentCategory,
    loading,
    searchQuery,
    setSearchQuery,
  };
}
