import React, { useContext, useMemo } from "react";

import { UrlParams } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import type { FilterItem } from "@equiem/react-admin-ui";
import {
  Button,
  ComplexFilter,
  FilterDateModifier,
  FilterIsModifier,
  Form,
  ProgressCircle,
  Tooltip,
  useIsMobileWidth,
  useTheme,
} from "@equiem/react-admin-ui";
import { RiCalendarLine, RiEyeLine, RiFileDownloadLine, RiSearchLine } from "@equiem/react-admin-ui/icons";

import { RequestsAccessContext } from "../contexts/RequestsAccessContext";
import { RequestsFilterContext } from "../contexts/RequestsFilterContext";
import { RequestsScopeContext } from "../contexts/RequestsScopeContext";
import { useRequestExportData } from "../hooks/useRequestExportCsv";
import { useRequestFilterAssignee } from "../hooks/useRequestFilterAssignee";
import { useRequestFilterBuilding } from "../hooks/useRequestFilterBuilding";
import { useRequestFilterCategory } from "../hooks/useRequestFilterCategory";
import { useRequestFilterCompany } from "../hooks/useRequestFilterCompany";
import { useRequestFilterReporter } from "../hooks/useRequestFilterReporter";
import { useRequestFilterStatus } from "../hooks/useRequestFilterStatus";

import { RequestQuickTab } from "./RequestQuickTab";

interface P {
  className?: string;
}
export const RequestsFilter: React.FC<P> = ({ className = "" }) => {
  const isMobile = useIsMobileWidth();
  const { t, i18n } = useTranslation();
  const { colors, breakpoints } = useTheme();
  const access = useContext(RequestsAccessContext);
  const { filters, search, clearSearch, handleSearch, setFilters, activeTab, entities } =
    useContext(RequestsFilterContext);
  const urlParamsProvider = useContext(UrlParams);
  const { exportCsv, reportLoading } = useRequestExportData();
  const { currentScope } = useContext(RequestsScopeContext);
  const hasAccessToCsvExport = access.workplaceManager || access.propertyManager || access.requestManager;

  const { filter: status } = useRequestFilterStatus(entities?.statuses, activeTab, filters.status?.value);
  const { filter: assignee } = useRequestFilterAssignee(
    !access.showAssignee || currentScope === "assigned",
    entities?.assignees,
  );
  const { filter: reporter } = useRequestFilterReporter(entities?.reporters);
  const { filter: company } = useRequestFilterCompany(entities?.companies);
  const { filter: building } = useRequestFilterBuilding(entities?.buildings);
  const { filter: category } = useRequestFilterCategory(entities?.categories);

  const filterExist = useMemo(() => {
    const totalFilters = Object.keys(filters).filter((key) => !["search"].includes(key)).length;
    if (currentScope === "assigned" && totalFilters === 1 && filters.assignee != null) {
      return false;
    }

    return totalFilters > 0;
  }, [activeTab, filters]);

  const complexFilters = useMemo(() => {
    const build: Record<string, FilterItem> = {
      created: {
        title: t("common.date"),
        type: "date",
        icon: RiCalendarLine,
        modifiers: [FilterDateModifier.is, FilterDateModifier.between],
      },
      watchedByUuid: {
        title: t("requests.tab.watching"),
        type: "is",
        icon: RiEyeLine,
        modifiers: isMobile ? [FilterIsModifier.empty, FilterIsModifier.is] : [FilterIsModifier.is],
      },
    };

    if (category != null) {
      build.category = category;
    }
    if (status != null) {
      build.status = status;
    }
    if (building != null) {
      build.building = building;
    }
    if (assignee != null && access.showAssignee) {
      build.assignee = assignee;
    }
    if (
      company != null &&
      !access.workplaceManager &&
      (access.propertyManager || access.requestManager || access.requestAssignee) &&
      currentScope !== "my"
    ) {
      build.company = company;
    }
    if (
      reporter != null &&
      (access.propertyManager || access.workplaceManager || access.requestManager || access.requestAssignee) &&
      currentScope !== "my"
    ) {
      build.reporter = reporter;
    }

    return Object.keys(build)
      .sort()
      .reduce<Record<string, FilterItem>>((prev, key) => {
        prev[key] = build[key];
        return prev;
      }, {});
  }, [
    access.propertyManager,
    access.requestAssignee,
    access.requestManager,
    access.showAssignee,
    access.workplaceManager,
    activeTab,
    assignee,
    building,
    category,
    company,
    reporter,
    status,
    t,
  ]);

  return (
    <>
      <div className={`filters ${className}`}>
        <ComplexFilter
          key={activeTab}
          filters={complexFilters}
          onChange={setFilters}
          language={i18n.language}
          urlParamsProvider={urlParamsProvider}
          additionalFilterDropdownContent={
            isMobile ? (
              <div className="quick-filters-dropdown quick-tabs">
                <RequestQuickTab />
              </div>
            ) : undefined
          }
          autoShow
        >
          {({ renderChips, renderFilterButton, renderClearButton }) => (
            <>
              <div className="main-row">
                {!isMobile && (
                  <div className="left-side quick-tabs">
                    <RequestQuickTab />
                  </div>
                )}
                <div className="right-side">
                  <div className="search-container">
                    <Form.InputExtended
                      className="search-input"
                      icon={RiSearchLine}
                      placeholder={`${t("common.search")}...`}
                      variant="sm"
                      value={search}
                      onChange={handleSearch}
                      onClear={clearSearch}
                      clearable
                    />
                  </div>
                  {renderFilterButton("primary", "bottom-end")}
                  {hasAccessToCsvExport && (
                    <div>
                      <Tooltip title={t("visitors.reception.csvExport")} placement="bottom">
                        <Button
                          variant="ghost"
                          round
                          disabled={reportLoading}
                          onClick={() => {
                            void exportCsv();
                          }}
                          className="export-csv"
                        >
                          {reportLoading ? <ProgressCircle size={16} /> : <RiFileDownloadLine size={16} />}
                        </Button>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
              {!isMobile && filterExist && (
                <div className="chips-row">
                  <div className="left-side">{renderChips()}</div>
                  <div className="right-side">{renderClearButton()}</div>
                </div>
              )}
            </>
          )}
        </ComplexFilter>
      </div>
      <style jsx>{`
        .filters {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 32px;
        }
        .main-row {
          display: flex;
          justify-content: space-between;
          gap: 8px;
        }
        .right-side,
        .left-side {
          display: flex;
          gap: 8px;
          align-items: flex-start;
        }
        .chips-row {
          display: flex;
          justify-content: space-between;
          gap: 8px;
          border-top: 1px solid ${colors.grayscale[10]};
          padding: 8px 0;
          margin-top: 8px;
        }
        .search-container {
          width: 270px;
        }

        @media (max-width: ${breakpoints.lg}px) {
          .main-row {
            flex-direction: column;
            max-width: 100vw;
          }

          .main-row .left-side {
            overflow-x: auto;
          }

          .main-row .left-side::-webkit-scrollbar,
          .quick-filters-dropdown::-webkit-scrollbar {
            display: none;
          }

          .main-row .right-side {
            display: grid;
            grid-template-columns: auto 32px ${hasAccessToCsvExport ? "32px" : ""};
            align-items: center;
          }

          .search-container {
            width: 100%;
          }

          .quick-filters-dropdown {
            display: flex;
            gap: 8px;
            align-items: flex-start;
            padding: 8px;
            overflow-x: auto;
            min-height: 48px;
          }
        }

        @media (max-width: ${breakpoints.sm}px) {
          .filters {
            padding: 16px;
          }
        }
      `}</style>
    </>
  );
};
