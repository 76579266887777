import type { TabItem } from "@equiem/react-admin-ui";
import { Modal, Tabs } from "@equiem/react-admin-ui";
import type { FC } from "react";
import React, { useState } from "react";
import type { FlexTenantFragmentFragment } from "../../generated/gateway-client";
import { useTranslation } from "@equiem/localisation-eq1";
import { EditMembershipForm } from "./EditMembershipForm";
import { EditBuildingsForm } from "./EditBuildingsForm";
import { EditCompanyForm } from "./EditCompanyForm";

interface Props {
  flexTenant: FlexTenantFragmentFragment;
  readonly?: boolean;
}

export const EditFlexTenantForm: FC<Props> = ({ flexTenant, readonly }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState("membership");

  const tabs: TabItem[] = [
    { title: t("flex.members.editForm.titles.membership"), key: "membership" },
    { title: t("flex.members.editForm.titles.buildings"), key: "buildings" },
    { title: t("flex.members.editForm.titles.company"), key: "company" },
  ];

  return (
    <>
      <Modal.Header closeButton={true}>
        <Tabs items={tabs} selected={selected} onSelect={(e) => setSelected(e)} />
      </Modal.Header>
      {selected === "membership" && <EditMembershipForm flexTenant={flexTenant} readonly={readonly} />}
      {selected === "buildings" && <EditBuildingsForm flexTenant={flexTenant} readonly={readonly} />}
      {selected === "company" && <EditCompanyForm flexTenant={flexTenant} readonly={readonly} />}
    </>
  );
};
