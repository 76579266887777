import { useTranslation, formatters } from "@equiem/localisation-eq1";
import type { FlexTenantFragmentFragment } from "../../generated/gateway-client";
import { useMyFlexTenantsQuery } from "../../generated/gateway-client";
import type { FilterValueOptions } from "@equiem/react-admin-ui";
import { Avatar, Dropdown, ProgressCircle, Table, useTheme } from "@equiem/react-admin-ui";
import type { FC } from "react";
import React, { useContext, useMemo } from "react";
import { StatusTag } from "../StatusTag";
import { SideModalContext } from "../../contexts/SideModalContext";
import { RiMoreLine } from "@equiem/react-admin-ui/icons";
import { DateTime } from "luxon";
import { notNullOrUndefined } from "@equiem/lib";

const BlockTd: React.FC<{ tenant: FlexTenantFragmentFragment }> = ({ tenant }) => {
  const { openTab } = useContext(SideModalContext);
  const { colors } = useTheme();
  const { t, i18n } = useTranslation();

  const uniqueBuildings = [...new Set(tenant.locations.map((l) => l.building.name))];

  const moveInDate = useMemo(
    () => formatters.dateshort(DateTime.fromMillis(tenant.moveInDate), i18n.language),
    [tenant, i18n.language],
  );

  const expiryDate = useMemo(
    () => formatters.dateshort(DateTime.fromMillis(tenant.expirationDate), i18n.language),
    [tenant, i18n.language],
  );

  const overLimit = tenant.memberLimit != null && tenant.membershipCount > tenant.memberLimit;

  return (
    <tr>
      <td className="d-flex align-items-center">
        <div>
          <Avatar firstName={tenant.name} className="mr-4" />
        </div>
        <div>{tenant.company.name}</div>
      </td>
      <td>{tenant.name}</td>
      <td>{uniqueBuildings.join(", ")}</td>
      <td>
        <span className={overLimit ? "alert" : ""}>
          {tenant.membershipCount} / {tenant.memberLimit ?? "-"}
        </span>
      </td>
      <td>{tenant.monthlyCreditAllowance}</td>
      <td>{moveInDate}</td>
      <td>{expiryDate}</td>
      <td>
        <StatusTag status={tenant.status} />
      </td>
      <td>
        <Dropdown.Icon size="sm" placement="right-end" icon={RiMoreLine} className="action-menu">
          <Dropdown.Item onClick={() => openTab("editFlexTenant", tenant)}>
            {t("flex.members.editMembership")}
          </Dropdown.Item>
        </Dropdown.Icon>
      </td>
      <style jsx>{`
        span.alert {
          color: ${colors.danger};
        }
      `}</style>
    </tr>
  );
};

export type Filters = Record<string, FilterValueOptions>;

interface Props {
  search: string | null;
  filters?: Filters;
}

export const MembersTable: FC<Props> = ({ search, filters }) => {
  const { t } = useTranslation();
  const { data, loading } = useMyFlexTenantsQuery({
    variables: {
      search,
      first: 20,
      filters: {
        sites: filters?.sites?.value?.map((v) => v.value) ?? [],
        buildings: filters?.buildings?.value?.map((v) => v.value) ?? [],
      },
    },
  });

  const tenants = data?.myFlexTenants.edges ?? [];

  return (
    <>
      <Table.Table className="w-100">
        <thead className="table-head">
          <tr>
            <Table.Header label={t("flex.members.form.company")} />
            <Table.Header label={t("flex.members.form.membershipName")} />
            <Table.Header label={t("flex.members.form.buildings")} />
            <Table.Header label={t("flex.members.form.teamSize")} />
            <Table.Header label={t("flex.members.form.creditBalance")} />
            <Table.Header label={t("flex.members.form.moveInDate")} />
            <Table.Header label={t("flex.members.form.expirationDate")} />
            <Table.Header label={t("flex.members.form.status")} />
            <Table.Header label="" />
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan={8}>
                <div className="mt-6 mb-6 d-flex justify-content-center align-items-center">
                  <ProgressCircle size="md" />
                  <span className="ml-4">{`${t("common.loading")}...`}</span>
                </div>
              </td>
            </tr>
          )}
          {tenants.length === 0 && !loading && (
            <tr>
              <td colSpan={8}>
                <div className="mt-6 mb-6 d-flex justify-content-center align-items-center">
                  <span className="ml-4">{`${t("common.noResults")}...`}</span>
                </div>
              </td>
            </tr>
          )}
          {tenants
            .map((e) => e.node)
            .filter(notNullOrUndefined)
            .map((node, i) => (
              <BlockTd key={i} tenant={node} />
            ))}
        </tbody>
      </Table.Table>
    </>
  );
};
