import React from "react";
import { Field } from "formik";

import { useTranslation } from "@equiem/localisation-eq1";
import { Form } from "@equiem/react-admin-ui";

import type {
  VisitorForBuildingReceptionFragment,
  VisitorForReceptionFragment,
} from "../../../../generated/visitors-client";
import { VisitorStatus } from "../../../../generated/visitors-client";

export const CardIdInput = ({
  visitor,
  setCardIdLoading,
  setCardStatusLoading,
  cardIds,
  handleCardIdBlur,
  setCardIds,
  cardIdDisabled,
}: {
  visitor: VisitorForReceptionFragment | VisitorForBuildingReceptionFragment;
  setCardStatusLoading: boolean;
  setCardIdLoading: boolean;
  cardIdDisabled: boolean;
  cardIds: Record<string, string | undefined>;
  handleCardIdBlur: (cardId: string, visitorUuid: string) => Promise<void>;
  setCardIds: (value: Record<string, string | undefined>) => void;
}) => {
  const { t } = useTranslation();
  const hoverClassName = cardIdDisabled ? "" : "card-input-hover";

  return (
    <>
      <>
        <Field
          as={Form.Input}
          disabled={cardIdDisabled}
          className={`card-input ${
            ((visitor.cardId != null && visitor.cardId.length > 0) || visitor.status === VisitorStatus.CheckedOut) &&
            "card-input-fill"
          } ${visitor.cardReturned ? "card-returned" : hoverClassName}`}
          name={visitor.uuid}
          placeholder={t("visitors.reception.enterCardId")}
          autoComplete="off"
          maxLength={20}
          value={cardIds[visitor.uuid] ?? visitor.cardId ?? ""}
          onKeyPress={async (e: React.KeyboardEvent<HTMLInputElement>) =>
            e.key === "Enter" && handleCardIdBlur(e.currentTarget.value, visitor.uuid)
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setCardIds({
              ...cardIds,
              [visitor.uuid]: e.target.value,
            });
            e.currentTarget.classList.add("card-input-fill");
          }}
          onBlur={async (e: React.FocusEvent<HTMLInputElement>) => handleCardIdBlur(e.target.value, visitor.uuid)}
        />
      </>
      <style jsx>
        {`
          :global(.card-input) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 30px !important;
          }
          :global(.card-input-fill) {
            background: #f2f2f2 !important;
            color: #666666 !important;
          }
          :global(.card-input-hover:hover) {
            background-repeat: no-repeat !important;
            background-position: calc(100% - 10px) center !important;
            background-image: url("data:image/svg+xml,%3Csvg width='18' height='15' viewBox='0 0 4 14' fill='%23666666' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.276 9.66737L9.03733 2.90604L8.09467 1.96337L1.33333 8.72471L1.33333 9.66737H2.276ZM2.82867 11.0007H0L0 8.17204L7.62333 0.548707C7.74835 0.423726 7.91789 0.353516 8.09467 0.353516C8.27144 0.353516 8.44098 0.423726 8.566 0.548707L10.452 2.43471C10.577 2.55973 10.6472 2.72926 10.6472 2.90604C10.6472 3.08282 10.577 3.25236 10.452 3.37737L2.82867 11.0007ZM0 12.334L12 12.334V13.6674L0 13.6674L0 12.334Z' fill='%23666666'/%3E%3C/svg%3E%0A") !important;
          }
          :global(.card-input-fill:focus) {
            background: initial !important;
            color: initial !important;
          }
          :global(.card-returned) {
            background: #008000 !important;
            color: white !important;
            background-repeat: no-repeat !important;
            background-position: calc(0% + 2px) center !important;
            padding-left: 30px !important;
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='15' viewBox='0 0 4 16' fill='%23ffffff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.88525 4.66588L4.57592 6.35655L3.63325 7.29922L0.333252 3.99922L3.63325 0.699219L4.57592 1.64189L2.88525 3.33255H7.66659C9.08107 3.33255 10.4376 3.89446 11.4378 4.89465C12.438 5.89484 12.9999 7.2514 12.9999 8.66588C12.9999 10.0804 12.438 11.4369 11.4378 12.4371C10.4376 13.4373 9.08107 13.9992 7.66659 13.9992H1.66659V12.6659H7.66659C8.72745 12.6659 9.74487 12.2445 10.495 11.4943C11.2452 10.7442 11.6666 9.72675 11.6666 8.66588C11.6666 7.60502 11.2452 6.5876 10.495 5.83746C9.74487 5.08731 8.72745 4.66588 7.66659 4.66588H2.88525Z' fill='%23ffffff'/%3E%3C/svg%3E%0A") !important;
          }
        `}
      </style>
    </>
  );
};
