/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useCallback, useContext, useState } from "react";
import { BookingModal } from "../../operations/contexts/BookingModalContext";
import type { FormValues } from "../util/BookingDiscountFormValues";
import type { FormikHelpers } from "formik";
import {
  useCreateUpdateBookingDiscountMutation,
  BookingDiscountType,
  useDeleteBookingDiscountMutation,
  BookingDiscountStatus,
  GetSiteBookingDiscountsDocument,
  ActivitiesDocument,
} from "../../../generated/gateway-client";
import { useConfirmer, useToast } from "@equiem/react-admin-ui";
import { useErrorTranslation, useTranslation } from "@equiem/localisation-eq1";
import type { ApolloError, InternalRefetchQueriesInclude } from "@apollo/client";
import type { BookingDiscount } from "../util/BookingDiscount";
import { activityQueryInputVariables } from "../util/activityQueryVariables";

export enum ViewMode {
  New = "NEW",
  Edit = "EDIT",
  View = "VIEW",
  History = "HISTORY",
}

const defaultValue: FormValues = {
  availability: {
    daysBeforeBookingToApply: null,
  },
  percentage: null!,
  resources: [],
  title: "",
  type: BookingDiscountType.Periodic,
  startDate: "",
  endDate: "",
};

interface BookingDiscountAddEditContext {
  create: () => void;
  edit: (discount: BookingDiscount) => void;
  viewDiscount: (discount: BookingDiscount) => void;
  deleteDiscount: (discount: BookingDiscount, onConfirm?: () => void) => void;
  createOrUpdate: (values: FormValues, actions: FormikHelpers<FormValues>) => Promise<void>;
  value: FormValues;
  setValue: (value: FormValues) => void;
  viewMode: ViewMode;
  selectedDiscount: BookingDiscount | null;
  canUpdateOrDeleteDiscount: (discount: BookingDiscount) => boolean;
  viewHistory: (discount: BookingDiscount) => void;
}

export const BookingDiscountAddEditContext = createContext<BookingDiscountAddEditContext>({
  create: () => null,
  edit: () => {},
  viewDiscount: () => {},
  deleteDiscount: () => {},
  createOrUpdate: async () => {},
  value: defaultValue,
  setValue: () => {},
  viewMode: ViewMode.New,
  selectedDiscount: null,
  canUpdateOrDeleteDiscount: () => false,
  viewHistory: () => {},
});

interface Props {
  children?: React.ReactNode;
  valuesInit?: FormValues;
}

export const BookingDiscountAddEditProvider: React.FC<Props> = ({ children, valuesInit = defaultValue }) => {
  const { tError } = useErrorTranslation();
  const modal = useContext(BookingModal);
  const [value, setValue] = useState<FormValues>(valuesInit);
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.New);
  const [selectedDiscount, setSelectedDiscount] = useState<BookingDiscount | null>(null);
  const toast = useToast();
  const { t } = useTranslation();
  const { withConfirmation } = useConfirmer();

  const [upsertMutation] = useCreateUpdateBookingDiscountMutation();
  const [deleteMutation] = useDeleteBookingDiscountMutation();

  const canUpdateOrDeleteDiscount = useCallback(
    (discount: BookingDiscount) =>
      discount.resources.every((resource) => resource.viewerPermissions?.canManageBookings === true) &&
      discount.status !== BookingDiscountStatus.Deleted,
    [],
  );

  const create = useCallback(() => {
    setValue(defaultValue);
    modal.setDisplayMode("view");
    setViewMode(ViewMode.New);
    modal.open(viewMode);
  }, [modal, viewMode]);

  const edit = useCallback(
    (discount: BookingDiscount) => {
      setValue({
        availability:
          discount.availability.__typename === "BookingDiscountEarlyBookingAvailability"
            ? {
                daysBeforeBookingToApply: discount.availability.daysBeforeBookingToApply,
              }
            : {
                days: discount.availability.days,
                endTime: discount.availability.endTime,
                startTime: discount.availability.startTime,
              },
        endDate: discount.availability.endDate === "infinity" ? "" : discount.availability.endDate,
        percentage: discount.percentage,
        resources: discount.resources.map((resource) => resource.uuid),
        startDate: discount.availability.startDate,
        title: discount.title,
        type: discount.type,
        uuid: discount.uuid,
      });
      modal.setDisplayMode("view");
      setViewMode(ViewMode.Edit);
      modal.open(viewMode);
    },
    [setValue, modal, viewMode],
  );

  const viewDiscount = useCallback(
    (discount: BookingDiscount) => {
      setSelectedDiscount(discount);
      modal.setDisplayMode("view");
      setViewMode(ViewMode.View);
      modal.open(viewMode);
    },
    [modal, viewMode],
  );

  const viewHistory = useCallback(
    (discount: BookingDiscount) => {
      setSelectedDiscount(discount);
      setViewMode(ViewMode.History);
      modal.open(viewMode);
    },
    [modal, viewMode],
  );

  const deleteDiscount = useCallback(
    (discount: BookingDiscount, onConfirm?: () => void) => {
      withConfirmation({
        title: t("common.areYouSure"),
        message: t("bookings.discounts.deleteWarning"),
        confirmButtonText: t("common.yesDelete"),
        cancelButtonText: t("home.widgets.cancelNo"),
        confirmButtonVariant: "danger",
        size: "md",
        onConfirm: async () => {
          onConfirm?.();

          try {
            const deleteResult = await deleteMutation({
              variables: { uuid: discount.uuid },
              refetchQueries: [
                GetSiteBookingDiscountsDocument,
                {
                  query: ActivitiesDocument,
                  variables: activityQueryInputVariables(discount.uuid),
                },
              ],
            });
            if (deleteResult.errors != null) {
              toast.negative(deleteResult.errors[0].message);
            } else {
              toast.positive(t("bookings.discounts.discountWasSuccessfullyDeleted", { name: discount.title }));
              modal.close();
            }
          } catch (e: unknown) {
            console.error(e);
            toast.negative(tError(e as ApolloError));
          }
        },
      })();
    },
    [t, withConfirmation, modal, deleteMutation, tError, toast],
  );

  const createOrUpdate = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      actions.setSubmitting(true);

      const refetchQueries: InternalRefetchQueriesInclude = [GetSiteBookingDiscountsDocument];
      if (values.uuid != null) {
        refetchQueries.push({
          query: ActivitiesDocument,
          variables: activityQueryInputVariables(values.uuid),
        });
      }

      try {
        const result = await upsertMutation({
          variables: {
            input: {
              uuid: values.uuid != null ? values.uuid : undefined,
              availability:
                values.type === BookingDiscountType.EarlyBooking
                  ? {
                      dateRange: `[${values.startDate}, ${values.endDate}]`,
                      daysBeforeBookingToApply: values.availability.daysBeforeBookingToApply,
                    }
                  : {
                      dateRange: `[${values.startDate}, ${values.endDate}]`,
                      days: values.availability.days,
                      startTime: values.availability.startTime,
                      endTime: values.availability.endTime,
                    },
              percentage: values.percentage,
              resourceUuids: values.resources,
              title: values.title,
              type: values.type,
            },
          },
          refetchQueries,
        });

        if (result.errors != null) {
          toast.negative(result.errors[0].message);
        } else {
          modal.close(true);
          const successMessage =
            viewMode === ViewMode.New
              ? t("bookings.discounts.discountWasSuccessfullyCreated", { name: values.title })
              : t("bookings.discounts.discountWasSuccessfullyUpdated", { name: values.title });
          toast.positive(successMessage);
        }
      } catch (e: unknown) {
        console.error(e);
        toast.negative(tError(e as ApolloError));
      } finally {
        actions.setSubmitting(false);
      }
    },
    [upsertMutation, toast, modal, t, tError, viewMode],
  );

  return (
    <BookingDiscountAddEditContext.Provider
      value={{
        create,
        edit,
        viewDiscount,
        createOrUpdate,
        value,
        setValue,
        viewMode,
        selectedDiscount,
        deleteDiscount,
        canUpdateOrDeleteDiscount,
        viewHistory,
      }}
    >
      {children}
    </BookingDiscountAddEditContext.Provider>
  );
};
