import React, { useContext } from "react";

import { CurrentProfile, CurrentRole, Role, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { ActivityFeed, useConfirmer } from "@equiem/react-admin-ui";
import { RiTeamLine, RiUserHeartLine } from "@equiem/react-admin-ui/icons";

import type { RequestQuery } from "../../../generated/requests-client";
import { ActivityAudience, useActivitiesQuery, useCreateCommentMutation } from "../../../generated/requests-client";

import { ActivityDisplay } from "./ActivityDisplay";

const pollInterval = 5 * 60 * 1000;

interface Props {
  request: RequestQuery["reqMgt"]["request"];
}
export const Activities: React.FC<Props> = ({ request }) => {
  const { data, loading, fetchMore } = useActivitiesQuery({
    variables: { entityUuid: request.uuid, first: 10 },
    pollInterval,
  });
  const [mutation] = useCreateCommentMutation({ refetchQueries: ["Activities"] });
  const { withConfirmation } = useConfirmer();
  const { currentRole } = useContext(CurrentRole);
  const { t } = useTranslation();
  const showError = useShowError();
  const { profile } = useContext(CurrentProfile);

  const receivers = [
    {
      id: "all",
      label: t("requests.activity.everyone"),
      icon: RiTeamLine,
    },
  ];

  if (request.viewerRelations.canManage || request.viewerRelations.assignee || currentRole === Role.PropertyManager) {
    receivers.push({
      id: "team",
      label: t("requests.activity.internalTeam"),
      icon: RiUserHeartLine,
    });
  }

  const isRequestOwner = profile?.uuid === request.reporter?.uuid;

  const onSubmit = (message: string, receiverId?: string) => {
    if (receiverId === "all") {
      withConfirmation({
        title: t("common.areYouSure"),
        message: isRequestOwner
          ? t("requests.activity.publicMessageConfirmationRM")
          : t("requests.activity.publicMessageConfirmation"),
        confirmButtonText: t("requests.activity.publicMessageConfirm"),
        cancelButtonText: t("common.cancelNo"),
        onConfirm: () => {
          mutation({
            variables: {
              input: {
                requestUuid: request.uuid,
                comment: message,
                audience: ActivityAudience.Everyone,
              },
            },
          }).catch(showError);
        },
      })();
    } else {
      mutation({
        variables: {
          input: {
            requestUuid: request.uuid,
            comment: message,
            audience: ActivityAudience.Internal,
          },
        },
      }).catch(showError);
    }
  };

  const fetchMoreAsync = async () => {
    await fetchMore({
      variables: {
        after: data?.activities.pageInfo.endCursor,
      },
      updateQuery(prev, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          activities: {
            ...fetchMoreResult.activities,
            edges: [...prev.activities.edges, ...fetchMoreResult.activities.edges],
          },
        };
      },
    });
  };

  return (
    <ActivityFeed
      messageOptions={{ receivers, onSubmit }}
      scrollToBottom={!loading && data != null}
      next={fetchMoreAsync}
      dataLength={data?.activities.totalCount ?? 0}
      hasMore={data?.activities.pageInfo.hasNextPage ?? false}
    >
      {!loading
        ? data?.activities.edges.map(({ node }) =>
            node != null ? <ActivityDisplay key={node.uuid} activity={node} /> : null,
          )
        : null}
    </ActivityFeed>
  );
};
