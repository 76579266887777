import { ActivitySortField } from "../../../generated/gateway-client";
import type { ActivitiesQueryVariables } from "../../../generated/gateway-client";

export const activityQueryInputVariables = (entityUuid: string): ActivitiesQueryVariables => {
  return {
    entityUuid,
    first: 100,
    sort: {
      asc: false,
      field: ActivitySortField.Timestamp,
    },
  };
};
