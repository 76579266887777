"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSafeTOptions = void 0;
var getSafeTOptions = function (variables) {
    if (variables === void 0) { variables = {}; }
    var options = { replace: variables };
    if ("count" in variables && typeof variables.count === "number") {
        options.count = variables.count;
    }
    if ("defaultValue" in variables && typeof variables.defaultValue === "string") {
        options.defaultValue = variables.defaultValue;
    }
    return options;
};
exports.getSafeTOptions = getSafeTOptions;
