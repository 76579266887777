import { CurrentProfile, Site, stringIsEmpty } from "@equiem/lib";
import { useContext, useState, useEffect, useCallback } from "react";
import type { Runtype } from "runtypes";

const safeParse = <T>(val: string | null, runtype?: Runtype<T>): T | null => {
  if (val == null) {
    return null;
  }
  try {
    const parsed = JSON.parse(val) as T;
    return runtype?.check(parsed) ?? parsed;
  } catch (err: unknown) {
    console.error("Invalid local storage state:", err);
    return null;
  }
};

export const useLocalStorageState = <T>(key: string, initialValue: T, runtype?: Runtype<T>) => {
  const site = useContext(Site);
  const { profile, loading: profileLoading } = useContext(CurrentProfile);
  const loading = stringIsEmpty(site.uuid) || profileLoading;

  const storageKey = `_eq_localstoragestate_${site.uuid}_${profile?.uuid}_${key}`;

  // if the viewer context hasn't loaded yet, we can't meaningfully initialize
  // the state

  const [state, setState] = useState(
    !loading ? safeParse(localStorage.getItem(storageKey), runtype) ?? initialValue : null,
  );

  const handleUpdate = useCallback(
    (newValue: T) => {
      if (!loading) {
        localStorage.setItem(storageKey, JSON.stringify(newValue));
      }
      setState(newValue);
    },
    [storageKey, loading],
  );

  // handle syncing to local storage when profile loads
  useEffect(() => {
    if (!loading) {
      handleUpdate(state ?? safeParse(localStorage.getItem(storageKey), runtype) ?? initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageKey]);

  return [{ state: state ?? initialValue, loading }, handleUpdate] as const;
};
