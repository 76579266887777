import { Form } from "@equiem/react-admin-ui";
import type { ArrayHelpers } from "formik";
import React from "react";
import type { BookableResourceAddOn } from "../../../../generated/gateway-client";
import { BookableResourceAddOnType } from "../../../../generated/gateway-client";
import { useAddOnError } from "../../hooks/useAddOnError";
import { AddOnCheckboxOption } from "./AddOnCheckboxOption";
import { useTranslation } from "@equiem/localisation-eq1";

interface P {
  addOn: BookableResourceAddOn;
  index: number;
  arrayHelpers: ArrayHelpers;
}
export const AddonSingleOption: React.FC<P> = ({ addOn, index, arrayHelpers }) => {
  const { t } = useTranslation();
  const error = useAddOnError(index);
  if (addOn.type !== BookableResourceAddOnType.SingleOption || addOn.options == null || addOn.options.length === 0) {
    return null;
  }

  return (
    <Form.Group label={addOn.name ?? t("common.unknown")} required={addOn.mandatory} error={error}>
      <AddOnCheckboxOption option={addOn.options[0]} index={index} arrayHelpers={arrayHelpers} />
    </Form.Group>
  );
};
