import { type Static, Optional, Union, Record, Array, String } from "runtypes";

const ValueEntry = Union(String, Record({ label: String, value: String }));

const FilterValue = Union(
  Record({
    type: String,
    modifier: String,
    value: Optional(Union(ValueEntry, Array(ValueEntry))),
    search: Optional(String),
  }),
);

export const SavedResourceFilters = Record({
  siteUuid: Optional(FilterValue),
  buildingUuid: Optional(FilterValue),
  levelUuid: Optional(FilterValue),
  resourceTypeUuid: Optional(FilterValue),
  resourceFeatureUuid: Optional(FilterValue),
  date: Optional(FilterValue),
  startTime: Optional(FilterValue),
  durationMinutes: Optional(FilterValue),
  showPaidResources: Optional(FilterValue),
  maximumCapacity: Optional(FilterValue),
  minimumCapacity: Optional(FilterValue),
  managedBy: Optional(FilterValue),
});
export type SavedResourceFilters = Static<typeof SavedResourceFilters>;
