import React, { useContext, useEffect, useMemo } from "react";
import { useFormikContext } from "formik";

import { CurrentProfile, useShowError, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Alert, Button, Modal, Tabs, Tag, useConfirmer, useTheme, useToast } from "@equiem/react-admin-ui";
import { RiErrorWarningLine } from "@equiem/react-admin-ui/icons";

import { useCompaniesV2LazyQuery } from "../generated/requests-client";
import { useCategoryData } from "../pages/settings/category/hooks/useCategoryData";
import { ModalContext } from "../pages/settings/contexts/ModalContext";

import { CategoryCafmIntegrationModalContext } from "./category-cafm-integration/CategoryCafmIntegrationModalContext";
import type { CategoryFormValues } from "./utils/categoryFormTypes";
import type { CategoryTabKey } from "./utils/categoryTabs";
import { categoryTabs } from "./utils/categoryTabs";
import { CategoryIntegrationsForm } from "./CategoryIntegrationsForm";
import { Subcategories } from "./Subcategories";

export const CategoryDetails: React.FC<{
  onClickEdit: () => void;
  selectedTab: string;
  setSelectedTab: (key: string) => void;
}> = ({ onClickEdit, selectedTab, setSelectedTab }) => {
  const { buildings, typeOptions, categoriesMap, deleteCategoryMutation, subCategories, setSelectedCategoryUuid } =
    useCategoryData();
  const { colors } = useTheme();
  const { values } = useFormikContext<CategoryFormValues>();
  const { t } = useTranslation();
  const { canManageRegion } = useContext(CurrentProfile);
  const { uuid: siteUuid } = useSiteContext();
  const modal = useContext(ModalContext);
  const toast = useToast();
  const showError = useShowError();
  const { withConfirmation } = useConfirmer();
  const category = useMemo(() => (modal.id != null ? categoriesMap.get(modal.id) : undefined), [modal, categoriesMap]);
  const cafmLinkModalContext = useContext(CategoryCafmIntegrationModalContext);
  const tabs = categoryTabs(t);
  const selectedBuildings = useMemo(
    () => buildings?.filter((building) => values.buildings.some((uuid) => uuid === building?.uuid)),
    [buildings, values.buildings],
  );

  const [companiesQuery, companiesQueryData] = useCompaniesV2LazyQuery({
    variables: {
      first: 100,
      destinationUuid: siteUuid,
    },
  });
  const ownerCompanyName = useMemo(
    () =>
      companiesQueryData.data?.companiesV2.edges.find((company) => company.node?.uuid === values.ownerCompanyUuid)?.node
        ?.name ?? "",
    [companiesQueryData, values.ownerCompanyUuid],
  );
  const typeValue = useMemo(
    () => typeOptions.find((type) => type.value === values.type)?.label,
    [typeOptions, values.type],
  );

  const renderStatus = useMemo(() => {
    if (category?.queue == null) {
      return "";
    } else if (category.queue.active) {
      return <Tag variant="positive">{t("requests.category.status.active")}</Tag>;
    } else {
      return <Tag variant="negative">{t("requests.category.status.inactive")}</Tag>;
    }
  }, [category?.queue, t]);

  const currentTab = useMemo(() => {
    switch (selectedTab) {
      case "subcategories":
        return (
          <Subcategories
            isEditing={false}
            currentSubcategories={subCategories}
            setCurrentSubcategories={() => undefined}
          />
        );
      case "integrations":
        return cafmLinkModalContext.configurations != null &&
          cafmLinkModalContext.configurations.reqMgt.cafmConfigurationsBySiteUuid.length > 0 ? (
          <CategoryIntegrationsForm isEditing={false} />
        ) : null;
      default:
        return (
          <>
            <div className="category-details">
              <div className="section group">
                <p className="title">{values.name}</p>
                {renderStatus}
              </div>
              {category?.queue?.active === false && (
                <div className="group">
                  <Alert
                    size="large"
                    variant="gray"
                    icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
                    message={t("requests.category.stautsInactiveDescription")}
                  />
                </div>
              )}
              <div className="group">
                <p className="label">{t("common.buildings")}</p>
                <div className="tags">
                  {selectedBuildings?.map((building) => (
                    <Tag key={building?.uuid}>{building?.name}</Tag>
                  ))}
                </div>
              </div>
              <div className="group">
                <p className="label">{t("requests.category.categoryTypeLabel")}</p>
                <p>{typeValue}</p>
              </div>
              {canManageRegion && ownerCompanyName !== "" && (
                <div className="group">
                  <p className="label">{t("requests.category.owner")}</p>
                  <p>{ownerCompanyName}</p>
                </div>
              )}
              {category?.queue != null && category.queue.name !== "" && (
                <div className="group">
                  <p className="label">{t("requests.category.queue")}</p>
                  <p>{category.queue.name}</p>
                </div>
              )}
            </div>
            <style jsx>{`
              .group {
                margin: 0 0 24px 0;
              }
              .category-details :global(.tag) {
                padding: 4px 8px;
              }
              .category-details :global(.typography--label) {
                font-size: 12px;
                line-height: 16px;
              }
              p {
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                margin: 0;
              }
              .tags > :global(*) {
                margin-right: 4px;
                margin-bottom: 4px;
              }
              .label {
                text-transform: uppercase;
                font-size: 12px;
                line-height: 16px;
                color: ${colors.grayscale[60]};
                font-weight: 500;
                margin-bottom: 4px;
              }
              .section {
                margin-bottom: 24px;
                display: flex;
                align-items: center;
              }
              .title {
                font-size: 18px;
                line-height: 24px;
                font-weight: 700;
                margin-right: 8px;
                display: inline-block;
              }
            `}</style>
          </>
        );
    }
  }, [
    selectedTab,
    buildings,
    values,
    typeValue,
    ownerCompanyName,
    canManageRegion,
    t,
    subCategories,
    selectedBuildings,
  ]);

  useEffect(() => {
    setSelectedTab(tabs[0].key);
  }, []);

  useEffect(() => {
    if (modal.id != null) {
      setSelectedCategoryUuid(modal.id);
    }
  }, [modal, setSelectedCategoryUuid]);

  useEffect(() => {
    if (canManageRegion) {
      companiesQuery().catch((e) => {
        console.error(e);
      });
    }
  }, [companiesQuery, canManageRegion]);

  return (
    <>
      <div className="px-6 mb-6 mobile-header">
        <Tabs
          items={tabs}
          selected={selectedTab}
          onSelect={(key) => {
            setSelectedTab(key as CategoryTabKey);
          }}
        />
      </div>
      <Modal.Body>{currentTab}</Modal.Body>
      <Modal.Footer>
        <div className="primary-actions">
          <Button
            variant="danger"
            disabled={category?.uuid == null}
            onClick={withConfirmation({
              onConfirm: () => {
                deleteCategoryMutation({ variables: { uuid: category?.uuid ?? "" } })
                  .then(() => {
                    toast.neutral(t("requests.category.deleteCategorySuccess", { categoryName: category?.name ?? "" }));
                    modal.close();
                  })
                  .catch(showError);
              },
              title: t("common.deleteConfirmationTitleWithTitle", { title: category?.name as string }),
              message: t("common.deleteConfirmationWithTitle", { title: category?.name as string }),
              confirmButtonText: t("common.yesDelete"),
              confirmButtonVariant: "danger",
            })}
          >
            {t("common.delete")}
          </Button>
          <Button variant="primary" onClick={onClickEdit}>
            {t("common.edit")}
          </Button>
        </div>
      </Modal.Footer>
      <style jsx>{`
        .primary-actions {
          display: flex;
          gap: 8px;
          width: 100%;
        }
        .primary-actions :global(> button) {
          width: 50%;
        }
        .mobile-header {
          border-bottom: 1px solid ${colors.grayscale[10]};
          position: sticky;
          top: 64px;
          z-index: 2;
        }
        .mobile-header ~ :global(.modal-body) {
          margin-top: 0;
        }
      `}</style>
    </>
  );
};
