import React, { useContext, useEffect } from "react";
import { useRouter } from "next/router";

import { useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { useTheme } from "@equiem/react-admin-ui";

import { RequestTabs } from "../../components/RequestTabs";
import { RequestsMenuContext, RequestsMenuProvider } from "../../contexts/RequestsMenuContext";
import { useRequestQuery } from "../../generated/requests-client";
import { RequestsAccessProvider } from "../requests/contexts/RequestsAccessContext";
import { RequestsScopeProvider } from "../requests/contexts/RequestsScopeContext";

import { RequestActivity } from "./components/RequestActivity";
import { RequestDetails } from "./components/RequestDetails";
import { RequestDetailsProvider } from "./contexts/RequestDetailsContext";

const RequestDetailsInternal: React.FC = () => {
  const router = useRouter();
  const { uuid } = router.query as { uuid: string };
  const { data, loading, error, refetch } = useRequestQuery({
    variables: { uuid },
    fetchPolicy: "cache-and-network",
  });
  const theme = useTheme();
  const showError = useShowError();
  const { t } = useTranslation();
  const { addTab } = useContext(RequestsMenuContext);

  useEffect(() => {
    if (data?.reqMgt.request != null) {
      addTab({
        uuid: data.reqMgt.request.uuid,
        reference: data.reqMgt.request.reference,
      });
    }
  }, [data, addTab]);

  if (error != null) {
    showError(error);
  }

  const request = data?.reqMgt.request;

  const title = t("requests.details.requestTitle", { reference: request?.reference ?? "" }) as string;

  return (
    <RequestDetailsProvider>
      <RequestTabs title={title} heading={title} key={uuid}>
        <div className="container">
          <div className="details">
            <RequestDetails loading={loading} request={request} refetchRequest={refetch} />
          </div>
          <div className="activity">
            <RequestActivity loading={loading} request={request} />
          </div>
        </div>
        <style jsx>{`
          .container {
            background: #fff;
            min-height: calc(100vh - 177px);
          }
          .details {
            padding: ${theme.spacers.s6};
            overflow-y: auto;
          }
          .activity {
            height: 100%;
          }

          @media (max-width: ${theme.breakpoints.lg}px) {
            .container {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              min-height: calc(100vh - 113px);
            }
            .activity {
              position: sticky;
              background: #fff;
              border-top: 1px solid ${theme.colors.border};
              bottom: 0;
              left: 0;
              right: 0;
            }
          }

          @media (min-width: ${theme.breakpoints.lg}px) {
            .container {
              display: grid;
              grid-template-columns: 70% 30%;
            }
            .activity {
              border-left: 1px solid ${theme.colors.border};
            }
          }
        `}</style>
      </RequestTabs>
    </RequestDetailsProvider>
  );
};

export const RequestDetailsPage: React.FC = () => {
  return (
    <RequestsMenuProvider>
      <RequestsAccessProvider>
        <RequestsScopeProvider>
          <RequestDetailsInternal />
        </RequestsScopeProvider>
      </RequestsAccessProvider>
    </RequestsMenuProvider>
  );
};
