import React, { useContext, useMemo } from "react";

import { CurrentProfile } from "@equiem/lib";
import type { ReqMgtCategory } from "@equiem/lib/generated/gateway-client";
import { useTranslation } from "@equiem/localisation-eq1";
import { EmptyState, InfiniteScrollTable, Tag, useTheme } from "@equiem/react-admin-ui";

import { ModalContext } from "../../contexts/ModalContext";
import { useCategoryData } from "../hooks/useCategoryData";

interface RowP {
  category: ReqMgtCategory;
  onClick: () => void;
}

const Row: React.FC<RowP> = ({ category, onClick }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { canManageRegion } = useContext(CurrentProfile);

  const subcategories = useMemo(() => {
    const tags = [];
    let i = 0;
    while (i < 3) {
      if (category.subCategories[i] == null) {
        return tags;
      }
      tags.push(
        <Tag key={category.subCategories[i].uuid} className="mr-2 my-1">
          <span>{category.subCategories[i].name}</span>
        </Tag>,
      );
      ++i;
    }
    if (category.subCategories.length > i) {
      tags.push(
        <Tag key={category.subCategories.length} className="mr-2 my-1">
          <span>+{category.subCategories.length - i}</span>
        </Tag>,
      );
    }
    return tags;
  }, [category.subCategories]);

  return (
    <>
      <tr className="request-row" onClick={onClick}>
        <td>{category.name}</td>
        <td>{subcategories}</td>
        <td>
          {category.buildings.map((building) => (
            <Tag key={building?.uuid} className="mr-2 my-1">
              <span>{building?.name}</span>
            </Tag>
          ))}
        </td>
        {canManageRegion && (
          <td>
            <span>{category.ownerCompany?.name}</span>
          </td>
        )}
        <td>
          {category.queue == null ? (
            ""
          ) : category.queue.active ? (
            <Tag variant="positive">{t("requests.category.status.active")}</Tag>
          ) : (
            <Tag variant="negative">{t("requests.category.status.inactive")}</Tag>
          )}
        </td>
      </tr>
      <style jsx>{`
        .request-row {
          cursor: pointer;
        }
        .request-row:hover {
          background: ${colors.grayscale_5};
        }
      `}</style>
    </>
  );
};

export const CategoriesList: React.FC = () => {
  const { t } = useTranslation();
  const colSpan = 5;
  const modal = useContext(ModalContext);
  const { categories, categoriesLoading } = useCategoryData();
  const { canManageRegion } = useContext(CurrentProfile);
  const tableHeaderRows = useMemo(() => {
    const headers = [
      t("requests.list.categoryName"),
      t("requests.list.subcategories"),
      t("requests.list.buildings"),
      t("requests.list.status"),
    ];
    if (canManageRegion) {
      headers.splice(headers.length - 1, 0, t("requests.list.owner"));
    }
    return headers;
  }, [canManageRegion, t]);

  return (
    <div className="requests-list">
      <InfiniteScrollTable
        loading={categoriesLoading}
        hasMoreData={false}
        rowHeaders={tableHeaderRows}
        rowCount={categories.length}
        fetchMore={() => {
          console.log("no more to fetch");
        }}
      >
        {categories.map((category) => (
          <Row
            key={category.uuid}
            category={category as ReqMgtCategory}
            onClick={() => modal.open("CategoryModal", category.uuid)}
          />
        ))}
        {!categoriesLoading && categories.length === 0 && (
          <tr>
            <td colSpan={colSpan} className="text-center">
              <EmptyState />
            </td>
          </tr>
        )}
      </InfiniteScrollTable>
      <style jsx>{`
        .requests-list {
          padding-top: 14px;
        }
      `}</style>
    </div>
  );
};
