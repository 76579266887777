import { Field, useFormikContext } from "formik";
import type { PropsWithChildren } from "react";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { BookingPaymentCreditCard } from "./BookingPaymentCreditCard";
import { BookingPaymentInvoice } from "./BookingPaymentInvoice";
import { BookingPaymentCredits } from "./BookingPaymentCredits";
import { BillingCustomerLabel } from "./BookingPaymentBillingCustomer";
import { BookingChargesContext } from "../../contexts/BookingChargesProvider";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form, Skeleton, useTheme } from "@equiem/react-admin-ui";
import type { BookingFormValue } from "../../models/BookingFormValue";
import { BookingModalInfo } from "../../contexts/BookingModalInfoProvider";
import { BreakWordDiv } from "../../../../lib/BreakWordDiv";
import { BookableResourcePaymentMethod } from "../../../../generated/gateway-client";
import { stringNotEmpty } from "@equiem/lib";

interface P extends PropsWithChildren {
  title: string;
}
const Section: React.FC<P> = ({ children, title }) => (
  <div className="payment pb-5">
    <h4 className="font-weight-bold mb-4">{title}</h4>
    {children}
  </div>
);

export const BookingPayment: React.FC = () => {
  const { booking, resource } = useContext(BookingModalInfo);
  const { t } = useTranslation();
  const { spacers, breakpoints } = useTheme();
  const { loading, charges } = useContext(BookingChargesContext);
  const fm = useFormikContext<BookingFormValue>();
  const theme = useTheme();

  const methods = useMemo(
    () =>
      // Default to invoice when we do proxy booking.
      fm.values.isProxyBooking
        ? [BookableResourcePaymentMethod.Invoice]
        : resource.paymentMethods.filter((method) =>
            // We don't allow credit card in edit booking.
            booking?.uuid != null && method === BookableResourcePaymentMethod.CreditCard ? false : true,
          ),
    [booking?.uuid, fm.values.isProxyBooking, resource.paymentMethods],
  );

  useEffect(() => {
    if (fm.values.isProxyBooking) {
      fm.setFieldValue("paymentMethod", BookableResourcePaymentMethod.Invoice).catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fm.values.isProxyBooking]);

  const paymentMethodLabel = useCallback(
    (method: BookableResourcePaymentMethod) => {
      switch (method) {
        case BookableResourcePaymentMethod.Invoice:
          return t("bookings.operations.payViaInvoice");
        case BookableResourcePaymentMethod.CreditCard:
          return t("bookings.operations.payViaCreditCard");
        case BookableResourcePaymentMethod.Credits:
          return t("bookings.operations.payViaCredits");
        default:
          // dummy fallback in case we add another payment method
          return method as string;
      }
    },
    [t],
  );

  if (loading) {
    return (
      <Section title={t("bookings.operations.selectPayment")}>
        <div>
          <Skeleton.Line width="100%" height="58px" borderRadius={theme.borderRadius} />
          <Skeleton.Line width="100%" height="58px" borderRadius={theme.borderRadius} />
        </div>
        <style jsx>{`
          div {
            display: flex;
            gap: ${theme.spacers.s3};
          }
        `}</style>
      </Section>
    );
  }

  // Just bail as we don't support credit card on update.
  if (booking?.paymentMethod === BookableResourcePaymentMethod.CreditCard) {
    return null;
  }

  if (booking?.billingCustomer != null) {
    return (
      <Section title={t("bookings.operations.billingDetails")}>
        <BillingCustomerLabel billingCustomer={booking.billingCustomer} />
      </Section>
    );
  }

  if (booking?.invoiceContact != null) {
    return (
      <Section title={t("bookings.operations.payment")}>
        <BreakWordDiv>
          <strong>{t("common.name")}:</strong> {booking.invoiceContact.fullName}
        </BreakWordDiv>
        <BreakWordDiv>
          <strong>{t("common.email")}:</strong> {booking.invoiceContact.email}
        </BreakWordDiv>
      </Section>
    );
  }

  if (booking?.creditAccountUuid != null) {
    return (
      <Section title={t("bookings.operations.payment")}>
        <Form.Group
          error={stringNotEmpty(fm.errors.paymentMethod) ? fm.errors.paymentMethod : ""}
          className="booking-payment-method"
        >
          <BookingPaymentCredits booking={booking} />
        </Form.Group>
      </Section>
    );
  }

  if (charges == null || charges.total === 0 || methods.length === 0) {
    return null;
  }

  return (
    <Section title={t("bookings.operations.payment")}>
      {methods.length > 1 && (
        <div className="payment-method pb-5">
          {methods.map((method) => (
            <Field
              key={method}
              name="paymentMethod"
              value={fm.values.paymentMethod === method}
              label={paymentMethodLabel(method)}
              component={Form.EnclosedRadioButton}
              description=""
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.currentTarget.checked) {
                  fm.setFieldValue("paymentMethod", method).catch(console.error);
                }
              }}
            />
          ))}
        </div>
      )}
      <Form.Group
        error={stringNotEmpty(fm.errors.paymentMethod) ? fm.errors.paymentMethod : ""}
        className="booking-payment-method"
      >
        {fm.values.paymentMethod === BookableResourcePaymentMethod.Invoice && (
          <BookingPaymentInvoice
            requireBillingCustomerForInvoices={
              resource.company.bookingSettings?.requireBillingCustomerForInvoices ?? false
            }
          />
        )}
        {fm.values.paymentMethod === BookableResourcePaymentMethod.CreditCard && <BookingPaymentCreditCard />}
        {fm.values.paymentMethod === BookableResourcePaymentMethod.Credits && <BookingPaymentCredits />}
      </Form.Group>
      <style jsx>{`
        .payment-method {
          display: flex;
          flex-wrap: wrap;
          gap: ${spacers.s3};
          flex-direction: ${methods.length > 2 ? "column" : "row"};
        }
        .payment-method :global(label) {
          flex: 1;
        }
        @media (max-width: ${breakpoints.sm}px) {
          .payment-method {
            flex-direction: column;
          }
        }
      `}</style>
    </Section>
  );
};
