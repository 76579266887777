import React, { useContext } from "react";

import type { IFileV2 } from "@equiem/uploader";

import { FileUploader } from "../../../components/FileUploader";
import type { RequestQuery } from "../../../generated/requests-client";
import { RequestDetailsContext } from "../contexts/RequestDetailsContext";

interface Props {
  request?: RequestQuery["reqMgt"]["request"] | null;
}

export const RequestAttachments = ({ request }: Props) => {
  const { update } = useContext(RequestDetailsContext);
  const isRequestManager = request?.viewerRelations.canManage === true;
  const isInternalParticipant = request?.viewerRelations.internalParticipant === true;

  return (
    <FileUploader
      showUploadButton={isInternalParticipant}
      value={request?.attachments ?? []}
      name="attachments"
      accept=".png,.jpg,.jpeg,.pdf"
      showRemove={isRequestManager}
      onRemove={({ key, value }: IFileV2, allItems: IFileV2[]) => {
        update(request!, {
          // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
          deleteAttachments: [{ key: key as string, filename: value?.filename! }],
          persistAttachments: allItems.map((item) => ({
            key: item.key,
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            filename: item.value?.filename!,
          })),
        }).catch(console.error);
      }}
      onChange={({ target: { value } }: { target: { value: IFileV2[] } }) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/strict-boolean-expressions
        if (!value) {
          return;
        }

        update(request!, {
          persistAttachments: value.map((file) => ({
            key: file.key,
            temporaryUuid: file.temporaryUuid,
            filename: file.value?.filename ?? (file as { filename: string }).filename,
          })),
        }).catch(console.error);
      }}
    />
  );
};
