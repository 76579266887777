import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form, Modal, useTheme } from "@equiem/react-admin-ui";
import { Form as FormikForm, Formik, Field } from "formik";
import React from "react";
import { BookableResourceCancellationRateType as RateType } from "../../../../../generated/gateway-client";
import { useCurrency } from "../../../../../hooks/useCurrency";
import type { CancellationRate } from "../../../../../lib/cancellationRate";
import { cancellationPermissionsModalValidationSchema } from "../../../../../lib/validationSchema";

type CancellationPermissionsModalFormValues = {
  paymentRateCancellation: CancellationRate;
};

interface Props {
  showModal: boolean;
  setShowModal: (_state: boolean) => void;
  modalIndex: number | null;
  setModalIndex: (_index: number | null) => void;
  building?: string;
  usedDaysBefore: Array<number | null>;
  paymentRateCancellation?: CancellationRate;
  onAdd: (_paymentRateCancellation: CancellationRate) => void;
  onEdit: (_paymentRateCancellation: CancellationRate, _index: number) => void;
}

export const ResourceCreateAndEditFormCancellationPermissionsModal: React.FC<Props> = ({
  showModal,
  setShowModal,
  modalIndex,
  setModalIndex,
  building,
  usedDaysBefore,
  paymentRateCancellation,
  onAdd,
  onEdit,
}) => {
  const { t } = useTranslation();
  const { breakpoints, colors, spacers } = useTheme(true);
  const { currency } = useCurrency(building);

  return (
    <Modal.Dialog
      title={
        modalIndex != null ? t("bookings.resources.editCancellationRate") : t("bookings.resources.addCancellationRate")
      }
      show={showModal}
      size="xl"
      onHide={() => {
        setShowModal(false);
      }}
      hideOnClick={false}
      hideOnEsc={false}
      supportsMobile={true}
      focusTrapOptions={{ initialFocus: false }}
      centered
    >
      <div className="cancellation-permissions-modal">
        <Modal.Header supportsMobile={true} closeButton={true} noBorder={false} />
        <Formik<CancellationPermissionsModalFormValues>
          initialValues={{
            paymentRateCancellation: paymentRateCancellation ?? {
              type: RateType.FixedRate,
              amount: "0",
              daysBefore: 0,
            },
          }}
          validationSchema={cancellationPermissionsModalValidationSchema(usedDaysBefore, t)}
          validateOnMount={true}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            modalIndex != null
              ? onEdit(values.paymentRateCancellation, modalIndex)
              : onAdd(values.paymentRateCancellation);
            actions.setSubmitting(false);
          }}
        >
          {({ isSubmitting, isValid, submitForm, setFieldValue, touched, values, errors }) => (
            <FormikForm>
              <Modal.Body>
                <Form.Group label={t("bookings.resources.rateType")} error={errors.paymentRateCancellation?.type}>
                  <Field id="type" name="type">
                    {() => (
                      <div className="rate-type-radio">
                        <Form.RadioButton
                          name="type"
                          id="fixedRate"
                          disabled={isSubmitting}
                          label={t("bookings.resources.fixedRate")}
                          checked={values.paymentRateCancellation.type === RateType.FixedRate}
                          onChange={() => {
                            setFieldValue("paymentRateCancellation.type", RateType.FixedRate).catch(console.log);
                          }}
                        />
                        <Form.RadioButton
                          name="type"
                          id="percentage"
                          disabled={isSubmitting}
                          label={t("common.percentage")}
                          checked={values.paymentRateCancellation.type === RateType.Percentage}
                          onChange={() => {
                            setFieldValue("paymentRateCancellation.type", RateType.Percentage).catch(console.log);
                          }}
                        />
                      </div>
                    )}
                  </Field>
                </Form.Group>

                <div className="form-group-container">
                  <Form.Group
                    label={t("common.amount")}
                    required
                    error={
                      touched.paymentRateCancellation?.amount === true
                        ? errors.paymentRateCancellation?.amount
                        : undefined
                    }
                  >
                    <Field
                      name="paymentRateCancellation.amount"
                      as={values.paymentRateCancellation.type === RateType.FixedRate ? Form.Money : Form.Percentage}
                      currency={values.paymentRateCancellation.type === RateType.FixedRate ? currency : null}
                    />
                  </Form.Group>
                  <Form.Group
                    label={t("bookings.resources.daysBefore")}
                    required
                    error={
                      touched.paymentRateCancellation?.daysBefore === true
                        ? errors.paymentRateCancellation?.daysBefore
                        : undefined
                    }
                  >
                    <Field
                      name="paymentRateCancellation.daysBefore"
                      as={Form.Input}
                      placeholder={t("bookings.resources.daysBeforeHint")}
                      type="number"
                      min={0}
                      max={999}
                    />
                  </Form.Group>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="ghost"
                  type="button"
                  onClick={() => {
                    setShowModal(false);
                    setModalIndex(null);
                  }}
                >
                  {t("common.cancel")}
                </Button>
                <Button
                  className="add-button"
                  type="submit"
                  variant="primary"
                  onSubmit={() => {
                    void submitForm();
                  }}
                  disabled={isSubmitting || !isValid}
                >
                  {modalIndex != null ? t("common.save") : t("common.add")}
                </Button>
              </Modal.Footer>
            </FormikForm>
          )}
        </Formik>
      </div>
      <style jsx>
        {`
          .cancellation-permissions-modal :global(.header) {
            padding: ${spacers.s6} !important;
          }
          .cancellation-permissions-modal :global(.header .title-row) {
            margin: ${spacers.s0} !important;
          }
          .cancellation-permissions-modal :global(.header .title) {
            color: ${colors.dark};
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
            text-transform: none;
            text-align: left;
            z-index: 1;
          }
          .cancellation-permissions-modal :global(.modal-body) {
            display: flex;
            flex-direction: column;
            padding: ${spacers.s7} ${spacers.s6};
            margin: ${spacers.s0};
            gap: ${spacers.s7};
            overflow-y: auto;
            max-height: 400px;
          }
          .cancellation-permissions-modal :global(.modal-body hr),
          .cancellation-permissions-modal :global(.modal-body .form-group) {
            margin: ${spacers.s0};
          }
          .cancellation-permissions-modal :global(.modal-body .rate-type-radio) {
            display: flex;
            gap: ${spacers.s5};
          }
          .cancellation-permissions-modal :global(.modal-body .form-group-container) {
            display: flex;
            gap: ${spacers.s7};
          }
          .cancellation-permissions-modal :global(.modal-body .form-group .form-header label) {
            line-height: 14px;
          }
          .cancellation-permissions-modal :global(.footer) {
            padding: ${spacers.s5} ${spacers.s7} !important;
            gap: ${spacers.s3};
            border-top: 1px solid ${colors.grayscale[10]};
          }
          .cancellation-permissions-modal :global(.footer .add-button) {
            width: 200px;
          }
          @media screen and (max-width: ${breakpoints.md}px) {
            .cancellation-permissions-modal :global(.header .title) {
              font-size: 20px;
            }
            .cancellation-permissions-modal :global(.header button) {
              z-index: 2;
            }
            .cancellation-permissions-modal :global(.modal-body) {
              padding: ${spacers.s5};
              gap: ${spacers.s5};
            }
            .cancellation-permissions-modal :global(.modal-body .form-group-container) {
              flex-direction: column;
              gap: ${spacers.s5};
            }
            .cancellation-permissions-modal :global(.footer button) {
              flex: 1;
            }
          }
        `}
      </style>
    </Modal.Dialog>
  );
};
