import React, { useState } from "react";
import { Modal, Form, Button, useToast } from "@equiem/react-admin-ui";
import { useTranslation, useServerMessageTranslation, useErrorTranslation } from "@equiem/localisation-eq1";
import { stringIsEmpty, stringNotEmpty } from "@equiem/lib";

import { useUpdateResourceBookingApprovalMutation } from "../../../generated/gateway-client";

interface Props {
  /** minimal set of required fields, for reuse */
  booking: { uuid: string; reference: string };
  showModal: boolean;
  onHide: () => void;
}

export const OperationsApproveBookingModal: React.FC<Props> = ({ booking, showModal, onHide }) => {
  const { t } = useTranslation();
  const { tServer } = useServerMessageTranslation();
  const { tError } = useErrorTranslation();
  const toast = useToast();
  const [mutation, { loading }] = useUpdateResourceBookingApprovalMutation();

  const [approved, setApproved] = useState(true);
  const [reason, setReason] = useState("");

  const submit = async () => {
    try {
      const result = await mutation({
        variables: {
          uuid: booking.uuid,
          input: {
            approved,
            reason: stringNotEmpty(reason) ? reason.trim() : null,
          },
        },
      });

      if (result.data?.updateResourceBookingApproval.__typename === "BookingSuccessResponse") {
        toast.neutral(
          approved
            ? t("bookings.operations.bookingApprovalSuccess", { bookingReference: booking.reference })
            : t("bookings.operations.bookingDeclinedSuccess", { bookingReference: booking.reference }),
        );
      } else if (result.data?.updateResourceBookingApproval.__typename === "BookingFailureResponse") {
        toast.negative(tServer(result.data.updateResourceBookingApproval.localisedReason));
      } else {
        console.error(result);
        throw new Error("Failed to update booking approval");
      }
    } catch (e: unknown) {
      console.error(e);
      toast.negative(tError(e));
    } finally {
      onHide();
    }
  };

  return (
    <>
      <Modal.Dialog
        title={t("bookings.operations.approveOrDecline")}
        show={showModal}
        onHide={onHide}
        hideOnEsc={true}
        centered={true}
        size="sm"
      >
        <Modal.Header closeButton={false} noBorder={true} />
        <Modal.Body>
          <div className="my-4">
            {t("bookings.operations.approveOrDeclineMessage", { bookingReference: booking.reference })}
          </div>
          <div className="mt-6 controls">
            <Form.Group label={t("bookings.operations.approveOrDeclineAction")}>
              <label className="d-flex mb-2">
                <input
                  className="ml-0 mr-3"
                  type="radio"
                  id="approveOrDecline_approve"
                  name="approveOrDecline"
                  checked={approved}
                  onClick={() => setApproved(true)}
                />
                <span>{t("bookings.operations.approveThisBooking")}</span>
              </label>
              <label className="d-flex">
                <input
                  className="ml-0 mr-3"
                  type="radio"
                  name="approveOrDecline"
                  id="approveOrDecline_decline"
                  checked={!approved}
                  onClick={() => setApproved(false)}
                />
                <span>{t("bookings.operations.declineThisBooking")}</span>
              </label>
            </Form.Group>
            {!approved && (
              <Form.Group
                label={t("bookings.operations.declineBookingReason")}
                required
                showTooltip
                tooltipText={t("bookings.operations.declineBookingReasonTooltip")}
              >
                <Form.Input
                  placeholder={t("bookings.operations.declineBookingReasonPlaceholder")}
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </Form.Group>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="footer">
            <Button className="mr-2" size="md" variant="ghost" onClick={onHide}>
              {t("bookings.operations.noTakeMeBack")}
            </Button>
            <Button
              size="md"
              variant={approved ? "primary" : "danger"}
              disabled={(!approved && stringIsEmpty(reason)) || loading}
              onClick={() => {
                void submit();
              }}
            >
              {approved ? t("bookings.operations.approveThisBooking") : t("bookings.operations.declineThisBooking")}
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Dialog>
      <style jsx>{`
        .line {
          display: inline-block;
          height: 1.5em;
          width: 50%;
        }
      `}</style>
    </>
  );
};
