import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { AdvancedButton, Form, ProgressCircle, Text } from "@equiem/react-admin-ui";
import { RiSearchLine } from "@equiem/react-admin-ui/icons";

import { EmptyResult } from "../components/EmptyResult";
import { CreateRequestContext } from "../CreateRequestContext";
import { useBuildingData } from "../hooks/useBuildingData";

export const SpaceStep: React.FC = () => {
  const { step, navigationDirection, nextStep, setValues } = useContext(CreateRequestContext);
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const { spaces, loading, searchQuery } = useBuildingData();

  useEffect(() => {
    if (searchQuery.length === 0 && spaces.length === 1 && navigationDirection === "forward") {
      setValues((prev) => ({ ...prev, spaceUuid: spaces[0].uuid }));
      nextStep();
    }
  }, [spaces, navigationDirection, searchQuery, nextStep, setValues]);

  const handleSelect = (uuid: string) => () => {
    setValues((prev) => ({ ...prev, spaceUuid: uuid }));
    nextStep();
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleClear = () => {
    setSearch("");
  };

  return (
    <div className="container">
      <Text variant="label">{t("common.step", { step })}</Text>
      <Text variant="heading" size="small">
        {t("requests.create.selectSpace")}
      </Text>
      <div className="search-container">
        <Form.InputExtended
          className="search-input"
          icon={RiSearchLine}
          placeholder={`${t("common.search")}...`}
          variant="sm"
          value={search}
          onChange={handleSearch}
          onClear={handleClear}
          clearable
        />
      </div>
      <div className="list">
        {loading && <ProgressCircle size="md" className="w-100 d-flex justify-content-center mt-5" />}
        {!loading && spaces.length === 0 && <EmptyResult query={search} />}
        {!loading &&
          spaces.map((space) => (
            <AdvancedButton title={space.name} className="mx-0" key={space.uuid} onClick={handleSelect(space.uuid)} />
          ))}
      </div>
      <style jsx>
        {`
          .container {
            display: flex;
            flex-direction: column;
          }

          .search-container {
            margin-top: 24px;
          }

          .list {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 8px;
            margin-top: 8px;
          }
        `}
      </style>
    </div>
  );
};
