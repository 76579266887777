import type { FormikErrors } from "formik";
import { DateTime } from "luxon";
import type { IImage } from "@equiem/uploader";
import { stringIsEmpty, stringNotEmpty } from "@equiem/lib";
import { durationString } from "@equiem/react-admin-ui";

import type {
  BookableResourceAddOnFragmentFragment,
  BookableResourceAvailability,
  BookableResourceFlexibleAvailability,
  BookableResourceSlotsAvailability,
  BookableResourceSiteAudienceInput,
  CreateResourceMutationVariables,
} from "../generated/gateway-client";
import { BookableResourceAddOnType, BookableResourcePaymentMethod } from "../generated/gateway-client";
import { convertInputNumberToNumber } from "./convertNumberStringToNumber";
import { convertInputMoneyToNumber } from "./convertInputMoneyToNumber";
import type { CancellationRate } from "./cancellationRate";
import { validateCancellationRates } from "./cancellationRate";
import type { TFunction } from "@equiem/localisation-eq1";
import type { IVideo } from "../components/VideoLinkInputNew";
import type { BookingsSite } from "../pages/resources/hooks/useAuthorizedDestinationDetails";

export enum AvailabilityType {
  slots = "SLOTS",
  flexible = "FLEXIBLE",
}

export type FormValues = Omit<
  CreateResourceMutationVariables["input"],
  | "typeV2"
  | "images"
  | "lat"
  | "lon"
  | "location"
  | "name"
  | "displayCapacity"
  | "flexibleAvailability"
  | "slotsAvailability"
  | "editBookingNoticePeriodInMinutes"
  | "children"
  | "addOns"
  | "siteAudiences"
  | "paymentRateFullDay"
  | "paymentRateHalfDay"
  | "paymentRateHourly"
  | "paymentRateHourlyAfterHours"
  | "paymentRateHourlyWeekend"
  | "paymentRateCancellation"
  | "businessHoursHalfDayDurationMinutes"
  | "video"
  | "ownerCompanyUuid"
  | "allowRecurringBooking"
  | "buildingUuid"
> & {
  typeV2: string | null;
  addOns: BookableResourceAddOnFragmentFragment[];
  availability: BookableResourceAvailability[];
  images: IImage[];
  siteName: string;
  building: string;
  buildingName: string;
  buildingAddress: string;
  level?: string;
  levelName?: string;
  start?: string;
  end?: string;
  displayCapacity?: number | string | null;
  editBookingNoticePeriodInMinutes?: number | string | null;
  title: string;
  availabilityType: AvailabilityType;
  steps: number;
  children: string[];
  parentNames: string[];
  siteAudiences: Array<BookableResourceSiteAudienceInput & { siteName: string }>;
  paymentRateFullDay?: number | string | null;
  paymentRateHalfDay?: number | string | null;
  paymentRateHourly?: number | string | null;
  paymentRateHourlyAfterHours?: number | string | null;
  paymentRateHourlyWeekend?: number | string | null;
  paymentRateCancellation: CancellationRate[];
  businessHoursHalfDayDuration?: string | null;
  video?: string | IVideo | null;
  ownerCompanyUuid: string;
  allowRecurringBooking?: boolean;
  ownerCompanyName: string;
};

type ErrorObject<T> = { [Property in keyof T]?: string };
export type SlotsAvailabilityError = ErrorObject<BookableResourceSlotsAvailability>;
export type FlexibleAvailabilityError = ErrorObject<BookableResourceFlexibleAvailability>;
export type ResourceAddOnError = ErrorObject<Partial<BookableResourceAddOnFragmentFragment>>;

export const DATE_FORMAT = "yyyy-MM-dd";

export const hasAnyBaseRates = (values: FormValues): boolean => {
  return (
    convertInputMoneyToNumber(values.paymentRateFullDay, true) != null ||
    convertInputMoneyToNumber(values.paymentRateHalfDay, true) != null ||
    convertInputMoneyToNumber(values.paymentRateHourly, true) != null ||
    convertInputMoneyToNumber(values.paymentRateHourlyAfterHours, true) != null ||
    convertInputMoneyToNumber(values.paymentRateHourlyWeekend, true) != null
  );
};

export const segmentationHasRates = (values: FormValues): boolean => {
  return values.siteAudiences.some((audience) => {
    return (
      convertInputMoneyToNumber(audience.paymentRateFullDay, true) != null ||
      convertInputMoneyToNumber(audience.paymentRateHalfDay, true) != null ||
      convertInputMoneyToNumber(audience.paymentRateHourly, true) != null ||
      convertInputMoneyToNumber(audience.paymentRateHourlyAfterHours, true) != null ||
      convertInputMoneyToNumber(audience.paymentRateHourlyWeekend, true) != null
    );
  });
};

export const addOnsHasPrice = (values: FormValues): boolean => {
  return values.addOns.some((addOn) => {
    return addOn.options == null || addOn.options.length === 0
      ? false
      : addOn.options.some((option) => option.unitPrice > 0);
  });
};

const validatePaymentMethods = (values: FormValues, creditCardAvailable: boolean, t: TFunction): string | null => {
  if (
    (hasAnyBaseRates(values) || segmentationHasRates(values) || addOnsHasPrice(values)) &&
    (values.paymentMethods == null || values.paymentMethods.length === 0)
  ) {
    return t("bookings.lib.selectDefaultPaymentMethod");
  }

  if (segmentationHasRates(values) && !hasAnyBaseRates(values)) {
    return t("bookings.lib.pleaseConfigureRates");
  }

  const hasCreditCard = (values.paymentMethods ?? []).includes(BookableResourcePaymentMethod.CreditCard);
  if (hasCreditCard && !creditCardAvailable) {
    return t("bookings.lib.connectStripeAccount");
  }

  return null;
};

const validateAudience = (
  values: BookableResourceSiteAudienceInput,
  creditCardAvailable: boolean,
  t: TFunction,
): FormikErrors<BookableResourceSiteAudienceInput> => {
  const errors: FormikErrors<BookableResourceSiteAudienceInput> = {};

  const hasCreditCard = (values.paymentMethods ?? []).includes(BookableResourcePaymentMethod.CreditCard);
  if (hasCreditCard && !creditCardAvailable) {
    errors.paymentMethods = t("bookings.lib.connectStripeAccount");
  }

  return errors;
};

// eslint-disable-next-line complexity
export function formValidation(
  values: FormValues,
  creditCardAvailable: boolean,
  ownerCompanyLocations: BookingsSite[],
  t: TFunction,
): FormikErrors<FormValues> {
  const start = stringNotEmpty(values.start) ? DateTime.fromFormat(values.start, DATE_FORMAT) : null;
  const end = stringNotEmpty(values.end) ? DateTime.fromFormat(values.end, DATE_FORMAT) : null;
  const errors: FormikErrors<FormValues> = {};

  const site = ownerCompanyLocations.find((s) => s.uuid === values.site);
  const building = site?.buildings.find((b) => b.uuid === values.building);
  const level = building?.buildingLevels.find((bl) => bl.uuid === values.level);
  if (building == null) {
    errors.building = t("bookings.resources.companyLocationConfigError");
  } else if (stringNotEmpty(values.level) && level == null) {
    errors.level = t("bookings.resources.companyLocationConfigError");
  }

  if (stringIsEmpty(values.typeV2)) {
    errors.typeV2 = t("bookings.lib.pleaseSelectResourceType");
  }

  if (start == null && end != null) {
    errors.start = t("bookings.lib.pleaseSetStartDate");
  }

  if (start != null && end != null && start > end) {
    errors.start = t("bookings.lib.endDateBeforeStart");
  }

  const clean = convertInputNumberToNumber(values.displayCapacity);
  if (clean != null && clean < 0) {
    errors.displayCapacity = t("bookings.lib.valueGreaterThan");
  }

  const addOnsErrors = values.addOns.flatMap((addOn) => {
    const errs: ResourceAddOnError = {};

    if (addOn.type !== BookableResourceAddOnType.SingleOption && !stringNotEmpty(addOn.name)) {
      errs.name = t("bookings.lib.addOnTitleRequired");
    }

    if (addOn.type !== BookableResourceAddOnType.FreeText) {
      const options = addOn.options ?? [];
      const hasEmptyTxt =
        options.some((option) => {
          return !stringNotEmpty(option.name);
        }) || options.length === 0;
      if (hasEmptyTxt) {
        errs.options =
          addOn.type === BookableResourceAddOnType.SingleOption
            ? t("bookings.lib.addOnNameRequired")
            : t("bookings.lib.pleaseFillAllChoices");
      }
    }

    return Object.keys(errs).length > 0 ? [errs] : [{}];
  });
  const hasAddOnsError = addOnsErrors.some((e) => Object.keys(e).length > 0);
  if (hasAddOnsError) {
    errors.addOns = addOnsErrors;
  }

  const availabilityErrors = values.availability.flatMap((availability) => {
    if (values.availabilityType === AvailabilityType.slots) {
      const slots = availability as BookableResourceSlotsAvailability;
      const errs: SlotsAvailabilityError = {};

      if (slots.days.length === 0) {
        errs.days = t("bookings.lib.requiredMinimumWeek");
      }

      const startLx = DateTime.fromFormat(slots.start, "kk:mm");
      const endLx = DateTime.fromFormat(slots.end, "kk:mm");
      if (startLx >= endLx) {
        errs.start = t("bookings.lib.startBeforeEndTime");
      }

      const durationInMinutes = convertInputNumberToNumber(slots.durationInMinutes);
      if (slots.isFullSession != null && !slots.isFullSession && durationInMinutes == null) {
        errs.durationInMinutes = t("bookings.lib.pleaseSpecifyDuration");
      }

      return Object.keys(errs).length > 0 ? [errs] : [{}];
    }

    const flexible = availability as BookableResourceFlexibleAvailability;
    const errs: FlexibleAvailabilityError = {};

    if (flexible.days.length === 0) {
      errs.days = t("bookings.lib.requiredMinimumWeek");
    }

    const startLx = DateTime.fromFormat(flexible.start, "kk:mm");
    const endLx = DateTime.fromFormat(flexible.end, "kk:mm");
    if (startLx >= endLx) {
      errs.start = t("bookings.lib.startBeforeEndTime");
    }

    const minTime = convertInputNumberToNumber(flexible.minTimeInMinutes);
    const maxTime = convertInputNumberToNumber(flexible.maxTimeInMinutes);
    if (minTime != null && maxTime != null && maxTime <= minTime) {
      errs.minTimeInMinutes = t("bookings.lib.minimumTimeMustBeLess");
    }

    return Object.keys(errs).length > 0 ? [errs] : [{}];
  });

  const hasAvailabilityError = availabilityErrors.some((e) => Object.keys(e).length > 0);
  if (hasAvailabilityError) {
    errors.availability = availabilityErrors;
  }

  if (
    values.bookingWindowMinInMinutes != null &&
    values.bookingWindowMinInMinutes > 0 &&
    values.bookingWindowMaxInMinutes != null &&
    values.bookingWindowMaxInMinutes > 0 &&
    values.bookingWindowMaxInMinutes < values.bookingWindowMinInMinutes
  ) {
    errors.bookingWindowMaxInMinutes = t("bookings.lib.bookingWindowRangeError");
  }

  const businessHoursStart = DateTime.fromFormat(values.businessHoursStart ?? "", "HH:mm");
  const businessHoursEnd = DateTime.fromFormat(values.businessHoursEnd ?? "", "HH:mm");
  if (businessHoursStart >= businessHoursEnd) {
    errors.businessHoursEnd = t("bookings.lib.mustBeAfter");
  }
  if (
    stringNotEmpty(values.businessHoursHalfDayDuration) &&
    !durationString.isValid(values.businessHoursHalfDayDuration)
  ) {
    errors.businessHoursHalfDayDuration = t("bookings.lib.validDuration");
  }

  const paymentMethodsError = validatePaymentMethods(values, creditCardAvailable, t);
  if (paymentMethodsError != null) {
    errors.paymentMethods = paymentMethodsError;
  }

  const cancellationRateErrors = validateCancellationRates(values.paymentRateCancellation);
  if (cancellationRateErrors != null) {
    errors.paymentRateCancellation = cancellationRateErrors;
  }

  const audienceErrors = values.siteAudiences.map((audience) => validateAudience(audience, creditCardAvailable, t));
  if (audienceErrors.some((err) => Object.keys(err).length > 0)) {
    errors.siteAudiences = audienceErrors;
  }

  const isManuallyApproved = !(values.autoApproveBookings ?? true);
  const paymentMethods = [
    ...(values.paymentMethods ?? []),
    ...values.siteAudiences.flatMap((audience) => audience.paymentMethods ?? []),
  ];
  if (isManuallyApproved) {
    const usesCreditCard = paymentMethods.includes(BookableResourcePaymentMethod.CreditCard);
    const usesCredits = paymentMethods.includes(BookableResourcePaymentMethod.Credits);
    if (usesCreditCard && usesCredits) {
      errors.autoApproveBookings = t("bookings.resources.bookingApprovalsErrorCreditsAndCreditCard");
    } else if (usesCreditCard) {
      errors.autoApproveBookings = t("bookings.resources.bookingApprovalsError");
    } else if (usesCredits) {
      errors.autoApproveBookings = t("bookings.resources.bookingApprovalsErrorCredits");
    }
  }

  return errors;
}
