import React, { useContext, useMemo, useState } from "react";

import { stringNotEmpty, useShowError } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Form, Skeleton, useTheme } from "@equiem/react-admin-ui";

import { AssigneeTag } from "../../../components/AssigneeTag";
import { ProfileTag } from "../../../components/ProfileTag";
import type { RequestQuery } from "../../../generated/requests-client";
import { useRequestAssigneesQuery } from "../../../generated/requests-client";
import { RequestDetailsContext } from "../contexts/RequestDetailsContext";

interface P {
  request: RequestQuery["reqMgt"]["request"];
}
export const RequestAssigneeEdit: React.FC<P> = ({ request }) => {
  const showError = useShowError();
  const theme = useTheme();
  const { t } = useTranslation();
  const [selectedAssignee, setSelectedAssignee] = useState(request.assignee?.uuid);
  const buildingUuid = request.space?.buildingLevel.building.uuid ?? "nil";
  const { data, loading, error } = useRequestAssigneesQuery({
    fetchPolicy: "network-only",
    variables: { buildingUuid },
    skip: buildingUuid === "nil",
  });
  const { loading: updateLoading, update } = useContext(RequestDetailsContext);

  if (error != null) {
    showError(error);
  }

  const options = useMemo(() => {
    const lists = (data?.reqMgt.assignees ?? []).flatMap((assignee) => {
      const params = {
        profileIconUrl: assignee.avatar ?? undefined,
        companyName: assignee.companyV2?.name ?? "",
        email: assignee.email,
        firstName: assignee.firstName ?? "",
        lastName: assignee.lastName ?? "",
      };

      return [
        {
          label: <ProfileTag {...params} />,
          facadeLabel: <ProfileTag {...params} hideDetails />,
          searchKeywords: [`${params.firstName} ${params.lastName}`, params.email],
          value: assignee.uuid,
        },
      ];
    });

    if (request.assignee == null) {
      return lists;
    }

    const params = {
      profileIconUrl: request.assignee.avatar ?? undefined,
      companyName: request.assignee.companyV2?.name ?? "",
      email: request.assignee.email,
      firstName: request.assignee.firstName ?? "",
      lastName: request.assignee.lastName ?? "",
    };

    return [
      {
        label: <ProfileTag {...params} />,
        facadeLabel: <ProfileTag {...params} hideDetails />,
        searchKeywords: [`${params.firstName} ${params.lastName}`, params.email],
        value: request.assignee.uuid,
      },
    ].concat(lists.filter((list) => list.value !== request.assignee?.uuid));
  }, [data?.reqMgt.assignees, request.assignee]);

  if (loading) {
    return <Skeleton.Line width="250px" height="50px" />;
  }

  return (
    <>
      <div>
        <Form.DynamicSelect
          mobileView="minimal"
          size="sm"
          search
          className="assignee"
          searchPlaceholder={t("common.findAPerson")}
          disabled={updateLoading}
          showSelectedOnList
          name="rassignee"
          showChrome="onInteraction"
          value={request.assignee?.uuid ?? ""}
          noneLabel={<AssigneeTag />}
          options={options}
          onChange={(event) => {
            const uuid = event.target.value;
            if (stringNotEmpty(uuid) && uuid !== selectedAssignee) {
              // Update function already handle the nice catch.
              update(request, { assignee: uuid })
                .then(() => {
                  setSelectedAssignee(uuid);
                })
                .catch(console.log);
            }
          }}
        />
      </div>
      <style jsx>{`
        div {
          margin-left: -${theme.spacers.s3};
        }
      `}</style>
    </>
  );
};

export const RequestAssignee: React.FC<P> = ({ request }) => {
  return request.viewerRelations.canManage ? (
    <RequestAssigneeEdit request={request} />
  ) : (
    <div className="py-3">
      {request.assignee != null ? (
        <ProfileTag
          profileIconUrl={request.assignee.avatar ?? undefined}
          companyName={request.assignee.companyV2?.name ?? ""}
          email={request.assignee.email}
          firstName={request.assignee.firstName ?? ""}
          lastName={request.assignee.lastName ?? ""}
          hideDetails
        />
      ) : (
        <AssigneeTag />
      )}
    </div>
  );
};
