import { useContext, useMemo } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import type { FilterItem } from "@equiem/react-admin-ui";
import { FilterOptionsModifier } from "@equiem/react-admin-ui";
import { RiBuilding4Line } from "@equiem/react-admin-ui/icons";

import { RequestsAccessContext } from "../contexts/RequestsAccessContext";
import { RequestsScopeContext } from "../contexts/RequestsScopeContext";

interface Building {
  uuid: string;
  name: string;
}
export function useRequestFilterBuilding(buildings?: Building[]) {
  const { t } = useTranslation();
  const access = useContext(RequestsAccessContext);
  const { currentScope } = useContext(RequestsScopeContext);

  const options = useMemo(
    () =>
      (buildings ?? [])
        .map((building) => ({
          label: building.name,
          value: building.uuid,
        }))
        .filter((building) =>
          (access.requestManager || access.requestAssignee) && currentScope === "all"
            ? access.buildings.find((b) => b.uuid === building.value) != null
            : true,
        )
        .sort((a, b) => a.label.localeCompare(b.label)),
    [buildings, currentScope],
  );

  const filter: FilterItem = {
    title: t("common.building"),
    type: "options",
    options,
    icon: RiBuilding4Line,
    modifiers: [FilterOptionsModifier.includes],
  };

  return {
    filter: filter.options.length > 0 ? filter : null,
  };
}
