import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { DropdownContainer } from "../../Dropdown/DropdownContainer";
import { DropdownContext } from "../../Dropdown/Dropdown";
import { useInputBorderCss } from "../useInputBorderCss";
import { useInputPadding } from "../useInputPadding";
import { useTheme } from "../../../contexts/Theme";
import { useDropdownChevronBackground } from "../../../hooks/useDropdownChevronBackground";
import * as FormInputGroup from "../FormInputGroup";
import { FormInput } from "../FormInput/FormInput";
import { includes } from "lodash";
import { RiCheckLine, RiCloseCircleLine, RiCloseLine, RiSearchLine } from "react-icons/ri";
import type { DropdownMobileView } from "../../Dropdown/DropdownMenu/DropdownMenu";
import { useTranslation } from "@equiem/localisation-eq1";
import { stringNotEmpty } from "../../../util/stringNotEmpty";
import { useDebounced } from "../../../hooks";
import { ProgressCircle } from "../../ProgressCircle/ProgressCircle";
import { FormFacadeButtonGhost } from "../FormMultiSelect/FormFacadeButtonGhost";

interface ChangeEvent {
  target: Pick<HTMLInputElement, "name"> & Partial<EventTarget & HTMLInputElement>;
}

interface Option {
  group?: { name: string; weight: number };
  value: string;
  label: React.ReactNode;
  // Searching for this option.
  searchKeywords?: string[];
  // if you wish to have different looks on the selected item,
  // if this empty, it will use label instead.
  facadeLabel?: React.ReactNode;
  dropLabel?: React.ReactNode | false;
}

const Item: React.FC<{
  option: Option;
  onChange?: (newValue: string | null) => void;
  selectedValue?: string;
  allowClear: boolean;
}> = ({ option, onChange, selectedValue, allowClear }) => {
  const { setShow } = useContext(DropdownContext);
  const padding = useInputPadding();
  const theme = useTheme();
  const selected = option.value === selectedValue;

  return (
    <>
      <div
        onClick={() => {
          onChange?.(selected ? null : option.value);
          setShow(false);
        }}
        role="option"
        className={`small pl-3 ${selected && "selected"}`}
      >
        {option.dropLabel ?? option.label}
        {selected && (
          <>
            <span className="check">
              <RiCheckLine />
            </span>
            {allowClear && (
              <span className="remove">
                <RiCloseLine />
              </span>
            )}
          </>
        )}
      </div>
      <style jsx>{`
        div {
          cursor: pointer;
          padding-top: ${padding.top};
          padding-bottom: ${padding.bottom};
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        span {
          margin-right: ${theme.spacers.s3};
          font-size: 14px;
          line-height: 14px;
        }
        div.selected {
          background: ${theme.colors.lightHover};
        }
        .check {
          color: ${theme.colors.primary};
          display: inline;
        }
        .remove {
          display: none;
        }
        div:hover {
          background: ${theme.colors.grayscale_5};
        }
        div:hover .check {
          display: ${allowClear ? "none" : "inline"};
        }
        div:hover .remove {
          display: inline;
        }
      `}</style>
    </>
  );
};

interface ItemDisplayProps {
  name: string;
  options: Option[];
  onChange?: (event: ChangeEvent) => void;
  selectedValue?: string;
  allowClear: boolean;
}
const ItemDisplay: React.FC<ItemDisplayProps> = ({ name, options, onChange, selectedValue, allowClear }) => {
  return (
    <>
      {options.map((option, index) => (
        <div key={index} className="item mx-3">
          <Item
            selectedValue={selectedValue}
            option={option}
            onChange={(newValue) => {
              onChange?.({
                target: { name, value: newValue ?? "" },
              });
            }}
            allowClear={allowClear}
          />
        </div>
      ))}
    </>
  );
};

interface GroupDisplayProps {
  name: string;
  filteredOptions: Option[];
  onChange?: (event: ChangeEvent) => void;
  selectedValue?: string;
  allowClear: boolean;
}

const GroupDisplay: React.FC<GroupDisplayProps> = ({ name, filteredOptions, onChange, selectedValue, allowClear }) => {
  const theme = useTheme();
  const allGroups = useMemo(
    () =>
      filteredOptions.reduce<Record<string, Option[]>>((prev, curr) => {
        if (curr.group != null) {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          prev[curr.group.name] = (prev[curr.group.name] ?? []).concat([curr]);
        }

        return prev;
      }, {}),
    [filteredOptions],
  );

  const allKeys = useMemo(
    () =>
      Object.keys(allGroups)
        .map((key) => ({
          name: key,
          weight: filteredOptions.find((option) => option.group?.name === key)?.group?.weight ?? 0,
        }))
        .sort((a, b) => a.weight - b.weight),
    [allGroups, filteredOptions],
  );

  return (
    <>
      {allKeys.map((group, groupIndex) => (
        <div className="group" key={groupIndex}>
          <label>{group.name}</label>
          <ItemDisplay
            selectedValue={selectedValue}
            name={name}
            options={allGroups[group.name]}
            onChange={onChange}
            allowClear={allowClear}
          />
        </div>
      ))}
      <style jsx>{`
        .group {
          border-bottom: 1px solid ${theme.colors.border};
          padding-bottom: ${theme.spacers.s4};
          margin-bottom: ${theme.spacers.s4};
        }
        .group:last-child {
          border: 0;
          margin: 0;
          padding: 0;
        }
        .group label {
          display: block;
          margin: 0 ${theme.spacers.s4} ${theme.spacers.s3};
          padding: 0 ${theme.spacers.s2};
          font-size: 11px;
          text-transform: uppercase;
          font-weight: 500;
        }
      `}</style>
    </>
  );
};

export interface Props {
  name: string;
  value: string;
  className?: string;
  onChange?: (event: ChangeEvent) => void;
  options?: Option[];
  readOnly?: boolean;
  showChrome?: boolean | "onInteraction";
  search?: boolean;
  searchCb?: (keyword: string) => Promise<void>;
  supportsMobile?: boolean;
  size?: "sm" | "md";
  searchPlaceholder?: string;
  mobileView?: DropdownMobileView;
  title?: string;
  disabled?: boolean;
  noneLabel?: React.ReactNode | string;
  // Shows selected option in the option.
  showSelectedOnList?: boolean;
  facade?: "ghost";
  minWidth?: number;
  allowClear?: boolean;
}
// eslint-disable-next-line complexity
export const FormDynamicSelect: React.FC<Props> = ({
  name,
  className = "",
  value,
  options,
  onChange,
  searchCb,
  showChrome = true,
  search = false,
  title,
  size = "md",
  searchPlaceholder,
  mobileView,
  noneLabel,
  disabled = false,
  showSelectedOnList = false,
  facade,
  minWidth = 0,
  allowClear = false,
  readOnly,
}) => {
  const { t } = useTranslation();
  const { colors, borderRadius, spacers } = useTheme();
  const borderCss = useInputBorderCss({ showChrome: readOnly != null ? !readOnly : showChrome });
  const dropdownBg = useDropdownChevronBackground({
    color: colors.grayscale[100],
  });
  const [localValue, setLocalValue] = useState<string>(value);
  const [selected, setSelected] = useState(options?.find((option) => option.value === localValue));
  const [searching, setSearching] = useState(false);

  useEffect(() => {
    setLocalValue(value);
    setSelected(options?.find((option) => option.value === value));
  }, [options, value]);

  const DEFAULT_WIDTH = 300;
  const [width, setWidth] = useState<number | undefined>(DEFAULT_WIDTH);

  const FILTER_DEBOUNCE_MS = 500;
  const [filterText, setFilterText] = useState<string | null>(null);
  const debouncedFilter = useDebounced(filterText, FILTER_DEBOUNCE_MS);

  // Handle the search callback when exist.
  useEffect(() => {
    if (debouncedFilter != null && searchCb != null) {
      searchCb(debouncedFilter).finally(() => {
        setSearching(false);
      });
    }
  }, [debouncedFilter, searchCb]);

  const ref = useRef<HTMLDivElement>(null);

  const adjustWidth = useCallback(() => {
    if (ref.current != null) {
      const newWidth = Math.max(ref.current.getBoundingClientRect().width, minWidth);
      setWidth(newWidth);
    }
  }, [minWidth]);

  useEffect(() => {
    window.addEventListener("resize", adjustWidth);
    adjustWidth();

    return () => window.removeEventListener("resize", adjustWidth);
  }, [adjustWidth]);

  const emptyLabel = noneLabel != null ? <>{noneLabel}</> : null;

  const filteredOptions = useMemo(() => {
    const searchmode = searchCb == null && stringNotEmpty(filterText);
    const searchKeyword = filterText?.toLowerCase();

    return (options ?? []).filter((option) => {
      const optionKeywords =
        option.searchKeywords != null && option.searchKeywords.length > 0
          ? option.searchKeywords
          : [String(option.label)];

      const getFilteredCondition =
        searchmode && stringNotEmpty(searchKeyword)
          ? optionKeywords.some((ok) => includes(ok.toLowerCase(), searchKeyword))
          : true;

      const includeSelected = showSelectedOnList ? true : option.value !== localValue;

      return includeSelected && option.dropLabel !== false && getFilteredCondition;
    });
  }, [options, filterText, searchCb, showSelectedOnList, localValue]);

  const hasGroup = useMemo(() => (options ?? []).some((option) => stringNotEmpty(option.group?.name)), [options]);
  const label = selected?.facadeLabel ?? selected?.label ?? emptyLabel ?? t("common.none");

  return (
    <div ref={ref} className={className}>
      <DropdownContainer
        placement="bottom-start"
        width={width}
        mobileView={mobileView}
        title={title}
        disabled={disabled || readOnly}
        trigger={
          facade === "ghost" ? (
            <FormFacadeButtonGhost placeholder={name} className={className} size={size === "sm" ? "small" : "large"} />
          ) : (
            <div className={`triggerInput small mr-5 ${borderCss.className}`} tabIndex={0}>
              {label}
            </div>
          )
        }
      >
        {search && (
          <FormInputGroup.Group className="mx-3 mt-2 mb-4">
            <FormInputGroup.Prefix>
              {searching ? (
                <ProgressCircle size={15} style={{ color: colors.muted1 }} />
              ) : (
                <RiSearchLine color={colors.muted1} size={15} />
              )}
            </FormInputGroup.Prefix>
            <FormInput
              placeholder={searchPlaceholder ?? (t("common.searchAttributes") as string)}
              onChange={(e) => {
                searchCb != null && setSearching(true);
                setFilterText(e.target.value);
              }}
              className="p-2"
              value={filterText ?? ""}
              autoFocus
            />
            <FormInputGroup.Suffix>
              <RiCloseCircleLine
                style={{
                  display: filterText == null || filterText.length === 0 ? "none" : "block",
                  cursor: "pointer",
                }}
                onClick={() => setFilterText("")}
                color={colors.muted1}
                size={15}
              />
            </FormInputGroup.Suffix>
          </FormInputGroup.Group>
        )}

        {!searching && (
          <>
            {hasGroup ? (
              <GroupDisplay
                selectedValue={localValue}
                filteredOptions={filteredOptions}
                name={name}
                onChange={onChange}
                allowClear={allowClear}
              />
            ) : (
              <ItemDisplay
                selectedValue={localValue}
                options={filteredOptions}
                name={name}
                onChange={onChange}
                allowClear={allowClear}
              />
            )}
          </>
        )}
      </DropdownContainer>
      <style jsx>{`
        label {
          cursor: pointer;
        }
        .item:hover {
          background-color: ${colors.grayscale_5};
          border-radius: ${borderRadius};
        }
        .triggerInput {
          width: 100%;
          height: 100%;
          padding: ${size === "sm" ? spacers.s3 : spacers.s4};
          opacity: ${disabled ? "0.5" : "1"};
          cursor: ${disabled ? "not-allowed" : "pointer"};
          font-size: 0.9rem;
          background-color: ${disabled ? colors.grayscale[5] : colors.white} !important;
          background: ${showChrome === true ? `${dropdownBg} right 11px center/16px 16px no-repeat` : "none"};
        }
        .triggerInput:hover,
        .triggerInput:focus,
        .focus-in {
          background: ${showChrome === true || showChrome === "onInteraction"
            ? `${dropdownBg} right 11px center/16px 16px no-repeat`
            : "none"};
        }
      `}</style>
      {borderCss.styles}
    </div>
  );
};
