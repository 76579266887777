import React, { useContext, useEffect } from "react";

import { useTranslation } from "@equiem/localisation-eq1";
import { AdvancedButton, Form, ProgressCircle, Text } from "@equiem/react-admin-ui";
import { RiLayoutMasonryLine, RiSearchLine } from "@equiem/react-admin-ui/icons";

import { EmptyResult } from "../components/EmptyResult";
import { CreateRequestContext } from "../CreateRequestContext";
import { useBuildingData } from "../hooks/useBuildingData";

export const LevelStep: React.FC = () => {
  const { step, nextStep, setValues, navigationDirection } = useContext(CreateRequestContext);
  const { t } = useTranslation();
  const { levels, loading, searchQuery, setSearchQuery } = useBuildingData();

  useEffect(() => {
    if (searchQuery.length === 0 && levels.length === 1 && navigationDirection === "forward") {
      setValues((prev) => ({ ...prev, levelUuid: levels[0].uuid }));
      nextStep();
    }
  }, [levels, navigationDirection, searchQuery, nextStep, setValues]);

  const handleSelect = (uuid: string) => () => {
    setValues((prev) => ({ ...prev, levelUuid: uuid }));
    nextStep();
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleClear = () => {
    setSearchQuery("");
  };

  return (
    <div className="container">
      <Text variant="label">{t("common.step", { step })}</Text>
      <Text variant="heading" size="small">
        {t("requests.create.selectLevel")}
      </Text>
      <div className="search-container">
        <Form.InputExtended
          className="search-input"
          icon={RiSearchLine}
          placeholder={`${t("requests.create.searchLevel")}...`}
          variant="sm"
          value={searchQuery}
          onChange={handleSearch}
          onClear={handleClear}
          clearable
        />
      </div>
      <div className="list">
        {loading && <ProgressCircle size="md" className="w-100 d-flex justify-content-center mt-5" />}
        {!loading && levels.length === 0 && <EmptyResult query={searchQuery} />}
        {!loading &&
          levels.map((level) => (
            <AdvancedButton
              title={level.name}
              icon={RiLayoutMasonryLine}
              className="mx-0"
              key={level.uuid}
              onClick={handleSelect(level.uuid)}
            />
          ))}
      </div>
      <style jsx>
        {`
          .container {
            display: flex;
            flex-direction: column;
          }

          .search-container {
            margin-top: 24px;
          }

          .list {
            display: grid;
            grid-template-columns: ${levels.length > 0 && !loading ? "1fr 1fr" : "1fr"};
            grid-gap: 8px;
            margin-top: 8px;
          }
        `}
      </style>
    </div>
  );
};
