import type { FC } from "react";
import React, { useContext } from "react";
import type { UpdateFlexTenantLocationsInput } from "../../generated/gateway-client";
import {
  FlexTenantStatus,
  MyFlexTenantsDocument,
  useUpdateFlexTenantLocationsMutation,
  type FlexTenantFragmentFragment,
} from "../../generated/gateway-client";
import type { FormikHelpers } from "formik";
import { Field, Form, Formik } from "formik";
import { Button, Form as EqForm, Modal, ProgressCircle, Text } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";
import { flattenLocations } from "../lib/flattenLocations";
import { LocationSelect } from "../lib/LocationSelect";
import { RiAddLine } from "@equiem/react-admin-ui/icons";
import { FlexTenantLocationContext, emptyLocation } from "../../contexts/FlexTenantLocationContext";
import { SideModalContext } from "../../contexts/SideModalContext";

interface Props {
  flexTenant: FlexTenantFragmentFragment;
  readonly?: boolean;
}

export const EditBuildingsForm: FC<Props> = ({ flexTenant, readonly = false }) => {
  const { t } = useTranslation();
  const [mutation] = useUpdateFlexTenantLocationsMutation();
  const { closeModal } = useContext(SideModalContext);
  const { locations, setLocations, hasChanged } = useContext(FlexTenantLocationContext);

  const handleSubmit = async (
    values: UpdateFlexTenantLocationsInput,
    { setSubmitting }: FormikHelpers<UpdateFlexTenantLocationsInput>,
  ): Promise<boolean> => {
    setSubmitting(true);
    await mutation({
      variables: {
        input: {
          ...values,
          locations: flattenLocations(locations).map((l) => ({
            destination: l.destination.uuid,
            buildingLevels: l.levels.map((bl) => bl.uuid),
          })),
        },
      },
      refetchQueries: [
        {
          query: MyFlexTenantsDocument,
        },
      ],
    });
    setSubmitting(false);
    closeModal();
    return true;
  };

  const initialValues: UpdateFlexTenantLocationsInput = {
    flexTenantUuid: flexTenant.uuid,
    locations: [],
  };

  const canEdit = flexTenant.status !== FlexTenantStatus.Expired && !readonly;

  const hasEmptyLevels = Boolean(locations.find((l) => l.levels.length === 0));

  return (
    <Formik<UpdateFlexTenantLocationsInput> initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting, submitForm, isValid }) => (
        <>
          <Modal.Body>
            <Form>
              <Text variant="heading" size="small" weight="bold" className="mb-2">
                {t("flex.members.form.buildings")}
              </Text>
              <Text variant="text" size="small">
                {t("flex.members.form.locationsHelpText")}
              </Text>
              <>
                {locations.map((_location, i) => (
                  <EqForm.Group key={i} label={t("flex.members.form.location")}>
                    <>
                      <Field
                        placeholder={t("flex.members.form.buildings")}
                        as={LocationSelect}
                        selectedCompanyUuid={flexTenant.company.uuid}
                        locationIndex={i}
                        readOnly={!canEdit}
                      />
                    </>
                  </EqForm.Group>
                ))}
              </>
              {canEdit && (
                <Button
                  variant="secondary"
                  className="w-100"
                  onClick={() => setLocations((prev) => [...prev, emptyLocation])}
                >
                  <RiAddLine /> {t("flex.members.form.addBuildingButton")}
                </Button>
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={closeModal} variant="ghost">
              {t("common.cancel")}
            </Button>
            {canEdit && (
              <Button
                className="ml-2"
                disabled={isSubmitting || !isValid || !hasChanged || hasEmptyLevels}
                onClick={() => {
                  void submitForm();
                }}
              >
                {isSubmitting && <ProgressCircle size="xs" />} {t("common.saveChanges")}
              </Button>
            )}
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
};
