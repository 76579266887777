import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { formatters, useTranslation } from "@equiem/localisation-eq1";
import type { BookingFormValue } from "../../models/BookingFormValue";
import { useCreditAccounts } from "../../hooks/useCreditAccounts";
import type { BookingFragmentFragment } from "../../../../generated/gateway-client";
import { Form } from "@equiem/react-admin-ui";
import { BreakWordDiv } from "../../../../lib/BreakWordDiv";
import { stringNotEmpty } from "@equiem/lib";

const AccountLoading: React.FC = () => {
  return (
    <>
      <div className="w-100 line">
        <div className="label shimmer" />
        <div className="value shimmer" />
      </div>
      <div className="w-100 line">
        <div className="label shimmer" />
        <div className="value shimmer" />
      </div>
      <style jsx>{`
        .line {
          display: grid;
          grid-template-columns: 100px 1fr;
          word-break: break-word;
          margin-bottom: 0.25em;
        }
        .label {
          height: 1.25em;
          width: 70px;
        }
        .value {
          height: 1.5em;
          width: min(250px, 75%);
        }
      `}</style>
    </>
  );
};

export const BookingPaymentCredits: React.FC<{ booking?: BookingFragmentFragment }> = ({ booking }) => {
  const { t, i18n } = useTranslation();
  const fm = useFormikContext<BookingFormValue>();
  const { creditAccounts, loading: creditAccountsLoading } = useCreditAccounts();
  const isUpdate = booking != null;

  // @todo: check whether this can be droped.
  const [shouldTriggerValidation, setShouldTriggerValidation] = useState(false);
  useEffect(() => {
    if (creditAccountsLoading) {
      return;
    }

    if (isUpdate) {
      // this is a booking update - select the booking's credit account if the user still has access
      const bookingAccount = creditAccounts.find((account) => account.uuid === fm.values.creditAccount?.uuid);
      void fm.setFieldValue("creditAccount", bookingAccount).then(() => setShouldTriggerValidation(true));
    } else if (creditAccounts.length === 1 && fm.values.creditAccount == null) {
      // user only has one credit account - select it automatically
      void fm.setFieldValue("creditAccount", creditAccounts[0]).then(() => setShouldTriggerValidation(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate, creditAccounts, creditAccountsLoading]);

  useEffect(() => {
    // if we've auto-selected an account, trigger validation once the charges have also loaded
    if (shouldTriggerValidation && !creditAccountsLoading) {
      void fm.validateForm();
      setShouldTriggerValidation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditAccountsLoading, shouldTriggerValidation]);

  const selectedAccount = creditAccounts.find((account) => account.uuid === fm.values.creditAccount?.uuid);
  const formatBalance = (balance: number) => formatters.currency(balance / 100, i18n.language, { currency: "CREDITS" });

  // credit accounts load with network-and-cache, so only show the loading
  // shimmer if we don't have anything to display
  const showLoading = creditAccountsLoading && creditAccounts.length === 0 && selectedAccount == null;

  return (
    <>
      <Form.Group
        className="booking-checkout-creditaccount"
        label={t("bookings.operations.creditAccount").toUpperCase()}
        error={stringNotEmpty(fm.errors.creditAccount) ? fm.errors.creditAccount : undefined}
      >
        {showLoading && <AccountLoading />}
        {!showLoading && creditAccounts.length === 0 && <div>{t("bookings.operations.noCreditAccountsError")}</div>}
        {!showLoading && creditAccounts.length > 1 && !isUpdate && (
          <Form.Select
            id="creditAccount"
            name="creditAccount"
            className={selectedAccount != null ? "mb-3" : undefined}
            disabled={creditAccountsLoading}
            value={fm.values.creditAccount?.uuid ?? ""}
            onChange={(e) => {
              void fm
                .setFieldValue(
                  "creditAccount",
                  creditAccounts.find((account) => account.uuid === e.currentTarget.value),
                )
                .then(async () => fm.validateForm());
            }}
          >
            <option value="" disabled>
              {t("bookings.operations.selectACreditAccount")}
            </option>
            {creditAccounts.map((account) => (
              <option key={account.uuid} value={account.uuid}>
                {account.name} ({formatBalance(account.balance)})
              </option>
            ))}
          </Form.Select>
        )}
        {!showLoading && selectedAccount != null && (
          <>
            <BreakWordDiv className="pb-2">
              <div className="account-row">
                <strong>{t("common.name")}:</strong> <span>{selectedAccount.name}</span>
              </div>
            </BreakWordDiv>
            <BreakWordDiv className="pb-2">
              <div className="account-row">
                <strong>{t("common.accountBalance")}:</strong> <span>{formatBalance(selectedAccount.balance)}</span>
              </div>
            </BreakWordDiv>
          </>
        )}
      </Form.Group>
      <style jsx>{`
        .account-row {
          display: grid;
          grid-template-columns: 100px 1fr;
          word-break: break-word;
        }
      `}</style>
    </>
  );
};
