import React, { useMemo } from "react";
import { useTranslation } from "@equiem/localisation-eq1";
import { useMyBookingChargesQuery, type BookingFragmentFragment } from "../../../generated/gateway-client";
import { Skeleton, Text, useTheme } from "@equiem/react-admin-ui";
import { flatExistingBookingAddOns } from "../libs/groupExistingBookingAddOns";
import { CurrencyAmount } from "../../../lib/CurrencyAmount";
import { MyBookingChargeAdjustmentAndRefund } from "./MyBookingChargeAdjustmentAndRefund";

interface P {
  booking: BookingFragmentFragment;
  className?: string;
}
export const MyBookingCharges: React.FC<P> = ({ booking, className = "" }) => {
  const { t } = useTranslation();
  const freeBooking = booking.paymentMethod == null;
  const theme = useTheme();
  const { data, loading, error } = useMyBookingChargesQuery({
    fetchPolicy: "network-only",
    variables: { reference: booking.reference },
    skip: freeBooking,
  });
  const info = data?.myBookingByReference;
  const addOns = useMemo(() => flatExistingBookingAddOns(info?.addOns ?? [], t), [info?.addOns, t]);
  const hasDiscounts = (info?.discounts ?? []).length > 0;

  if (booking.paymentMethod == null) {
    return null;
  }

  if (loading) {
    return (
      <div>
        <div className="mb-2">
          <Skeleton.Line width="100px" height="15px" dark />
        </div>
        <Skeleton.Line width="250px" height="20px" dark />
      </div>
    );
  }

  if (error != null) {
    console.log(error);

    return null;
  }
  if (info == null) {
    return null;
  }

  const zeroValueText = t("common.free");

  return (
    <>
      <div className={`booking-charges ${className}`}>
        <Text variant="heading" size="medium" className="pb-4">
          {t("bookings.common.bookingCharges")}
        </Text>
        <div>
          <div className="info">
            <div> {t("bookings.operations.bookingReference", { reference: booking.reference })}</div>
            <CurrencyAmount
              amount={info.resourcePrice}
              currencyCode={info.currencyCode}
              buildingUuid={booking.resource.building?.uuid}
              zeroValueText={zeroValueText}
              isDiscounted={hasDiscounts}
            />
          </div>
          {hasDiscounts && (
            <div className="pl-3">
              {info.discounts.map((d, key) => (
                <div key={key} className="info discount-line-item">
                  {d.title} ({d.percentage}%)
                </div>
              ))}
              <div className="info">
                <div>{t("bookings.discounts.priceWithDiscount")}</div>
                <CurrencyAmount
                  amount={info.resourcePriceWithDiscount}
                  currencyCode={info.currencyCode}
                  buildingUuid={booking.resource.building?.uuid}
                  zeroValueText={zeroValueText}
                />
              </div>
            </div>
          )}
          {info.partialRefunds.map((r) => (
            <MyBookingChargeAdjustmentAndRefund
              key={r.uuid}
              name={t("bookings.operations.refund")}
              amount={
                <CurrencyAmount
                  amount={r.amount}
                  currencyCode={info.currencyCode}
                  buildingUuid={booking.resource.building?.uuid}
                  zeroValueText={zeroValueText}
                />
              }
              description={r.reason}
              className="mb-2"
            />
          ))}
          {addOns.length > 0 && (
            <div>
              {t("bookings.resources.addOns")} ({addOns.length})
              <div className="pl-3 pt-3">
                {addOns.map((ao) => (
                  <React.Fragment key={ao.uuid}>
                    <div className="info">
                      <div>
                        {ao.description} {ao.quantity > 1 ? `(x${ao.quantity})` : null}
                      </div>
                      <CurrencyAmount
                        amount={ao.amount}
                        currencyCode={info.currencyCode}
                        buildingUuid={booking.resource.building?.uuid}
                        zeroValueText={zeroValueText}
                      />
                    </div>
                    {ao.partialRefunds.map((r) => (
                      <MyBookingChargeAdjustmentAndRefund
                        key={r.uuid}
                        name={t("bookings.operations.refund")}
                        amount={
                          <CurrencyAmount
                            amount={r.amount}
                            currencyCode={info.currencyCode}
                            buildingUuid={booking.resource.building?.uuid}
                            zeroValueText={zeroValueText}
                          />
                        }
                        description={r.reason}
                        className="mb-2"
                      />
                    ))}
                  </React.Fragment>
                ))}
              </div>
            </div>
          )}
          {info.adjustments.map((adj) => (
            <>
              <MyBookingChargeAdjustmentAndRefund
                name={t("bookings.operations.additionalCharge")}
                amount={
                  <CurrencyAmount
                    amount={adj.amount}
                    currencyCode={info.currencyCode}
                    buildingUuid={booking.resource.building?.uuid}
                    zeroValueText={zeroValueText}
                  />
                }
                mode="adjustment"
                description={adj.reason}
                className="mb-2"
              />
              {adj.partialRefunds.map((r) => (
                <MyBookingChargeAdjustmentAndRefund
                  key={r.uuid}
                  name={t("bookings.operations.refund")}
                  amount={
                    <CurrencyAmount
                      amount={r.amount}
                      currencyCode={info.currencyCode}
                      buildingUuid={booking.resource.building?.uuid}
                      zeroValueText={zeroValueText}
                    />
                  }
                  description={r.reason}
                  className="mb-2"
                />
              ))}
            </>
          ))}
          {info.taxes.map((tax, i) => (
            <div className="info text-muted" key={i}>
              <div>{tax.description}</div>
              <CurrencyAmount
                amount={tax.price}
                currencyCode={info.currencyCode}
                buildingUuid={booking.resource.building?.uuid}
                zeroValueText={zeroValueText}
              />
            </div>
          ))}
        </div>
        <div className="total mt-auto font-weight-bold">
          <div className="d-flex justify-content-between">
            <div>{t("common.total")}</div>
            <CurrencyAmount
              amount={info.totalPrice}
              currencyCode={info.currencyCode}
              buildingUuid={booking.resource.building?.uuid}
              zeroValueText={zeroValueText}
            />
          </div>
        </div>
      </div>
      <style jsx>{`
        .info {
          margin-bottom: ${theme.spacers.s3};
          display: flex;
          justify-content: space-between;
        }
        .info div {
          word-break: break-word;
        }
        .info div:first-child {
          max-width: 80%;
        }
        .total {
          border-top: 1px solid ${theme.colors.border};
          padding-top: ${theme.spacers.s5};
        }
        .booking-charges {
          display: flex;
          flex-direction: column;
        }
        .cta {
          display: flex;
          padding-top: ${theme.spacers.s5};
          justify-content: space-between;
        }
        .cta :global(button) {
          width: 49%;
        }
        .discount-line-item {
          color: ${theme.colors.status.positive.primary};
        }
      `}</style>
    </>
  );
};
