import type { FlexTenantLocationFragmentFragment } from "../../generated/gateway-client";

interface BuildingLevel {
  uuid: string;
  name: string;
}

interface Site {
  uuid: string;
  name: string;
}

export interface Building {
  uuid: string;
  name: string;
  levels: BuildingLevel[];
  destination: Site;
}
export interface FlexTenantFragment {
  destination: {
    uuid: string;
    name: string;
  };
  building: {
    uuid: string;
    name: string;
  };
  levels: Array<{
    uuid: string;
  }>;
}

export const flattenLocations = (locations: Building[]): FlexTenantFragment[] => {
  // Combine all building levels for the same destination in the array of locations.
  const locationsMap = new Map<string, FlexTenantFragment>();
  locations.forEach((location) => {
    const key = location.destination.uuid;
    const existing = locationsMap.get(key);
    if (existing != null) {
      locationsMap.set(key, {
        ...existing,
        levels: [...existing.levels, ...location.levels],
      });
    } else {
      locationsMap.set(key, {
        destination: {
          uuid: location.destination.uuid,
          name: location.destination.name,
        },
        building: {
          uuid: location.uuid,
          name: location.name,
        },
        levels: location.levels,
      });
    }
  });

  return Array.from(locationsMap.values());
};

export const flattenGraphqlLocations = (locations: FlexTenantLocationFragmentFragment[]): Building[] => {
  // Maps locations to a structure that is easier to work with in the UI.
  const buildingsMap = new Map<string, Building>();
  locations.forEach((location) => {
    const building = buildingsMap.get(location.building.uuid);
    if (building != null) {
      building.levels.push({
        uuid: location.uuid,
        name: location.name,
      });
    } else {
      buildingsMap.set(location.building.uuid, {
        uuid: location.building.uuid,
        name: location.building.name,
        levels: [
          {
            uuid: location.uuid,
            name: location.name,
          },
        ],
        destination: {
          uuid: location.building.destination!.uuid,
          name: location.building.destination!.name,
        },
      });
    }
  });

  return Array.from(buildingsMap.values());
};
