import React, { useContext, useMemo, useState } from "react";

import { CurrentProfile, CurrentRole, Role, useShowError, useSiteContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import {
  Button,
  ColumnView,
  Form,
  handleMultilineTextAreaInput,
  Modal,
  Text,
  Tooltip,
  useConfirmer,
  useIsMobileWidth,
  useToast,
} from "@equiem/react-admin-ui";
import { RiFileUploadLine } from "@equiem/react-admin-ui/icons";

import {
  useAddSpacesMutation,
  useCompaniesV2Query,
  useCreateOrUpdateBuildingMutation,
} from "../../../generated/settings-client";
import { useBuildingData } from "../hooks/useBuildingData";

type Props = {
  type: "spaces" | "levels";
};

export const BulkUploadWidget: React.FC<Props> = ({ type }) => {
  const isMobile = useIsMobileWidth();
  const { t } = useTranslation();
  const toast = useToast();
  const { uuid } = useSiteContext();
  const showError = useShowError();
  const site = useSiteContext();
  const { withConfirmation } = useConfirmer();
  const [showModal, setShowModal] = useState(false);
  const [value, setValue] = useState("");
  const [ownerCompanyUuid, setOwnerCompanyUuid] = useState<string | undefined>(undefined);
  const [items, setItems] = useState<string[]>([]);
  const [isVerification, setIsVerification] = useState(false);
  const { profile, canManageRegion } = useContext(CurrentProfile);
  const { currentRole } = useContext(CurrentRole);
  const isFlexManager = currentRole === Role.FlexManager;
  const { levels, selectedBuilding, spaces, selectedLevelUuid, setSelectedLevelUuid } = useBuildingData();
  const [createOrUpdateBuildingMutation, { loading: levelsLoading }] = useCreateOrUpdateBuildingMutation();
  const [addSpaces, { loading: spacesLoading }] = useAddSpacesMutation();

  const resetModal = () => {
    setValue("");
    setOwnerCompanyUuid(undefined);
    setItems([]);
    setIsVerification(false);
    setShowModal(false);
  };

  const onCloseModal = () => {
    if (items.length > 0 || value.length > 0) {
      withConfirmation({
        title: t("common.areYouSure"),
        message: t("settings.build.cancelMessageBulk"),
        confirmButtonText: t("common.yesCancel"),
        cancelButtonText: t("home.widgets.cancelNo"),
        confirmButtonVariant: "danger",
        onConfirm: resetModal,
      })();
      return;
    }

    resetModal();
  };

  const handleBack = () => {
    setIsVerification(false);
    setItems([]);
  };

  const { data: companiesQueryData } = useCompaniesV2Query({
    variables: {
      first: 100,
      destinationUuid: uuid,
    },
    skip: profile == null,
  });
  const companies = useMemo(() => companiesQueryData?.companiesV2.edges ?? [], [companiesQueryData]);

  const handleContinue = () => {
    if (selectedBuilding == null) {
      return;
    }

    if (items.length > 0 && type === "spaces") {
      if (selectedLevelUuid == null) {
        return;
      }

      addSpaces({
        variables: { input: { buildingLevelUuid: selectedLevelUuid, spaces: items, ownerCompanyUuid } },
        refetchQueries: [isFlexManager ? "FlexManagerLevels" : "DestinationBuildings"],
      })
        .then((result) => {
          if (result.data?.addSpaces != null) {
            toast.positive(t("settings.build.widget.spacesAdded", { count: result.data.addSpaces.length }));
          }
        })
        .catch((error: unknown) => {
          showError(error);
        })
        .finally(() => {
          resetModal();
        });

      return;
    }

    if (items.length > 0 && type === "levels") {
      const levelsMap = levels?.map((c) => c.title) ?? [];

      createOrUpdateBuildingMutation({
        variables: {
          input: {
            uuid: selectedBuilding.uuid,
            destinationUuid: site.uuid,
            area: selectedBuilding.area,
            name: selectedBuilding.name,
            occupants: selectedBuilding.occupants,
            units: selectedBuilding.units,
            levels: [...levelsMap, ...items],
          },
        },
        refetchQueries: ["DestinationBuildings"],
      })
        .then((result) => {
          if (result.data?.createOrUpdateBuilding.__typename === "BuildingSyncFailure") {
            toast.negative(result.data.createOrUpdateBuilding.reason);
          }

          if (result.data?.createOrUpdateBuilding.__typename === "BuildingSyncSuccess") {
            const newLevels = result.data.createOrUpdateBuilding.building.buildingLevels.filter(
              (l) => !levelsMap.includes(l.name),
            );

            setSelectedLevelUuid(newLevels[0].uuid);

            toast.positive(t("settings.build.levelsAdded", { count: newLevels.length }));
          }
        })
        .catch((error: unknown) => {
          showError(error);
        })
        .finally(() => {
          resetModal();
        });

      return;
    }

    const newItems = value.split(/,|\n/).map((item) => item.trim());
    const uniqueItems = [...new Set(newItems)].filter((item) => item);

    if (type === "levels") {
      const existingLevels = levels != null ? levels.map((s) => s.title.toLowerCase()) : [];
      const filteredItems = uniqueItems.filter((item) => !existingLevels.includes(item.toLowerCase()));
      setItems(filteredItems);
      setIsVerification(true);
    }

    if (type === "spaces") {
      const existingSpaces = spaces != null ? spaces.map((s) => s.title.toLowerCase()) : [];
      const filteredItems = uniqueItems.filter((item) => !existingSpaces.includes(item.toLowerCase()));
      setItems(filteredItems);
      setIsVerification(true);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    handleMultilineTextAreaInput(e, setValue);
  };

  const isContinueDisabled = useMemo(() => {
    if (
      value.length === 0 ||
      levelsLoading ||
      spacesLoading ||
      (type === "spaces" && canManageRegion && (ownerCompanyUuid === undefined || ownerCompanyUuid === ""))
    ) {
      return true;
    }

    if (isVerification && items.length === 0) {
      return true;
    }

    return false;
  }, [isVerification, levelsLoading, spacesLoading, value, items, ownerCompanyUuid, canManageRegion, type]);

  if (isMobile) {
    return null;
  }

  return (
    <>
      <Modal.Dialog
        title={t("common.bulkUpload")}
        show={showModal}
        onHide={onCloseModal}
        hideOnEsc={true}
        hideOnClick={true}
        centered={true}
        size="md"
      >
        <Modal.Header closeButton={true} />
        <Modal.Body>
          {isVerification ? (
            <div className="columns">
              {items.length > 0 && (
                <Form.Group className="pt-3" label={t("settings.build.bulkUploadVerify")} required={true}>
                  <ColumnView.Container>
                    <ColumnView.Column
                      title={type === "levels" ? t("settings.build.levels") : t("settings.build.spaces")}
                      id="column"
                      className="bc-column"
                    >
                      {items.map((item) => (
                        <ColumnView.Item key={item} value={item} />
                      ))}
                    </ColumnView.Column>
                  </ColumnView.Container>
                </Form.Group>
              )}
              {items.length === 0 && <Text variant="text">{t("settings.build.bulkUploadNoItems")}</Text>}
            </div>
          ) : (
            <Form.Group
              className="pt-3"
              label={type === "levels" ? t("settings.build.bulkUploadLevels") : t("settings.build.bulkUploadSpaces")}
              required={true}
              showTooltip={true}
              tooltipText={
                type === "levels"
                  ? t("settings.build.bulkUploadLevelsTooltip")
                  : t("settings.build.bulkUploadSpacesTooltip")
              }
            >
              <Form.Textarea
                placeholder={
                  type === "levels"
                    ? t("settings.build.bulkUploadLevelsPlaceholder")
                    : t("settings.build.bulkUploadSpacesPlaceholder")
                }
                onChange={onChange}
                value={value}
                onKeyDown={handleEnter}
              />
            </Form.Group>
          )}
          {type === "spaces" && canManageRegion && (
            <Form.Group label={t("settings.build.owner")} required={true}>
              <Form.Select
                id="ownerCompanyUuid"
                name="ownerCompanyUuid"
                value={ownerCompanyUuid}
                disabled={isVerification}
                onChange={(e) => setOwnerCompanyUuid(e.target.value)}
              >
                <option value="">{t("settings.build.ownerPlaceholder")}</option>
                {companies.flatMap(({ node }) => (
                  <option key={node?.name} value={node?.uuid}>
                    {node?.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="mb-4 flex justify-end">
            <Button variant="ghost" onClick={isVerification ? handleBack : onCloseModal} className="mr-3">
              {isVerification ? t("common.back") : t("common.cancel")}
            </Button>
            <Button
              variant="primary"
              onClick={handleContinue}
              disabled={isContinueDisabled}
              aria-label={isVerification ? t("common.upload") : t("common.continue")}
            >
              {isVerification ? t("common.upload") : t("common.continue")}
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Dialog>
      <Tooltip title={t("common.bulkUpload")} focusable={false}>
        <Button
          variant="ghost"
          onClick={() => setShowModal(true)}
          aria-label={t("common.bulkUpload")}
          disabled={type === "spaces" ? selectedLevelUuid == null : selectedBuilding == null}
          round
        >
          <RiFileUploadLine size={16} />
        </Button>
      </Tooltip>
      <style jsx>{`
        .columns :global(.bc-column) {
          max-height: calc(100vh - 320px);
        }
      `}</style>
    </>
  );
};
