import React, { useEffect, useMemo, useState } from "react";
import { Field, Form } from "formik";

import type { CompanyFormValues } from "@equiem/lib";
import { useSaferFormikContext } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { Button, Form as EqForm, useTheme } from "@equiem/react-admin-ui";
import { RiLock2Line } from "@equiem/react-admin-ui/icons";

import { useWidgetContext } from "../../../contexts/WidgetContext";

import { ChangeDefaultAccessGroupModal } from "./ChangeDefaultAccessGroupModal";

interface Props {
  accessGroups: Array<{ uuid: string; name: string }>;
}

export const MultipleAccessGroupSelect: React.FC<Props> = ({ accessGroups }) => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const { subscribe } = useWidgetContext();
  const { values, submitForm, setFieldValue } = useSaferFormikContext<CompanyFormValues>();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [targetDefaultAccessGroupUuid, setTargetDefaultAccessGroupUuid] = useState<string | null>(null);

  const currentDefaultAccessGroup = useMemo(() => {
    return accessGroups.find((g) => g.uuid === targetDefaultAccessGroupUuid);
  }, [accessGroups, targetDefaultAccessGroupUuid]);

  useEffect(() => {
    return subscribe("onSave", () => {
      void submitForm();
    });
  }, [submitForm, subscribe]);
  return (
    <>
      <Form>
        <EqForm.Group
          className="p-3 form-box"
          label={t("settings.editCompany.selectGroupsTitle")}
          showTooltip
          required
          tooltipText={t("settings.editCompany.accessGroupTooltip")}
        >
          {accessGroups.map((group, index) => {
            return (
              <div key={group.uuid} className="d-flex align-items-center justify-content-between access-group">
                <Field
                  as={EqForm.Checkbox}
                  name={`accessGroupsUuids[${group.uuid}]`}
                  label={group.name}
                  disabled={group.uuid === values.defaultAccessGroupUuid}
                />
                {values.defaultAccessGroupUuid === group.uuid ? (
                  <div className="p-3">
                    <RiLock2Line size={16} />
                  </div>
                ) : (
                  <Button
                    variant="ghost"
                    round
                    className="non-default-access-group"
                    onClick={() => {
                      setTargetDefaultAccessGroupUuid(group.uuid);
                      setShowModal(true);
                    }}
                  >
                    <RiLock2Line size={16} />
                  </Button>
                )}
              </div>
            );
          })}
        </EqForm.Group>
      </Form>
      <ChangeDefaultAccessGroupModal
        showModal={showModal}
        title={t("settings.editCompany.areYouSureAccessGroupDefault", {
          name: currentDefaultAccessGroup?.name as string,
        })}
        onClose={() => {
          setTargetDefaultAccessGroupUuid(null);
          setShowModal(false);
        }}
        onAgree={() => {
          if (targetDefaultAccessGroupUuid != null) {
            setFieldValue("defaultAccessGroupUuid", targetDefaultAccessGroupUuid).catch(console.error);
            setFieldValue("accessGroupsUuids", {
              ...values.accessGroupsUuids,
              [targetDefaultAccessGroupUuid]: true,
            }).catch(console.error);
            setTargetDefaultAccessGroupUuid(null);
          }
          setShowModal(false);
        }}
      />
      <style jsx>
        {`
          .form-box {
            border: 1px solid ${colors.grayscale[20]};
          }

          .access-group {
            height: 32px;
          }

          .access-group :global(.non-default-access-group) {
            display: none;
          }

          .access-group:hover :global(.non-default-access-group) {
            display: block;
          }
        `}
      </style>
    </>
  );
};
