import { stringNotEmpty, StripeProvider } from "@equiem/lib";
import { useErrorTranslation, useTranslation } from "@equiem/localisation-eq1";
import React, { useContext, useEffect } from "react";
import { useMyBookingByReferenceQuery, useSiteBookingByReferenceQuery } from "../../../generated/gateway-client";
import { BookingModal } from "../contexts/BookingModalContext";
import { BookingModalInfoProvider } from "../contexts/BookingModalInfoProvider";
import { BookingModalContentLoading } from "./BookingModalContentLoading";
import { BookingView } from "./BookingView";
import { BookingForm } from "./BookingForm";

const useQuery = (adminMode: boolean, reference: string) => {
  const mine = useMyBookingByReferenceQuery({
    variables: { reference },
    fetchPolicy: "network-only",
    skip: adminMode,
  });

  // We don't need fetch policy here as we want the cache version
  // from booking details page.
  const admin = useSiteBookingByReferenceQuery({
    variables: { reference },
    skip: !adminMode,
  });

  return adminMode
    ? { data: admin.data?.siteBookingByReference, loading: admin.loading, error: admin.error }
    : { data: mine.data?.myBookingByReference, loading: mine.loading, error: mine.error };
};

interface P {
  reference: string;
}
export const BookingViewModalContent: React.FC<P> = ({ reference }) => {
  const { tError } = useErrorTranslation();
  const modal = useContext(BookingModal);
  const { t } = useTranslation();
  const { data, loading, error } = useQuery(modal.displayMode === "adminForm", reference);

  useEffect(() => {
    const name = data?.resource.name;
    if (stringNotEmpty(name)) {
      modal.setTitle(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.resource.name]);

  if (loading) {
    return <BookingModalContentLoading />;
  }

  if (error != null) {
    return <div>{tError(error)}</div>;
  }

  if (data == null) {
    return <>{t("bookings.operations.bookingNotFound")}</>;
  }

  return (
    <BookingModalInfoProvider booking={data}>
      {(modal.displayMode === "selfForm" || modal.displayMode === "adminForm") && (
        <StripeProvider>
          <BookingForm />
        </StripeProvider>
      )}
      {modal.displayMode === "view" && <BookingView />}
    </BookingModalInfoProvider>
  );
};
