import React, { useContext } from "react";

import { CurrentRole, Role } from "@equiem/lib";

import type { CompanyDetailsQuery } from "../../../generated/settings-client";

import { DefaultAccessGroupPicker } from "./DefaultAccessGroupPicker";
import { MultipleAccessGroupSelect } from "./MultipleAccessGroupSelect";

interface Props {
  company: NonNullable<CompanyDetailsQuery["companyV2"]>;
  accessGroups: Array<{ uuid: string; name: string }>;
}

export const CompanyAccessGroups: React.FC<Props> = ({ company, accessGroups }) => {
  const { currentRole } = useContext(CurrentRole);
  const companyAccessGroups = company.companyDestination?.ac1Config?.accessGroups;

  return (
    <>
      {accessGroups != null && company != null && (
        <div>
          {currentRole === Role.PropertyManager && <MultipleAccessGroupSelect accessGroups={accessGroups} />}

          {currentRole === Role.WorkplaceManager && companyAccessGroups != null && companyAccessGroups.length > 0 && (
            <DefaultAccessGroupPicker accessGroups={accessGroups} company={company} />
          )}
        </div>
      )}
    </>
  );
};
