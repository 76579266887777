import React from "react";

import type { BookingFragmentFragment } from "../../../generated/gateway-client";
import { BookingActivityList } from "./BookingActivityList";
import { BookingActivityLogChange } from "./BookingActivityLogChange";
import { BookingActivityLogChangeRow } from "./BookingActivityLogChangeRow";

interface Props {
  booking: BookingFragmentFragment;
}

export const BookingActivityLog: React.FC<Props> = ({ booking }) => {
  return (
    <BookingActivityList>
      {booking.updateHistory.map((change, i) => (
        <BookingActivityLogChange key={i} change={change} timezone={booking.timezone} />
      ))}
      <BookingActivityLogChangeRow
        changeInitiator={`${booking.createdBy.profile?.firstName} ${booking.createdBy.profile?.lastName}`}
        timestamp={booking.created}
        timezone={booking.timezone}
        changeType="created"
      />
    </BookingActivityList>
  );
};
